import {
  CreatableEntity,
  EntityBase,
  ModifiableEntity,
  NonDeletableEntityBase,
} from 'shared/types/commonEntities';
import { CreatableModel, ModelBase, ModifiableModel } from './commonModel';
import { UserWithPersonalData } from './user/types/model';
import { Identificable } from 'shared/types/commonView';
import { Dto } from 'shared/dtos/in/common';

function modelToCreatableEntity(
  createdBy: UserWithPersonalData | undefined,
  createdAt: string
): CreatableEntity {
  return {
    createdBy: createdBy?._id,
    createdAt,
  };
}
function modelToModifiableEntity(
  modifiedBy: UserWithPersonalData | undefined,
  modifiedAt: string
): ModifiableEntity {
  return {
    modifiedBy: modifiedBy?._id,
    modifiedAt,
  };
}

export function modelToEntityBase(model: ModelBase): EntityBase {
  return {
    ...modelToNonDeletableEntityBase(model),
    deleted: model.deleted ? 1 : 0,
  };
}

export function dtoToEntityBase(dto: Dto): EntityBase {
  return {
    ...dtoToNonDeletableEntityBase(dto),
    deleted: dto.deleted ? 1 : 0,
  };
}

export function modelToNonDeletableEntityBase(
  model: Identificable & CreatableModel & ModifiableModel
): NonDeletableEntityBase {
  const { createdBy, createdAt } = modelToCreatableEntity(
    model.createdBy,
    model.createdAt
  );
  const { modifiedBy, modifiedAt } = modelToModifiableEntity(
    model.modifiedBy,
    model.modifiedAt
  );

  return {
    _id: model._id,
    createdAt,
    createdBy,
    modifiedAt,
    modifiedBy,
  };
}

export function dtoToNonDeletableEntityBase({
  _id,
  createdAt,
  createdBy,
  modifiedAt,
  modifiedBy,
}: Dto): NonDeletableEntityBase {
  return {
    _id,
    createdAt,
    createdBy,
    modifiedAt,
    modifiedBy,
  };
}
