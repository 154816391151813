import {
  ReactElement,
  Ref,
  forwardRef,
  memo,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { Box, FormHelperText } from '@mui/material';
import {
  DateField,
  DatePicker,
  DateValidationError,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { toViewDate } from 'shared/utils/date/toViewDate';
import { IntlContext } from 'intl/IntlProviderWrapper';
import { DateTime } from 'luxon';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { projectDataSelector } from 'redux/selectors/project';
import { getHelperTextFromError, getLocalizedLabel } from './common';
import {
  DisabledSimpleDatePickerProps,
  SimpleDatepickerProps,
} from './types';

function SimpleDatepicker(
  {
    value: unsafeValue,
    localisedLabel,
    labelId,
    fieldName,
    disabled,
    handleChange,
    timezone,
    error,
    minDate,
    maxDate,
    dense,
    reserveSpaceForHelperText,
  }: SimpleDatepickerProps,
  ref: Ref<HTMLDivElement>
): ReactElement {
  let value;
  const { timezone: projectTimezone } = useSelector(projectDataSelector);

  // TODO MUI5 something is wrong with inspection_date
  if (unsafeValue && !DateTime.isDateTime(unsafeValue)) {
    value = toViewDate(timezone || projectTimezone, unsafeValue);
  } else {
    value = unsafeValue;
  }
  const intl = useIntl();
  const [helperText, _setHelperText] = useState(
    getHelperTextFromError(error, intl)
  );
  const setHelperText = useCallback(
    (error: DateValidationError) =>
      _setHelperText(getHelperTextFromError(error, intl)),
    [intl]
  );

  const { calendarLocales } = useContext(IntlContext);

  const unifiedHandleChange = useCallback(
    (date: DateTime | null) => {
      // TODO MUI5
      handleChange(date, date);
    },
    [handleChange]
  );

  const label = useMemo(
    () => getLocalizedLabel(intl, localisedLabel, labelId),
    [intl, localisedLabel, labelId]
  );

  if (disabled) {
    return (
      <DisabledSimpleDatepicker
        value={value}
        key={fieldName}
        label={label}
        dense={dense}
      />
    );
  }

  return (
    <Box>
      <LocalizationProvider
        key={fieldName}
        dateAdapter={AdapterLuxon}
        adapterLocale={calendarLocales.code}
      >
        <DatePicker
          onError={(e): void => {
            setHelperText(e);
          }}
          key={fieldName}
          sx={{ width: '100%' }}
          label={label}
          value={value}
          data-qa={`form-field-date-picker-${fieldName}`}
          onChange={unifiedHandleChange}
          disabled={Boolean(disabled)}
          disableOpenPicker={Boolean(disabled)}
          minDate={minDate || undefined}
          maxDate={maxDate || undefined}
          timezone={timezone || projectTimezone}
          slotProps={{
            // The actions will be different between desktop and mobile
            actionBar: ({ wrapperVariant }) => ({
              actions:
                wrapperVariant === 'desktop'
                  ? ['clear', 'today', 'accept']
                  : ['clear', 'cancel', 'today', 'accept'],
            }),
            textField: {
              variant: 'outlined',
              margin: dense ? 'dense' : 'none',
            },
          }}
        />
      </LocalizationProvider>
      <FormHelperText error={!!error}>
        {helperText || (reserveSpaceForHelperText && ' ')}
      </FormHelperText>
    </Box>
  );
}

function DisabledSimpleDatepicker(
  { value, label, dense }: DisabledSimpleDatePickerProps,
  ref: Ref<HTMLDivElement>
): ReactElement {
  const { calendarLocales } = useContext(IntlContext);

  const { timezone } = useSelector(projectDataSelector);

  return (
    <LocalizationProvider
      dateAdapter={AdapterLuxon}
      adapterLocale={calendarLocales.code}
    >
      <DateField
        value={value}
        disabled={true}
        margin={dense ? 'dense' : 'none'}
        label={label}
        variant='outlined'
        timezone={timezone}
        // slotProps={{
        //   textField: {
        //     variant: 'outlined',
        //   },
        // }}
      />
    </LocalizationProvider>
  );
}

export const MemoSimpleDatepicker = memo(forwardRef(SimpleDatepicker));
export const MemoDisabledSimpleDatepicker = memo(
  forwardRef(DisabledSimpleDatepicker)
);
