import { isAdmin } from 'shared/domain/role/isAdmin';
import { UserWithPermissionsInDto } from 'shared/domain/user/types/inDto';
import { LogLevel } from 'shared/types/logger';
import { logWithRethrow } from '../../db/helpers';
import { UserPermissions } from './types';

export function toUserPermissions({
  userData,
}: {
  userData: UserWithPermissionsInDto;
}): UserPermissions[] {
  // TODO
  //@ts-ignore
  if (userData.error) {
    const msg = 'Error when fetching user permissions';
    logWithRethrow({
      logLevel: LogLevel.INFO,
      msg,
      errorObj: new Error(msg),
      additionalInfo: { userData },
    });
  }

  return userData.permissions.map((projectPermission) => {
    return {
      _id: projectPermission.project,
      sites: projectPermission.sites,
      processes: projectPermission.processes,
      role: projectPermission.role,
    };
  });
}

type Checker = (
  key: keyof UserPermissions,
  previous: UserPermissions,
  current: UserPermissions
) => boolean;
type PermissionEqualityCheck = {
  [key in keyof UserPermissions]: Checker;
};

function stringCheck(
  key: keyof UserPermissions,
  previous: UserPermissions,
  current: UserPermissions
): boolean {
  return previous[key] === current[key];
}
function arrayCheck(
  key: keyof UserPermissions,
  previous: UserPermissions,
  current: UserPermissions
): boolean {
  const prev = previous[key] as string[];
  const curr = current[key] as string[];

  return (
    prev.length === curr.length &&
    prev.every((previousValue: string, index: number): boolean => {
      return previousValue === curr[index];
    })
  );
}

export function arePermissionsEqual(
  previous: UserPermissions | undefined,
  current: UserPermissions | undefined
): boolean {
  if (!previous || !current) {
    return false;
  }

  // Why this way? I @Szalupka think there may be a moment in application
  // where some keys in current or previous permissions might be missing;
  // Going over keys this way ensures we always go through all available keys in UserPermissions.
  // Also this will fail to compile if UserPermissions ever changes.
  const equalityChecks: PermissionEqualityCheck = {
    _id: stringCheck,
    processes: arrayCheck,
    role: stringCheck,
    sites: skipCheckIfAdmin(previous, current, arrayCheck),
  };
  return Object.keys(equalityChecks).every((key) =>
    equalityChecks[key as keyof PermissionEqualityCheck](
      key as keyof PermissionEqualityCheck,
      previous,
      current
    )
  );
}

function skipCheckIfAdmin(
  previous: UserPermissions,
  current: UserPermissions,
  check: Checker
): Checker {
  return isAdmin(previous.role) && isAdmin(current.role)
    ? () => true
    : check;
}
