import {
  Box,
  Card,
  CardContent,
  Divider,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { MemoPhoneInput } from 'components/common/simpleInputFields/phoneInput';
import { HeaderContext } from 'components/core/Layout/HeaderContext';
import { useOrganizations } from 'components/dataProviders/withOrganizations';
import { ButtonContained } from 'components/general';
import { MuiTelInputInfo, matchIsValidTel } from 'mui-tel-input';
import React, { useContext, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { projectDataSelector } from 'redux/selectors/project';
import { PersonalData } from 'shared/domain/user/types/entity';
import { FieldSize } from 'shared/types/fieldSize';
import useFormEdit from 'views/issue/forms/useFormEdit';
import { StoreState } from '../../setup/types/core';
import { ClientContext } from '../../with-client';
import { logError } from 'setup/logger/logError';

const LABEL_MAX_LENGTH = FieldSize.M;

export const CreatableUser = (): React.ReactElement => {
  const formName = 'currentUser';
  const { organizationId } = useSelector(projectDataSelector);
  const { organizationsMap } = useOrganizations();
  const currentOrganization = organizationsMap.get(organizationId);
  const { setRouteFor } = useContext(HeaderContext);
  const user = useSelector((state: StoreState) => state.user);
  const { setEditing } = useFormEdit();
  const intl = useIntl();
  const client = useContext(ClientContext);

  const [formData, setFormData] = React.useState<PersonalData>({
    clientId: organizationId,
    label: '',
    phone: '',
  });

  const [isNewEntry, setIsNewEntry] = React.useState(true);
  const [initialData, setInitialData] =
    React.useState<PersonalData | null>(null);
  const [labelError, setLabelError] = React.useState('');

  useEffect(() => {
    setRouteFor('userEdit');
  }, [setRouteFor]);

  useEffect(() => {
    if (user.data.personalData) {
      const existingData = user.data.personalData.find(
        (data) => data.clientId === organizationId
      );

      if (existingData) {
        setFormData(existingData);
        setInitialData(existingData);
        setIsNewEntry(false);
      } else {
        setFormData({
          clientId: organizationId,
          label: '',
          phone: '',
        });
        setInitialData(null);
        setIsNewEntry(true);
      }
    }
  }, [user.data.personalData, organizationId]);

  const validateLabel = (value: string): string => {
    if (value?.length > LABEL_MAX_LENGTH) {
      return intl.formatMessage(
        { id: 'validation_string_length_maximum_web' },
        { maxLength: LABEL_MAX_LENGTH }
      );
    }
    return '';
  };

  const handleLabelChange = (value: string): void => {
    const error = validateLabel(value);
    setLabelError(error);

    setFormData((prev) => ({
      ...prev,
      label: value || '',
    }));
    setEditing(true, formName);
  };

  const handlePhoneChange = (
    e: any,
    newValue: string,
    _: MuiTelInputInfo
  ): void => {
    setFormData((prev) => ({
      ...prev,
      phone: newValue,
    }));
    setEditing(true, formName);
  };

  const hasValidationErrors = (): boolean => {
    return Object.values(labelError).some((error) => error !== '');
  };

  const hasModifications = (): boolean => {
    if (isNewEntry) {
      return formData.label !== '' || formData.phone !== '';
    }
    return (
      formData.label !== initialData?.label ||
      formData.phone !== initialData?.phone
    );
  };

  const handleSave = async (): Promise<void> => {
    try {
      await client.updateUserPersonalData(
        { label: formData.label, phone: formData.phone },
        user.data._id,
        organizationId
      );

      setEditing(false, formName);
      setInitialData(formData);
      setIsNewEntry(false);
    } catch (error) {
      logError('Failed to update user data:', error);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '600px',
        margin: '0 auto',
        height: '100%',
        p: 3,
      }}
    >
      <Card
        elevation={3}
        sx={{
          borderRadius: 2,
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            bgcolor: 'primary.main',
            color: 'primary.contrastText',
            p: 3,
          }}
        >
          <Typography variant='h5' sx={{ fontWeight: 500 }}>
            <FormattedMessage id='user_view_header' />
          </Typography>
        </Box>

        <Divider />

        <CardContent sx={{ p: 3 }}>
          <Stack spacing={3}>
            <Typography
              variant='body1'
              color='text.secondary'
              sx={{
                fontSize: '0.95rem',
                fontStyle: 'italic',
                mb: 2,
              }}
            >
              {intl.formatMessage(
                { id: 'user_view_data_message' },
                { organizationLabel: currentOrganization?.label }
              )}
            </Typography>

            <TextField
              fullWidth
              variant='outlined'
              value={formData.label || ''}
              onChange={(e) => handleLabelChange(e.target.value)}
              data-qa='user-view-label-input'
              label={intl.formatMessage({
                id: 'user_view_name_input_label',
              })}
              error={!!labelError}
              helperText={
                labelError ||
                `${formData.label?.length || 0}/${LABEL_MAX_LENGTH}`
              }
              inputProps={{ maxLength: LABEL_MAX_LENGTH }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: 1.5,
                },
              }}
            />
            <MemoPhoneInput
              // fullWidth
              localisedLabel={intl.formatMessage({
                id: 'phone_number',
              })}
              fieldName='phone'
              labelId='phone_number'
              value={formData.phone}
              handleChange={handlePhoneChange}
              dense={true}
              error=''
              reserveSpaceForHelperText={true}
            />

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                mt: 3,
              }}
            >
              <ButtonContained
                onClick={handleSave}
                disabled={!hasModifications() || hasValidationErrors()}
                data-qa='user-view-save-button'
                sx={{
                  minWidth: 120,
                  borderRadius: 2,
                  textTransform: 'none',
                  fontSize: '1rem',
                }}
              >
                <FormattedMessage id='general_save' />
              </ButtonContained>
            </Box>
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
};
