import { SetValuesOptions } from 'components/dataCreationForms/withInputForm';
import { useCallback } from 'react';

export const useVisibleNoAccess = (
  setValues: (key: string, value: any, options?: SetValuesOptions) => void
): {
  handleVisibleNoAccessSelection: () => void;
} => {
  const handleVisibleNoAccessSelection = useCallback(() => {
    setValues('sites', undefined, { skipValidation: true });
    setValues('processes', undefined, { skipValidation: true });
  }, [setValues]);

  return { handleVisibleNoAccessSelection };
};
