import { ColumnShapeFactory } from 'components/table/columnShapes';
import {
  ColumnKey,
  InitialColumnWidth,
} from 'components/table/columnShapes/types';
import { UserModel } from 'shared/domain/user/types/model';
import { ColumnShape } from 'react-base-table';
import { IntlShape } from 'react-intl/src/types';
import { ColumnWidths } from 'setup/types/core';
import { UserOnView } from 'shared/domain/user/types/view';

export class UserColumnsFactory extends ColumnShapeFactory {
  constructor(
    formatMessage: IntlShape['formatMessage'],
    columnWidths: ColumnWidths
  ) {
    super(formatMessage, columnWidths);
  }
  create(): ColumnShape[] {
    return [
      this.orderNumber(),
      this.text(
        {
          dataKey: 'label',
          labelId: 'user_view_name_input_label',
          initialWidth: InitialColumnWidth.large,
          accessor: (user: UserOnView) => user.label,
        },
        ColumnKey.label
      ),
      this.text(
        {
          labelId: 'user_email',
          dataKey: 'email',
          initialWidth: InitialColumnWidth.large,
          accessor: (user: UserModel) => user.email,
        },
        ColumnKey.email
      ),
      this.role(),
    ];
  }
}
