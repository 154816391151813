import { VisibleFieldModel } from 'shared/domain/visibleField/visibleFieldModel';
import { VisibleFieldInDto } from 'shared/dtos/in/visibleField';
import { UserEntity } from 'shared/domain/user/types/entity';
import { VisibleFieldEntity } from './visibleFieldEntity';
import { toOptionalUserWithPersonalData } from 'shared/domain/user/mapping/toView';
import { entityToModelBase } from 'shared/domain/toBaseModel';

export function visibleFieldInDtoToEntity(
  visibleField: VisibleFieldInDto
): VisibleFieldEntity {
  return {
    _id: visibleField._id,
    createdAt: visibleField.createdAt,
    createdBy: visibleField.createdBy,
    deleted: visibleField.deleted ? 1 : 0,
    modifiedAt: visibleField.modifiedAt,
    modifiedBy: visibleField.modifiedBy,
    fieldName: visibleField.fieldName,
    processId: visibleField.processId,
  };
}

export function visibleFieldEntityToModel(
  entity: VisibleFieldEntity,
  users: UserEntity[]
): VisibleFieldModel {
  const modelBase = entityToModelBase({ entity, users });

  return {
    ...modelBase,
    fieldName: entity.fieldName,
    processId: entity.processId,
  };
}
