import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ClientContext } from 'with-client';

import {
  NOT_FOUND_ERROR,
  NO_FILTERED_TEMPLATES_ERROR,
  NO_TEMPLATES_ERROR,
  canFindOnTemplatesList,
  getLastSelectedTemplateId,
  scrollToActiveCard,
  setLastSelectedTemplateId,
  useLastSelectedInspectionTemplate,
  useShowList,
} from './helpers';

import { withProjectChangeDialog } from 'components/common/withProjectChangeDialog';
import { useDialog } from 'components/core/Dialog/common/DialogContext';
import {
  useInspectionTemplates,
  withInspectionTemplates,
} from 'components/dataProviders/withInspectionTemplates';
import { toInspectionTemplateItemModel } from 'components/dataProviders/withRequiredInspectionTemplate/model';
import { InspectionTemplateItemModel } from 'components/dataProviders/withRequiredInspectionTemplate/types';
import { InspectionTemplateStatus } from 'shared/domain/template/templateStatus';
import {
  Permission,
  noPermission,
  noPermissionInOffline,
} from 'helpers/permission';
import {
  genericSearchPhraseValidation,
  normalizeForSearch,
} from 'shared/utils/search';
import { FormattedMessage } from 'react-intl';
import {
  checkProcessesEquality,
  toProcessesObject,
} from 'redux/selectors/processes';
import { projectDataSelector } from 'redux/selectors/project';
import { toUserRole } from 'redux/selectors/role';
import { INSPECTION_CRUD_PERMISSIONS } from 'setup/permissions';
import EntityNotFoundView from 'views/issue/issueView/EntityNotFoundView';
import { useIsOffline } from 'components/common/withIsOffline';
import { useSearch } from '../../../hooks/search/useSearch';
import { MemoTemplateListPresentational } from './presentational';
import { MemoTemplateListSkeleton } from './skeleton';
import { TemplateListError } from './types';

const TEMPLATE_SEARCH_KEY = 'template_search_key';
function TemplateList(): ReactElement {
  const activeRef = useRef<string | undefined>(undefined);
  const prevSearchPhraseRef = useRef('');
  const createDialog = useDialog();
  const isOffline = useIsOffline();
  const client = useContext(ClientContext);
  const { id } = useParams<{
    id: string;
  }>();
  const project = useSelector(projectDataSelector);
  const processes = useSelector(toProcessesObject, checkProcessesEquality);
  const userRole = useSelector(toUserRole);
  const history = useHistory();
  const { inspectionTemplates, loading } = useInspectionTemplates();

  const [canSetErrors, setCanSetErrors] = React.useState<boolean>(false);
  const [error, setError] = React.useState<
    TemplateListError | undefined
  >();
  const [templates, setTemplates] = React.useState<
    InspectionTemplateItemModel[]
  >([]);
  const [filteredTemplates, setFilteredTemplates] = React.useState<
    InspectionTemplateItemModel[]
  >([]);

  const [canScroll, setCanScroll] = React.useState(false);
  const [active, _setActive] = React.useState<string | undefined>(
    undefined
  );
  const setActive = useCallback((id?: string) => {
    activeRef.current = id;
    _setActive(id);
  }, []);

  const filterTemplatesByPhrase = useCallback(
    (phrase: string) => {
      const normalizedPhrase = normalizeForSearch(phrase);
      const filtered = templates.filter((template) =>
        template.searchString.includes(normalizedPhrase)
      );
      setFilteredTemplates(filtered);
      const hasActive = filtered.find(
        (template) => template._id === active
      );
      if (
        filtered.length !== 0 &&
        !hasActive &&
        prevSearchPhraseRef.current !== normalizedPhrase
      ) {
        setActive(filtered[0]._id);
      }
      if (filtered.length !== 0 && hasActive && active) {
        scrollToActiveCard(active);
      }
      if (filtered.length === 0) {
        setError(NO_FILTERED_TEMPLATES_ERROR);
      }

      prevSearchPhraseRef.current = normalizedPhrase;
    },
    [templates, active, setActive]
  );

  const { searchChange, searchClear, searchPhrase } = useSearch(
    filterTemplatesByPhrase,
    localStorage.getItem(TEMPLATE_SEARCH_KEY) || '',
    genericSearchPhraseValidation
  );

  const { lastSelected, loading: loadingLastSelected } =
    useLastSelectedInspectionTemplate({
      templates,
      projectId: project._id,
    });

  useEffect(() => {
    localStorage.setItem(TEMPLATE_SEARCH_KEY, searchPhrase);
    setError(undefined);
  }, [searchPhrase]);

  useEffect(() => {
    if (!loading) {
      const processedTemplateItems = inspectionTemplates.items
        .filter((t) => !t.deleted)
        .map((template) =>
          toInspectionTemplateItemModel(
            processes,
            template,
            project.organizationId
          )
        )
        .sort(
          (a, b) => b.modifiedAtDate.getTime() - a.modifiedAtDate.getTime()
        );
      setTemplates(processedTemplateItems);
      setCanSetErrors(true);
      return;
    }

    setCanSetErrors(false);
  }, [inspectionTemplates, loading, processes]);

  useEffect(() => {
    if (loadingLastSelected || loading || !canSetErrors || active) {
      setError(undefined);
      return;
    }

    if (id && canFindOnTemplatesList(templates, id)) {
      activeRef.current = id;
      setActive(id);
      setError(undefined);
    } else if (id && !canFindOnTemplatesList(templates, id)) {
      setActive(undefined);
      setError(NOT_FOUND_ERROR);
    } else if (
      lastSelected &&
      canFindOnTemplatesList(templates, lastSelected._id)
    ) {
      setActive(lastSelected._id);
      setError(undefined);
    } else if (templates[0] && !lastSelected) {
      setActive(templates[0]._id);
      setError(undefined);
    } else if (!templates[0]) {
      setError(NO_TEMPLATES_ERROR);
      setCanScroll(false);
    }
  }, [
    id,
    templates,
    lastSelected,
    loadingLastSelected,
    loading,
    canSetErrors,
    active,
    setActive,
  ]);

  useEffect(() => {
    if (active && project._id) {
      setLastSelectedTemplateId(project._id, active);

      if (canScroll) {
        setCanScroll(false);
        scrollToActiveCard(active);
      }
    }
  }, [active, canScroll, project]);

  const { setObserverOn, showList, setShowList } = useShowList(
    active,
    activeRef,
    searchPhrase,
    loading || loadingLastSelected
  );

  const handleClick = useCallback(
    (value: InspectionTemplateItemModel): void => {
      const isAlreadyActive =
        getLastSelectedTemplateId(project._id) === value._id;

      setShowList(false);
      setObserverOn();

      if (isAlreadyActive) {
        return;
      }

      setActive(value._id);

      setTemplates((prev) => {
        if (prev[0].fromUrl) {
          return [...prev.slice(1)];
        }
        return prev;
      });

      setLastSelectedTemplateId(project._id, value._id);
    },
    [project, setObserverOn, setShowList, setActive]
  );

  const canEditTemplate = useCallback(
    (template: InspectionTemplateItemModel): Permission => {
      if (!INSPECTION_CRUD_PERMISSIONS[userRole]) {
        return noPermission('tooltip_action_forbidden');
      } else if (template.status === InspectionTemplateStatus.published) {
        return noPermission('inspection_template_edit_disabled_reason');
      } else if (isOffline) {
        return noPermissionInOffline();
      }
      return {
        permission: true,
      };
    },
    [userRole, isOffline]
  );

  const editTemplate = useCallback(
    (template: InspectionTemplateItemModel): void => {
      history.push(`/inspectionTemplate/${template._id}/edit`);
    },
    [history]
  );

  const canRemoveTemplate = useCallback(() => {
    if (!INSPECTION_CRUD_PERMISSIONS[userRole]) {
      return noPermission('tooltip_action_forbidden');
    }
    return {
      permission: true,
    };
  }, [userRole]);

  const removeTemplate = useCallback(
    (templateId: string, templateSummary: string): void => {
      if (!canRemoveTemplate().permission) {
        return;
      }

      createDialog({
        title: (
          <FormattedMessage id='remove_inspection_template_dialog_title' />
        ),
        description: (
          <span>
            <FormattedMessage
              id='remove_inspection_template_dialog_description'
              values={{
                templateTitle: (
                  <strong style={{ color: 'crimson' }}>
                    {templateSummary}
                  </strong>
                ),
              }}
            />
          </span>
        ),
        customControllerLabels: ['general_cancel', 'general_delete'],
      }).then(() => {
        client.removeTemplate(templateId).then((res) => {
          if (res.status !== 200) {
            return;
          }
          setTemplates((prev) => {
            const templateIndex = prev.findIndex(
              (template) => template._id === templateId
            );
            const nextIndex = templateIndex + 1;
            const nextItem = prev[nextIndex] || prev[0];

            const wasLastTemplate = !nextItem || prev.length <= 1;
            if (wasLastTemplate) {
              setLastSelectedTemplateId(project._id, '');
              setActive(undefined);
            } else {
              setLastSelectedTemplateId(project._id, nextItem._id);
              setActive(nextItem._id);
            }
            return [
              ...prev.slice(0, templateIndex),
              ...prev.slice(nextIndex),
            ];
          });
        });
      });
    },
    [client, createDialog, canRemoveTemplate, project, setActive]
  );

  const canCreateTemplate = useCallback(() => {
    if (!INSPECTION_CRUD_PERMISSIONS[userRole]) {
      return noPermission('tooltip_action_forbidden');
    }

    if (isOffline) {
      return noPermissionInOffline();
    }

    return {
      permission: true,
    };
  }, [userRole, isOffline]);

  const createTemplate = useCallback((): void => {
    history.push(`/create-template`);
  }, [history]);

  const startInspection = useCallback(
    (template): void => {
      history.push(`/create-inspection`, { template });
    },
    [history]
  );

  const canStartInspection = useCallback(
    (template: InspectionTemplateItemModel): Permission => {
      if (!INSPECTION_CRUD_PERMISSIONS[userRole]) {
        return noPermission('tooltip_action_forbidden');
      }
      if (template.status !== InspectionTemplateStatus.published) {
        return noPermission(
          'snack_start_inspection_on_published_template_error'
        );
      }
      if (isOffline) {
        return noPermissionInOffline();
      }

      return {
        permission: true,
      };
    },
    [userRole, isOffline]
  );

  if (loading || loadingLastSelected) {
    return <MemoTemplateListSkeleton />;
  }

  if (error === NOT_FOUND_ERROR) {
    return <EntityNotFoundView entity='template' />;
  }

  return (
    <MemoTemplateListPresentational
      templates={filteredTemplates}
      activeId={active}
      showList={showList}
      handleClick={handleClick}
      error={error}
      loaded={!loading && !loadingLastSelected}
      canCreateTemplate={canCreateTemplate}
      createTemplate={createTemplate}
      canEditTemplate={canEditTemplate}
      editTemplate={editTemplate}
      canRemoveTemplate={canRemoveTemplate}
      removeTemplate={removeTemplate}
      setCanScroll={setCanScroll}
      searchChange={searchChange}
      searchClear={searchClear}
      searchPhrase={searchPhrase}
      startInspection={startInspection}
      canStartInspection={canStartInspection}
    />
  );
}

export const MemoTemplateList = React.memo(
  withProjectChangeDialog(withInspectionTemplates(TemplateList))
);
