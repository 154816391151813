import React, { ReactElement, useCallback, useMemo } from 'react';

import { DeleteOutlined, EditOutlined } from '@mui/icons-material';
import { Box, Divider, Grid } from '@mui/material';
import { MemoPreviewField } from '../../general/PreviewField';
import { MemoChecklistPreview } from '../ChecklistPreview';
import { MemoInspectionTemplateStatus } from '../Status';
import { useStyles } from './styles';

import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { SingleTemplatePresentationalProps } from './types';

import Skeleton from '@mui/material/Skeleton';
import { GraphicUploader } from 'components/common/graphicUploader';
import { DEFAULT_THUMBNAIL_CARD_SIZE } from 'components/common/graphicUploader/styles';
import { useGraphicUploader } from 'components/common/graphicUploader/useLocalGraphicUploader';
import { MemoProcessIcon } from 'components/common/ProcessIcon';
import { MemoButtonContained } from 'components/general/ButtonContained/ButtonContained';
import { MemoForbiddenTooltip } from 'components/general/ForbiddenTooltip/ForbiddenTooltip';
import useIsMobile from 'hooks/useIsMobile';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { projectDataSelector } from 'redux/selectors/project';
import { DocumentToTemplateBinder } from 'shared/domain/document/documentBinder';
import { getUserLabelOptionalByOrganization } from 'shared/domain/user/mapping/toView';
import { createInspectionTemplateGeneralNormDocumentsBaseUrl } from 'views/template/wizard/communicator/helpers';
import { ButtonTextIcon } from '../../general';
import { InspectionTemplateStatusSize } from '../Status/types';

function SingleTemplatePresentational(
  props: SingleTemplatePresentationalProps
): ReactElement {
  const isMobile = useIsMobile();
  const classes = useStyles({ isMobile });
  const intl = useIntl();
  const { organizationId } = useSelector(projectDataSelector);
  const { projectId: urlProjectId } = useParams<{ projectId?: string }>();
  const urlPrefix = useMemo(() => {
    return urlProjectId ? `/project/${urlProjectId}` : '';
  }, [urlProjectId]);

  const {
    template,
    checklist,
    canEditTemplate,
    editTemplate,
    canRemoveTemplate,
    removeTemplate,
    startInspection,
    canStartInspection,
    generalNormDocuments,
    loadingDocuments,
  } = props;

  const internalUrlBuilder = useMemo(() => {
    return () => `${urlPrefix}/inspectionTemplate/${template._id}`;
  }, [template._id, urlPrefix]);

  const history = useHistory();

  const autoOpenCheck = useMemo(() => {
    return (_: string, file: any, __?: string | undefined) => {
      if (file && file._id) {
        return history.location.pathname.includes(`document/${file._id}`);
      }
      return false;
    };
  }, [history]);

  const GU = useGraphicUploader(
    generalNormDocuments,
    {
      baseUrl: createInspectionTemplateGeneralNormDocumentsBaseUrl(
        template._id
      ),
      previewOnly: true,
      internalEntityBaseUrl: internalUrlBuilder,
      // TODO: PT-3769 should we have binder on preview?
      documentBinderFactory: () =>
        new DocumentToTemplateBinder({
          templateId: template._id,
        }),
      autoOpenCheck: autoOpenCheck,
    },
    true
  );

  const edit = useCallback(() => {
    editTemplate(template);
  }, [template, editTemplate]);

  const remove = useCallback(() => {
    removeTemplate(template._id, template.summary);
  }, [template, removeTemplate]);

  const createInspection = useCallback(() => {
    startInspection(template);
  }, [template, startInspection]);

  const statusComponent = useMemo(
    () => (
      <MemoInspectionTemplateStatus
        status={template.status}
        size={InspectionTemplateStatusSize.default}
      />
    ),
    [template.status]
  );

  const { permission: editPermission, reason: editDisabledReason } =
    useMemo(() => canEditTemplate(template), [template, canEditTemplate]);

  const { permission: removalPermission, reason: removalDisabledReason } =
    canRemoveTemplate();

  const {
    permission: startInspectionPermission,
    reason: startInspectionDisabledReason,
  } = canStartInspection(template);

  const isEditButtonDisabled = !editPermission || template.deleted;
  const isRemoveButtonDisabled = !removalPermission || template.deleted;

  return (
    <div className={classes.singleContainer}>
      <div className={classes.header}>
        <MemoProcessIcon
          color={template.renderableProcess.color}
          code={template.renderableProcess.code}
        />
        <span>{template.code}</span>
      </div>
      <Divider />
      <div className={classes.summaryWithActions}>
        <h1>{template.summary}</h1>
        <div
          className={classNames(
            classes.wrappedContent,
            classes.actionsContainer
          )}
        >
          <div className='actions-box'>
            <MemoForbiddenTooltip
              visibleWhen={!editPermission}
              reason={editDisabledReason}
              inlined
            >
              <ButtonTextIcon
                disabled={isEditButtonDisabled}
                startIcon={EditOutlined}
                onClick={edit}
              >
                {intl.formatMessage({
                  id: 'inspection_template_action_edit',
                })}
              </ButtonTextIcon>
            </MemoForbiddenTooltip>

            {
              // PT-2191 adjust for release
              /* <Button className={classes.hidden}>
              {intl.formatMessage({
                id: 'inspection_template_action_create_similar',
              })}
            </Button> */
            }
            <MemoForbiddenTooltip
              visibleWhen={!removalPermission}
              reason={removalDisabledReason}
              inlined
            >
              <ButtonTextIcon
                disabled={isRemoveButtonDisabled}
                startIcon={DeleteOutlined}
                onClick={remove}
              >
                {intl.formatMessage({
                  id: 'inspection_template_action_remove',
                })}
              </ButtonTextIcon>
            </MemoForbiddenTooltip>
          </div>
          <div className='actions-box'>
            <MemoForbiddenTooltip
              visibleWhen={!startInspectionPermission}
              reason={startInspectionDisabledReason}
            >
              <MemoButtonContained
                disabled={!startInspectionPermission}
                onClick={createInspection}
              >
                {intl.formatMessage({
                  id: 'inspection_template_action_start_inspection',
                })}
              </MemoButtonContained>
            </MemoForbiddenTooltip>
          </div>
        </div>
      </div>
      <Divider />

      <Grid container spacing={2} sx={{ mt: 1, ml: 1 }}>
        <Grid item xs={6} sm={4} md={3}>
          <MemoPreviewField
            // className='preview-field'
            label={intl.formatMessage({
              id: 'inspection_template_field_label_creation_date',
            })}
            value={template.createdAtDate.toLocaleDateString('en-GB')}
            dense={true}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <MemoPreviewField
            label={intl.formatMessage({
              id: 'inspection_template_field_label_status',
            })}
            component={statusComponent}
            dense={true}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <MemoPreviewField
            label={intl.formatMessage({
              id: 'inspection_template_field_label_author',
            })}
            value={
              getUserLabelOptionalByOrganization(
                template.createdBy,
                organizationId
              ) ||
              intl.formatMessage({
                id: 'general_user_deleted',
              })
            }
            dense={true}
          />
        </Grid>
      </Grid>

      <Divider textAlign='left'>
        <h2>
          {intl.formatMessage({
            id: 'inspection_template_header_general_applied_standard',
          })}
        </h2>
      </Divider>
      <div className={classes.generalNormContainer}>
        <Box sx={{ ml: 3 }}>
          <MemoPreviewField
            label={intl.formatMessage({
              id: 'inspection_template_field_label_standard_description',
            })}
            value={template.generalNorm.description}
            dense={true}
          />
          <div className={classes.graphicUploaderWrapper}>
            {loadingDocuments && (
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: `repeat(auto-fill, ${DEFAULT_THUMBNAIL_CARD_SIZE}px)`,
                  gridGap: '16px',
                  width: '100%',
                  marginBottom: '16px',
                }}
              >
                <Skeleton
                  variant='rectangular'
                  width={DEFAULT_THUMBNAIL_CARD_SIZE}
                  height={DEFAULT_THUMBNAIL_CARD_SIZE}
                />
                <Skeleton
                  variant='rectangular'
                  width={DEFAULT_THUMBNAIL_CARD_SIZE}
                  height={DEFAULT_THUMBNAIL_CARD_SIZE}
                />
              </div>
            )}
            {!loadingDocuments && <GraphicUploader {...GU} />}
          </div>
        </Box>
      </div>
      <Divider textAlign='left'>
        <h2>
          {intl.formatMessage({
            id: 'inspection_template_header_checklist',
          })}
        </h2>
      </Divider>
      <div className={classes.checklistContainer}>
        <div style={{ marginBottom: '85px' }}>
          <MemoChecklistPreview checklist={checklist} />
        </div>
      </div>
    </div>
  );
}

export const MemoSingleTemplatePresentational = React.memo(
  SingleTemplatePresentational
);
