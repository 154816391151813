import { IntlShape } from 'react-intl';
import { SiteModel } from 'shared/domain/site/types/model';
import {
  UserEditOnView,
  UserLabelledWithEmail,
  UserLabelledWithEmailAndTranslatedRole,
  UserOnView,
} from 'shared/domain/user/types/view';
import {
  UserInDto,
  UserWithPermissionsInDto,
} from 'shared/domain/user/types/inDto';
import { ProcessType } from 'shared/types/form';
import {
  CreatableUserRole,
  USER_ROLE_LABEL_ID,
  UserRole,
} from 'shared/types/userRole';
import { idsToLabelledEntities } from 'shared/utils/id/idsToLabelledEntities';
import { PersonalData, UserEntity } from '../types/entity';
import {
  UserEditModel,
  UserModel,
  UserWithPersonalData,
} from '../types/model';
import { getRoleFromUserWithPermissionsInDto } from './toModel';

function userToLabelledEntityWithEmail(
  user: UserWithPersonalData,
  organizationId: string
): UserLabelledWithEmail {
  return {
    _id: user._id,
    label: getUserLabelByOrganization(user, organizationId),
    email: user.email,
  };
}

export function userInDtoToLabelledWithEmail(
  user: UserInDto,
  organizationId: string
): UserLabelledWithEmail {
  return userToLabelledEntityWithEmail(user, organizationId);
}

export function userModelToLabelledWithEmail(
  user: UserModel,
  organizationId: string
): UserLabelledWithEmail {
  return userToLabelledEntityWithEmail(user, organizationId);
}

export function userModelToLabelledEntityWithEmailAndTranslatedRole(
  user: UserModel,
  organizationId: string,
  intl: IntlShape
): UserLabelledWithEmailAndTranslatedRole {
  const { label, email } = userToLabelledEntityWithEmail(
    user,
    organizationId
  );
  return {
    _id: user._id,
    label,
    email,
    role: translateRole(user.role, intl),
  };
}

export function translateRole(role: UserRole, intl: IntlShape): string {
  return intl.formatMessage({
    id: `${USER_ROLE_LABEL_ID[role]}`,
  });
}

export function toLabelledUserRole<T extends UserRole | CreatableUserRole>(
  role: T,
  intl: IntlShape
): { _id: T; label: string } {
  return {
    _id: role,
    label: translateRole(role, intl),
  };
}

export function userModelToUserOnView({
  userModel,
  sites,
  processes,
  intl,
  organizationId,
}: {
  userModel: UserModel;
  sites: SiteModel[];
  processes: ProcessType[];
  intl: IntlShape;
  organizationId: string;
}): UserOnView {
  return {
    _id: userModel._id,
    label: getUserLabelByOrganization(userModel, organizationId),
    phone: getUserPhoneByOrganization(userModel, organizationId),
    email: userModel.email,
    role: toLabelledUserRole(userModel.role as CreatableUserRole, intl),
    processes: idsToLabelledEntities(userModel.processes, processes),
    sites: idsToLabelledEntities(userModel.sites, sites),
  };
}

export function userModelToUserEditOnView({
  userModel,
  sites,
  processes,
  intl,
  organizationId,
}: {
  userModel: UserModel;
  sites: SiteModel[];
  processes: ProcessType[];
  intl: IntlShape;
  organizationId: string;
}): UserEditOnView {
  return {
    _id: userModel._id,
    label: getUserLabelByOrganization(userModel, organizationId),
    phone: getUserPhoneByOrganization(userModel, organizationId),
    email: userModel.email,
    role: toLabelledUserRole(userModel.role as CreatableUserRole, intl),
    processes: idsToLabelledEntities(userModel.processes, processes),
    sites: idsToLabelledEntities(userModel.sites, sites),
    personalData: userModel.personalData,
  };
}

export function getPersonalDataByOrganization(
  user: { personalData: PersonalData[]; email: string },
  organizationId: string
): PersonalData {
  const personalDataForRequestedClient = user.personalData.find(
    (data) => data.clientId === organizationId
  );
  if (!personalDataForRequestedClient) {
    return {
      clientId: organizationId,
      label: user.email,
      phone: '',
    };
  }
  return {
    clientId: organizationId,
    label: personalDataForRequestedClient.label,
    phone: personalDataForRequestedClient.phone,
  };
}

export function setPersonalDataForOrganization(
  personalData: PersonalData[],
  organizationId: string,
  newData: Partial<PersonalData>
): PersonalData[] {
  const personalDataForRequestedClient = personalData.find(
    (data) => data.clientId === organizationId
  );

  if (!personalDataForRequestedClient) {
    throw new Error(
      `Could not find requested organization in personal data ${organizationId}`
    );
  }

  personalDataForRequestedClient.label =
    newData.label ?? personalDataForRequestedClient.label;
  personalDataForRequestedClient.phone =
    newData.phone ?? personalDataForRequestedClient.phone;

  return personalData;
}

export function getUserLabelOptionalByOrganization(
  user: UserWithPersonalData | undefined,
  organizationId: string
): string | undefined {
  if (!user) {
    return;
  }
  return getPersonalDataByOrganization(user, organizationId).label;
}

export function getUserLabelByOrganization(
  user: { personalData: PersonalData[]; email: string },
  organizationId: string
): string {
  return getPersonalDataByOrganization(user, organizationId).label;
}

export function getUserPhoneByOrganization(
  user: UserModel | UserEntity,
  organizationId: string
): string {
  return getPersonalDataByOrganization(user, organizationId).phone;
}

function toLabelledUser(
  user: UserModel | UserEntity,
  organizationId: string
): LabelledUser {
  return {
    _id: user._id,
    email: user.email,
    label: getUserLabelByOrganization(user, organizationId),
    role: user.role,
  };
}

function toOptionalLabelledUser(
  user: UserModel | UserEntity | undefined,
  organizationId: string
): LabelledUser | undefined {
  if (!user) return undefined;
  return {
    _id: user._id,
    email: user.email,
    label: getUserLabelByOrganization(user, organizationId),
    role: user.role,
  };
}

export function toOptionalUserWithPersonalData(
  user: UserModel | UserEntity | undefined
): UserWithPersonalData | undefined {
  if (!user) return undefined;
  return {
    _id: user._id,
    email: user.email,
    personalData: user.personalData,
    role: user.role,
  };
}

export function userModelToLabelled(
  user: UserModel,
  organizationId: string
): LabelledUser {
  return toLabelledUser(user, organizationId);
}
export function userModelToOptionalLabelled(
  user: UserModel | undefined,
  organizationId: string
): LabelledUser | undefined {
  return toOptionalLabelledUser(user, organizationId);
}

export function userModelToUserWithPersonalData(
  user: UserModel | undefined
): UserWithPersonalData | undefined {
  return toOptionalUserWithPersonalData(user);
}

export function userEntityToLabelled(
  user: UserEntity,
  organizationId: string
): LabelledUser | undefined {
  return toLabelledUser(user, organizationId);
}

export type LabelledUser = {
  _id: string;
  label: string;
} & { email: string; role: UserRole };

export function userWithPersonalDataToLabelledUser(
  user: UserWithPersonalData,
  organizationId: string
): LabelledUser {
  return {
    _id: user._id,
    email: user.email,
    label: getUserLabelByOrganization(user, organizationId),
    role: user.role,
  };
}

export function userWithPersonalDataToOptionalLabelled(
  user: UserWithPersonalData | undefined,
  organizationId: string
): LabelledUser | undefined {
  if (!user) {
    return undefined;
  }
  return {
    _id: user._id,
    email: user.email,
    label: getUserLabelByOrganization(user, organizationId),
    role: user.role,
  };
}

export function userWithPermissionsInDtoToUserWithPersonalData(
  user: UserWithPermissionsInDto,
  currentProjectId: string
): UserWithPersonalData {
  return {
    _id: user._id,
    email: user.email,
    personalData: user.personalData,
    role: getRoleFromUserWithPermissionsInDto(user, currentProjectId),
  };
}

export function userWithPermissionsInDtoToLabelled(
  userWithPermissionsInDto: UserWithPermissionsInDto,
  organizationId: string,
  currentProjectId: string
): LabelledUser {
  const { _id, email } = userWithPermissionsInDto;

  return {
    _id,
    email,
    label: getUserLabelByOrganization(
      userWithPermissionsInDto,
      organizationId
    ),
    role: getRoleFromUserWithPermissionsInDto(
      userWithPermissionsInDto,
      currentProjectId
    ),
  };
}
