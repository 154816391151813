import { IntlShape } from 'react-intl';
import { toLabelledUserRole } from 'shared/domain/user/mapping/toView';
import { CreatableUserRole, UserRole } from 'shared/types/userRole';

const userRolesOrder: Record<CreatableUserRole, number> = {
  [UserRole.visible_no_access]: 0,
  [UserRole.standard]: 1,
  [UserRole.viewer]: 2,
  [UserRole.manager]: 3,
  [UserRole.projectAdmin]: 4,
  [UserRole.organizationAdmin]: 5,
};

export const createUserRoleDropdownOptions = (
  authorRole: UserRole,
  intl: IntlShape
): {
  _id: CreatableUserRole;
  label: string;
}[] => {
  const creatableRoles: CreatableUserRole[] =
    getCreatableRoles(authorRole);

  return creatableRoles
    .sort(orderByRoleAsc)
    .map((value) => toLabelledUserRole(value, intl));
};

function getCreatableRoles(authorRole: UserRole): CreatableUserRole[] {
  return authorRole === UserRole.superAdmin ||
    authorRole === UserRole.organizationAdmin
    ? [
        UserRole.organizationAdmin,
        UserRole.projectAdmin,
        UserRole.manager,
        UserRole.viewer,
        UserRole.standard,
        UserRole.visible_no_access,
      ]
    : [
        UserRole.projectAdmin,
        UserRole.manager,
        UserRole.viewer,
        UserRole.standard,
        UserRole.visible_no_access,
      ];
}

function orderByRoleAsc(
  role1: CreatableUserRole,
  role2: CreatableUserRole
): number {
  return userRolesOrder[role1] - userRolesOrder[role2];
}
