import {
  ChecklistItemModel,
  InspectionTemplateModel,
} from 'shared/domain/template/inspectionTemplateModel';
import { getUserLabelOptionalByOrganization } from 'shared/domain/user/mapping/toView';
import { Identificable } from 'shared/types/commonView';
import { Color } from 'shared/utils/colors';
import { createSearchableString } from 'shared/utils/search';
import { RenderableProcess } from '../../common/ProcessIcon/types';
import {
  ChecklistItem,
  ChecklistModelToItemMapper,
  InspectionTemplateItemModel,
  ProcessObject,
} from './types';

function createInspectionTemplateCode(
  processesObject: ProcessObject,
  inspectionTemplate: { process: string } & {
    createdAt: string;
  } & Identificable
): string {
  return `${inspectionTemplate.createdAt.slice(0, 4)}/IT/${
    processesObject[inspectionTemplate.process]?.code
  }/${inspectionTemplate._id.slice(-4)}`;
}

function toRenderableProcess(
  processes: ProcessObject,
  id: string
): RenderableProcess {
  if (!processes || !processes[id]) {
    return {
      code: '',
      color: Color.white,
    };
  }
  return {
    code: processes[id]!.code,
    color: processes[id]!.color,
  };
}

export function toChecklistItem(
  templateId: string
): ChecklistModelToItemMapper {
  return function checklistItemMapping(
    checklistItem: ChecklistItemModel
  ): ChecklistItem {
    return {
      ...checklistItem,
      templateId: templateId,
    };
  };
}

export function toInspectionTemplateItemModel(
  processesObject: ProcessObject,
  inspectionTemplate: InspectionTemplateModel,
  organizationId: string
): InspectionTemplateItemModel {
  const process = processesObject[inspectionTemplate.process];
  const code = createInspectionTemplateCode(
    processesObject,
    inspectionTemplate
  );
  return {
    _id: inspectionTemplate._id,
    checklist: inspectionTemplate.checklist.map(
      toChecklistItem(inspectionTemplate._id)
    ),
    createdAt: inspectionTemplate.createdAt,
    createdBy: inspectionTemplate.createdBy,
    generalNorm: inspectionTemplate.generalNorm,
    modifiedAt: inspectionTemplate.modifiedAt,
    modifiedBy: inspectionTemplate.modifiedBy,
    process: inspectionTemplate.process,
    status: inspectionTemplate.status,
    summary: inspectionTemplate.summary,
    renderableProcess: toRenderableProcess(
      processesObject,
      inspectionTemplate.process
    ),
    code: createInspectionTemplateCode(
      processesObject,
      inspectionTemplate
    ),
    createdAtDate: new Date(inspectionTemplate.createdAt),
    modifiedAtDate: new Date(inspectionTemplate.modifiedAt),
    searchString: createSearchableString([
      inspectionTemplate.summary,
      inspectionTemplate.generalNorm.description,
      process?.label || '',
      process?.code || '',
      code,
      inspectionTemplate.createdBy?.email || '',
      getUserLabelOptionalByOrganization(
        inspectionTemplate.createdBy,
        organizationId
      ) || '',
    ]),
    active: false,
    deleted: inspectionTemplate.deleted,
  };
}
