import { AnyAction } from 'redux';
import {
  UserWithAuthAndPermissions,
  UserRole,
} from 'shared/types/userRole';
import * as actions from '../actionTypes';

const initialState: UserWithAuthAndPermissions = {
  isAuthenticated: !!process.env.REACT_APP_DISABLE_AUTH,
  error: null,
  data: {
    _id: '',
    email: '',
    permissions: [],
    personalData: [],
    basePermissions: {
      clients: [
        {
          _id: '',
          projects: [
            { role: UserRole.standard, _id: '', sites: [], processes: [] },
          ],
        },
      ],
    },
  },
};

const userReducer = (
  state = initialState,
  action: AnyAction
): UserWithAuthAndPermissions => {
  if (action.type === actions.AUTH_SUCCESS) {
    return {
      ...state,
      isAuthenticated: true,
      data: action.payload,
    };
  }

  if (action.type === actions.MODIFY_USER_SUCCESS) {
    const { personalData } = action.payload;

    return {
      ...state,
      data: { ...state.data, personalData },
    };
  }

  if (action.type === actions.MODIFY_USER_FAILURE) {
    return {
      ...state,
      isAuthenticated: !!process.env.REACT_APP_DISABLE_AUTH,
      error: action.payload.error,
    };
  }

  if (action.type === actions.USER_LOGOUT) {
    return initialState;
  }

  return state;
};

export default userReducer;
