import { toChecklistItem } from 'components/dataProviders/withRequiredInspectionTemplate/model';
import { ProcessObject } from 'components/dataProviders/withRequiredInspectionTemplate/types';
import { createInspectionCode } from 'helpers/generators';
import { toLabelledEntities, toLabelledEntity } from 'helpers/misc';
import { logError } from 'setup/logger/logError';
import { CompanyModel } from 'shared/domain/company/types/model';
import { ContractModel } from 'shared/domain/contract/types/model';
import {
  ComplianceCheckModel,
  InspectionModel,
  InspectionStatus,
  ProtocolItemModel,
} from 'shared/domain/inspection/inspectionModel';
import { LevelModel } from 'shared/domain/level/types/model';
import { SiteModel } from 'shared/domain/site/types/model';
import {
  ChecklistItemModel,
  InspectionTemplateModel,
} from 'shared/domain/template/inspectionTemplateModel';
import { modelToViewBase } from 'shared/domain/toBaseView';
import { getUserLabelOptionalByOrganization } from 'shared/domain/user/mapping/toView';
import { WorktypeModel } from 'shared/domain/worktype/worktypeModel';
import { HashMap, LabelledEntity } from 'shared/types/commonView';
import { Color } from 'shared/utils/colors';
import { stringToViewDate } from 'shared/utils/date/stringToViewDate';
import {
  ComplianceCheckOnView,
  InspectionOnTableView,
  InspectionOnView,
  ProtocolItemOnTableView,
  ProtocolOnView,
} from './inspectionOnView';

function findChecklistItem(
  template: InspectionTemplateModel,
  protocolItemModel: ProtocolItemModel
): ChecklistItemModel {
  const checklistItem = template.checklist.find((item) => {
    return item._id === protocolItemModel.templateChecklistItem;
  });
  if (!checklistItem) {
    const errorMessage =
      'Cannot match template checklist item to a protocol item.';
    logError(errorMessage, {
      templateId: template._id,
      protocolId: protocolItemModel._id,
    });

    throw new Error(errorMessage);
  }
  return checklistItem;
}

function toComplianceCheckOnView(
  complianceCheck: ComplianceCheckModel
): ComplianceCheckOnView {
  return {
    ...complianceCheck,
  };
}

function toProtocolOnView(
  template: InspectionTemplateModel,
  inspectionId: string
): (protocolItemModel: ProtocolItemModel) => ProtocolOnView {
  return function protocolItem(protocolItemModel): ProtocolOnView {
    const checklistItem = findChecklistItem(template, protocolItemModel);

    return {
      ...protocolItemModel,
      inspectionId: inspectionId,
      templateChecklistItem: toChecklistItem(template._id)(checklistItem),
      complianceCheck: toComplianceCheckOnView(
        protocolItemModel.complianceCheck
      ),
    };
  };
}

function toProtocolItemOnTableView(
  template: InspectionTemplateModel,
  inspectionId: string
): (protocolItemModel: ProtocolItemModel) => ProtocolItemOnTableView {
  return function protocolItemOnTableView(
    protocolItemInDto
  ): ProtocolItemOnTableView {
    const checklistItem = findChecklistItem(template, protocolItemInDto);

    return {
      _id: protocolItemInDto._id,
      modifiedAt: protocolItemInDto.modifiedAt,
      modifiedBy: protocolItemInDto.modifiedBy,
      inspectionId: inspectionId,
      templateChecklistItem: toChecklistItem(template._id)(checklistItem),
    };
  };
}

export function toInspectionOnView(
  processesObject: ProcessObject,
  template: InspectionTemplateModel,
  inspection: InspectionModel,
  sites: SiteModel[],
  levels: LevelModel[],
  worktypes: WorktypeModel[],
  companies: CompanyModel[],
  contracts: ContractModel[],
  organizationId: string
): InspectionOnView {
  const process = template.process;
  const site = sites.find((site) => site._id === inspection.site);
  if (!site) {
    throw new Error(`Cannot match site: ${inspection.site}`);
  }
  const controlledParties = toLabelledEntities(
    inspection.controlledParty
      .map((partyId) =>
        companies.find((company) => company._id === partyId)
      )
      .filter((x) => x)
      .map((company) => ({
        _id: company!._id,
        label: company!.shortLabel,
      })) as LabelledEntity[]
  );
  const inspectionWorktypes = toLabelledEntities(
    inspection.workTypes
      .map((worktypeId) =>
        worktypes.find((worktype) => worktype._id === worktypeId)
      )
      .filter((x) => x) as WorktypeModel[]
  );
  const onViewBase = modelToViewBase(inspection, organizationId);

  return {
    ...onViewBase,

    isCompleted: inspection.isCompleted,
    comment: inspection.comment,
    site: toLabelledEntity(site),
    contracts: toLabelledEntities(
      inspection.contracts
        .map((contractId) =>
          contracts.find((contract) => contract._id === contractId)
        )
        .filter((x) => x) as ContractModel[]
    ),
    controlledParty: controlledParties,
    levels: toLabelledEntities(
      inspection.levels
        .map((levelId) => levels.find((level) => level._id === levelId))
        .filter((x) => x) as LevelModel[]
    ),
    workTypes: inspectionWorktypes,
    process,
    template,
    protocol: inspection.protocol.map(
      toProtocolOnView(template, inspection._id)
    ),
    code: createInspectionCode(
      inspection.createdAt,
      inspection._id,
      processesObject[process]?.code || ''
    ),
    status: !inspection.isCompleted
      ? InspectionStatus.inProgress
      : InspectionStatus.completed,
    inspectionDate: stringToViewDate(inspection.inspectionDate),
    author: getUserLabelOptionalByOrganization(
      inspection.createdBy,
      organizationId
    ),
    joinedControlledParty: controlledParties
      .map((party) => party.label)
      .join(', '),
    joinedWorkTypes: inspectionWorktypes
      .map((workType) => workType.label)
      .join(', '),
    renderableProcess: {
      code: processesObject[process]?.code || Color.white,
      color: processesObject[process]?.color || Color.white,
    },
  };
}

export function toInspectionOnTableView(
  processesObject: ProcessObject,
  templatesObject: HashMap<InspectionTemplateModel>,
  inspection: InspectionModel,
  sites: SiteModel[],
  levels: LevelModel[],
  worktypes: WorktypeModel[],
  companies: CompanyModel[],
  contracts: ContractModel[],
  organizationId: string
): InspectionOnTableView {
  const template = templatesObject[inspection.template];
  const process = template.process;
  const site = sites.find((site) => site._id === inspection.site);
  if (!site) {
    throw new Error(`Cannot match site: ${inspection.site}`);
  }
  const controlledParties = toLabelledEntities(
    inspection.controlledParty
      .map((partyId) =>
        companies.find((company) => company._id === partyId)
      )
      .filter((x) => x)
      .map((company) => ({
        _id: company!._id,
        label: company!.shortLabel,
      })) as LabelledEntity[]
  );
  const inspectionWorktypes = toLabelledEntities(
    inspection.workTypes
      .map((worktypeId) =>
        worktypes.find((worktype) => worktype._id === worktypeId)
      )
      .filter((x) => x) as WorktypeModel[]
  );
  const onViewBase = modelToViewBase(inspection, organizationId);
  return {
    ...onViewBase,
    isCompleted: inspection.isCompleted,
    comment: inspection.comment,
    site: toLabelledEntity(site),
    contracts: toLabelledEntities(
      inspection.contracts
        .map((contractId) =>
          contracts.find((contract) => contract._id === contractId)
        )
        .filter((x) => x) as ContractModel[]
    ),
    controlledParty: controlledParties,
    levels: toLabelledEntities(
      inspection.levels
        .map((levelId) => levels.find((level) => level._id === levelId))
        .filter((x) => x) as LevelModel[]
    ),
    workTypes: inspectionWorktypes,
    process,
    template,
    deleted: inspection.deleted ? true : false,
    protocol: inspection.protocol.map(
      toProtocolItemOnTableView(template, inspection._id)
    ),
    code: createInspectionCode(
      inspection.createdAt,
      inspection._id,
      processesObject[process]?.code || ''
    ),
    status: !inspection.isCompleted
      ? InspectionStatus.inProgress
      : InspectionStatus.completed,
    // renderableInspectionDate: toStandardDateDisplayStringFormat(
    //   inspection.inspectionDate,
    //   timezone
    // ),
    inspectionDate: stringToViewDate(inspection.inspectionDate),
    author: getUserLabelOptionalByOrganization(
      inspection.createdBy,
      organizationId
    ),
    joinedControlledParty: controlledParties
      .map((party) => party.label)
      .join(', '),
    joinedWorkTypes: inspectionWorktypes
      .map((workType) => workType.label)
      .join(', '),
    renderableProcess: {
      code: processesObject[process]?.code || Color.white,
      color: processesObject[process]?.color || Color.white,
    },
  };
}
