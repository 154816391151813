import InsufficientPermissionsPage from 'components/core/InsufficientPermissionsPage/InsufficientPermissionsPage';
import { isDefined } from 'shared/domain/role/isDefined';
import { UserInDto } from 'shared/domain/user/types/inDto';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { currentProjectUserSelector } from 'redux/selectors/users';
import { Documentations } from 'views/documentations/table';
import { MemoGenericRoleAuthorizer } from '../GenericRoleAuthorizer';

export const AuthorizableDocumentations = React.memo(
  (): React.ReactElement => {
    const currentUser: UserInDto = useSelector(currentProjectUserSelector);

    const isCurrentUserAuthorized = useMemo(() => {
      return isDefined(currentUser.role);
    }, [currentUser]);

    return (
      <MemoGenericRoleAuthorizer
        authorized={isCurrentUserAuthorized}
        unauthorized={!isCurrentUserAuthorized}
        authorizedComponent={Documentations}
        unauthorizedComponent={InsufficientPermissionsPage}
      />
    );
  }
);
