import {
  entityToModelBase,
  entityToNonDeletableModelBase,
} from 'shared/domain/toBaseModel';
import { LevelModel, MapModel } from 'shared/domain/level/types/model';
import { UserEntity } from 'shared/domain/user/types/entity';
import { LevelInDto } from 'shared/dtos/in/level';
import {
  LevelEntity,
  MapEntity,
} from '../../../shared/domain/level/types/entity';

export function levelInDtoToEntity(level: LevelInDto): LevelEntity {
  return {
    _id: level._id,
    createdAt: level.createdAt,
    createdBy: level.createdBy,
    deleted: level.deleted ? 1 : 0,
    index: level.index,
    label: level.label,
    maps: level.maps,
    modifiedAt: level.modifiedAt,
    modifiedBy: level.modifiedBy,
    site: level.site,
  };
}

export function levelEntityToModel(
  entity: LevelEntity,
  users: UserEntity[]
): LevelModel {
  const modelBase = entityToModelBase({
    entity,
    users,
  });
  return {
    ...modelBase,
    index: entity.index,
    label: entity.label,
    maps: entity.maps.map((map) => mapEntityToModel(map, users)),
    site: entity.site,
  };
}

function mapEntityToModel(
  entity: MapEntity,
  users: UserEntity[]
): MapModel {
  const { documentationId, versionId } = entity;
  const modelBase = entityToNonDeletableModelBase({
    entity,
    users,
  });
  return {
    ...modelBase,
    documentationId,
    versionId,
  };
}
