import { OpenCreateDialogParams } from 'components/dataCreationForms/types';
import { projectIdSelector } from 'helpers/misc';
import { useGetAllLevels } from 'hooks/useGetAllLevels';
import { useGetAllSites } from 'hooks/useGetAllSites';
import { useGetAllUsers } from 'hooks/useGetAllUsers';
import { useCancelConfirmation } from 'presentation/dialogForms/dialogFormsHooks';
import {
  ComponentType,
  ReactElement,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { displayGenericErrorToaster } from 'redux/actions/toasterActions';
import { projectDataSelector } from 'redux/selectors/project';
import {
  currentLabelledUserSelector,
  currentProjectUserModelSelector,
  currentUserSelector,
} from 'redux/selectors/users';
import { ChannelNames } from 'shared/domain/channelNames';
import { getUploadedMessageListener } from 'shared/domain/commonModel';
import { siteOnViewToSiteCreateModel } from 'shared/domain/site/mapping/toModel';
import { startSiteCreate } from 'shared/domain/site/startSiteCreate';
import { SiteModel } from 'shared/domain/site/types/model';
import { SiteOnView } from 'shared/domain/site/types/view';
import { toOptionalUserWithPersonalData } from 'shared/domain/user/mapping/toView';
import { Identificable } from 'shared/types/commonView';
import { nowTimezonedDateTime } from 'shared/utils/date/dates';
import { createUniqueId } from 'shared/utils/id/id';
import { createEmptySite } from 'views/site/model';
import { CreateSiteContext } from '../types';
import { handleFormValidation, validate } from '../validation';
const formName = 'site';
const SUBMIT_EVENT_TYPE = 'submit-site';
const WithCreateSiteContext = createContext<CreateSiteContext | undefined>(
  undefined
);

function WithCreateSite({
  children,
  onSuccessCallback,
}: {
  children: ReactElement;
  onSuccessCallback?: (_id: string, label: string) => void;
}): ReactElement {
  const { getAll: getAllSites } = useGetAllSites();
  const fetchSites = getAllSites;
  const { getAll: getAllLevels } = useGetAllLevels();
  const fetchLevels = getAllLevels;
  const { getAll: getAllUsers } = useGetAllUsers();
  const fetchUsers = getAllUsers;
  const [isPosting, setIsPosting] = useState<boolean>(false);
  const projectId = useSelector(projectIdSelector);

  const dispatch = useDispatch();
  const releaseSubmitEvent = useCallback(() => {
    window.dispatchEvent(new CustomEvent(SUBMIT_EVENT_TYPE));
  }, []);

  const { data: currentUser } = useSelector(currentUserSelector);
  const currentUserLabelled = useSelector(currentLabelledUserSelector);
  const currentUserModel = useSelector(currentProjectUserModelSelector);

  const { timezone } = useSelector(projectDataSelector);

  const emptySite = createEmptySite(
    nowTimezonedDateTime(timezone),
    currentUserLabelled
  );
  const [initialValues, setInitialValues] =
    useState<SiteOnView>(emptySite);

  const [open, setOpen] = useState(false);

  const openDialog = useCallback(
    ({ freeSoloLabel }: OpenCreateDialogParams) => {
      setOpen(true);
      initialValues &&
        freeSoloLabel &&
        setInitialValues({ ...initialValues, label: freeSoloLabel });
    },
    [initialValues]
  );
  const onSuccess = useCallback(
    (_id: string, label: string) =>
      onSuccessCallback && onSuccessCallback(_id, label),
    [onSuccessCallback]
  );
  const confirmCancel = useCancelConfirmation(formName);
  const openConfirmationDialog = useCallback(async () => {
    const { cancelConfirmed } = await confirmCancel();
    if (!cancelConfirmed) {
      return;
    }
    setOpen(false);
  }, [confirmCancel]);

  const submitForm = useCallback(
    async (values: SiteOnView) => {
      setIsPosting(true);
      const uniqueId = createUniqueId();
      const siteCreateModel = siteOnViewToSiteCreateModel(
        values,
        projectId,
        toOptionalUserWithPersonalData(currentUserModel)
      );

      return new Promise((resolve, reject) => {
        let timeout: ReturnType<typeof setTimeout>;
        const broadcast = getUploadedMessageListener(
          (message: SiteModel) => {
            broadcast.close();
            resolve(message);
            setOpen(false);
            setIsPosting(false);
            clearTimeout(timeout);
            // users are attached to sites so we have to do it
            // in other case we cannot assign anyone without refresh
            fetchUsers();
            // fetching sites may be safely ommited
            // but let's make sure we have everything up to date
            fetchSites();
            // First level is created automatically, we have to fetch it
            fetchLevels();
          },
          (error: Error) => {
            broadcast.close();
            reject(error);
            setIsPosting(false);
            displayGenericErrorToaster(dispatch);
            clearTimeout(timeout);
          },
          (message) => {
            return message.uniqueId === uniqueId;
          },
          ChannelNames.siteChannel
        );
        timeout = setTimeout(() => {
          broadcast.close();
          reject(new Error('WithCreateSite: Timeout on upload.'));
          setIsPosting(false);
          displayGenericErrorToaster(dispatch);
        }, 15000);

        startSiteCreate(siteCreateModel, uniqueId);
      })
        .then((site: unknown): any => {
          if (site && (site as Identificable)._id) {
            setOpen(false);
            return site;
          }
        })
        .catch(() => {});
    },
    [dispatch, fetchSites, fetchLevels, projectId, fetchUsers]
  );

  const ctx: CreateSiteContext = useMemo(() => {
    return {
      open,
      openDialog,
      closeDialog: openConfirmationDialog,
      submitForm,
      isPosting,
      initialValues,
      SUBMIT_EVENT_TYPE,
      releaseSubmitEvent,
      validate,
      handleFormValidation,
      onSuccess,
      formName,
    };
  }, [
    openConfirmationDialog,
    initialValues,
    isPosting,
    open,
    openDialog,
    releaseSubmitEvent,
    submitForm,
    onSuccess,
  ]);

  return (
    <WithCreateSiteContext.Provider value={ctx}>
      {children}
    </WithCreateSiteContext.Provider>
  );
}

function useCreateSite(): CreateSiteContext {
  const context = useContext(WithCreateSiteContext);
  if (context === undefined) {
    throw new Error(
      'useCreateSite must be used within an CreateSiteContext'
    );
  }
  return context;
}

const withCreateSite =
  (Component: ComponentType<any>) =>
  ({ ...props }): ReactElement => (
    <WithCreateSite>
      <Component {...props} />
    </WithCreateSite>
  );

export { WithCreateSite, useCreateSite, withCreateSite };
