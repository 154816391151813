import { ProjectModel } from 'shared/domain/project/types/model';
import { toViewDate } from 'shared/utils/date/toViewDate';
import { anyToAppLocaleOnView } from 'shared/utils/locale/anyLocaleCodeToAppLocaleOnView';
import { ProjectOnSingleView } from './types';

export function modelToSingleView(
  model: ProjectModel
): ProjectOnSingleView {
  const {
    _id,
    organizationId,
    name,
    timezone,
    budget,
    currency,
    projectStartDate,
    projectEndDate,
    localeCode,
  } = model;

  return {
    _id,
    organizationId,
    name,
    timezone,
    budget: budget || 0,
    currency,
    projectStartDate: toViewDate(timezone, projectStartDate),
    projectEndDate: toViewDate(timezone, projectEndDate),
    locale: anyToAppLocaleOnView(localeCode),
  };
}
