import { DateTime } from 'luxon';

export function toViewDate(
  timezone: string,
  date?: string
): DateTime<true> | undefined {
  return date
    ? (DateTime.fromISO(date, { zone: timezone }) as DateTime<true>)
    : undefined;
}
