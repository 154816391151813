import { Spinner } from 'components/core';
import { useCompanies } from 'components/dataProviders/withCompanies';
import { useSites } from 'components/dataProviders/withSites';
import { toLabelledEntities } from 'helpers/misc';
import { useGetAllCompanies } from 'hooks/useGetAllCompanies';
import { useCancelConfirmation } from 'presentation/dialogForms/dialogFormsHooks';
import {
  ComponentType,
  ReactElement,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { displayGenericErrorToaster } from 'redux/actions/toasterActions';
import { projectDataSelector } from 'redux/selectors/project';
import { ChannelNames } from 'shared/domain/channelNames';
import { getUpdatedMessageListener } from 'shared/domain/commonModel';
import { companyOnViewToCompanyEditModel } from 'shared/domain/company/mapping';
import { startCompanyEdit } from 'shared/domain/company/startCompanyEdit';
import { CompanyModel } from 'shared/domain/company/types/model';
import { CompanyOnView } from 'shared/domain/company/types/view';
import { keepUndeleted } from 'shared/domain/deletable/filters';
import { Identificable } from 'shared/types/commonView';
import { createUniqueId } from 'shared/utils/id/id';
import { companyModelToCompanyOnView } from 'views/companies/mapping';
import { EditCompanyContext } from '../types';
import { handleFormValidation, validate } from '../validation';
import {
  findCompanyModelForEditing,
  shouldDisableInvolvement,
} from './model';
const formName = 'company';
const SUBMIT_EVENT_TYPE = 'submit-company';
const WithEditCompanyContext = createContext<
  EditCompanyContext | undefined
>(undefined);

function WithEditCompany({
  children,
  onSuccessCallback,
}: {
  children: ReactElement;
  onSuccessCallback?: (_id: string, label: string) => void;
}): ReactElement {
  const {
    sites: { items: sites },
    loading: loadingSites,
  } = useSites();
  const history = useHistory();
  const intl = useIntl();
  const { organizationId, _id: projectId } = useSelector(
    projectDataSelector
  );

  const [isPosting, setIsPosting] = useState<boolean>(false);
  const availableSites = toLabelledEntities(sites.filter(keepUndeleted));

  const dispatch = useDispatch();
  const releaseSubmitEvent = useCallback(() => {
    window.dispatchEvent(new CustomEvent(SUBMIT_EVENT_TYPE));
  }, []);

  const {
    companies: { items: companies },
    loading: loadingCompanies,
  } = useCompanies();
  const { id: currentCompanyId } = useParams<{ id: string }>();

  const [existingValues, setExistingValues] = useState<
    CompanyOnView | undefined
  >(undefined);
  const [disableInvolvement, setDisableInvolvement] =
    useState<boolean>(false);

  useEffect(() => {
    const existingCompanyModel = findCompanyModelForEditing(
      companies,
      currentCompanyId
    );
    let companyOnView;
    if (existingCompanyModel) {
      companyOnView = companyModelToCompanyOnView(
        existingCompanyModel,
        // contracts,
        //TODO should be availableSites only
        sites,
        intl,
        organizationId
      );
      setExistingValues(companyOnView);
    }
    setDisableInvolvement(
      shouldDisableInvolvement(companyOnView, companies)
    );
  }, [companies, sites, intl, currentCompanyId]);

  const [open, setOpen] = useState(false);

  const openDialog = useCallback(() => {
    setOpen(true);
  }, []);

  const onSuccess = useCallback(
    (_id, label) => onSuccessCallback && onSuccessCallback(_id, label),
    [onSuccessCallback]
  );

  const confirmCancel = useCancelConfirmation(formName);
  const openConfirmationDialog = useCallback(async () => {
    const { cancelConfirmed } = await confirmCancel();
    if (!cancelConfirmed) {
      return;
    }
    setOpen(false);
  }, [confirmCancel]);

  const submitForm = useCallback(
    async (values: CompanyOnView) => {
      setIsPosting(true);

      const uniqueId = createUniqueId();
      const companyEditModel = companyOnViewToCompanyEditModel(
        values,
        projectId
      );
      return new Promise((resolve, reject) => {
        let timeout: ReturnType<typeof setTimeout>;
        const broadcast = getUpdatedMessageListener(
          (message: CompanyModel) => {
            broadcast.close();
            resolve(message);
            setOpen(false);
            setIsPosting(false);
            clearTimeout(timeout);
            // fetchCompanies();
          },
          (error: Error) => {
            broadcast.close();
            reject(error);
            setIsPosting(false);
            displayGenericErrorToaster(dispatch);
            clearTimeout(timeout);
          },
          (message) => {
            return message.uniqueId === uniqueId;
          },
          ChannelNames.companyChannel
        );
        timeout = setTimeout(() => {
          broadcast.close();
          reject(new Error('WithEditCompany: Timeout on update.'));
          setIsPosting(false);
          displayGenericErrorToaster(dispatch);
        }, 15000);

        startCompanyEdit(companyEditModel, uniqueId);
      })
        .then((company: unknown): any => {
          if (company && (company as Identificable)._id) {
            // const redirectAfterCreation =
            //   window.location.href.indexOf('create-company') > 0;
            // redirectAfterCreation &&
            history.push(`/company/${(company as Identificable)._id}`);
            // : closeDialog();
            return company;
          }
        })
        .catch(() => {});
    },
    [dispatch, history, projectId]
  );

  const ctx: EditCompanyContext = useMemo(() => {
    return {
      open,
      openDialog,
      closeDialog: openConfirmationDialog,
      submitForm,
      isPosting,
      existingValues,
      SUBMIT_EVENT_TYPE,
      releaseSubmitEvent,
      validate,
      handleFormValidation,
      disableInvolvement,
      availableSites,
      onSuccess,
      formName,
    };
  }, [
    openConfirmationDialog,
    existingValues,
    isPosting,
    open,
    openDialog,
    releaseSubmitEvent,
    submitForm,
    disableInvolvement,
    availableSites,
    onSuccess,
  ]);
  if (loadingCompanies || loadingSites) {
    return (
      <Spinner
        reason={`CompanyWizard loadingCompanies: ${loadingCompanies} || loadingSites: ${loadingSites} || existingValues: ${existingValues}`}
      />
    );
  }
  return (
    <WithEditCompanyContext.Provider value={ctx}>
      {children}
    </WithEditCompanyContext.Provider>
  );
}

function useEditCompany(): EditCompanyContext {
  const context = useContext(WithEditCompanyContext);
  if (context === undefined) {
    throw new Error(
      'useEditCompany must be used within an EditCompanyContext'
    );
  }
  return context;
}

const withEditCompany =
  (Component: ComponentType<any>) =>
  ({ ...props }): ReactElement => (
    <WithEditCompany>
      <Component {...props} />
    </WithEditCompany>
  );

export { WithEditCompany, useEditCompany, withEditCompany };
