import { DirectoryModel } from 'shared/domain/directory/directoryModel';
import { DOCUMENT_ERROR_SOURCE } from 'shared/domain/document/documentError';
import { MAX_DOCUMENTATION_NAME_LENGTH } from 'shared/domain/document/documentLimits';
import { DocumentationModel } from 'shared/domain/documentation/documentationModel';
import { SyncStatus } from 'shared/domain/entitySyncStatus/syncStatus';
import { stringToViewDate } from 'shared/utils/date/stringToViewDate';
import { normalizeForSearch } from 'shared/utils/search';
import { Store, useCreateStore } from 'hooks/createStore';
import { useGetSearchedDocumentations } from 'hooks/useGetSearchedDocumentations';
import { DateTime } from 'luxon';
import MIMETypes from 'mime-types';
import { useEffect, useState } from 'react';
import { DisplayedFilesStore } from '../../common/gallery/filesStore';
import {
  FileReadResult,
  FileReadSuccess,
  ResultType,
} from '../../common/graphicUploader/types';
import { DocumentationFile } from './types';
import { userWithPersonalDataToOptionalLabelled } from 'shared/domain/user/mapping/toView';

export function toFileReadResult(
  resolve: (result: FileReadResult) => void,
  file: File
): void {
  const url = URL.createObjectURL(file);

  return resolve({
    file,
    resultType: ResultType.success,
    src: url,
    size: file.size,
    title: file.name.substring(0, MAX_DOCUMENTATION_NAME_LENGTH),
    description: '',
    type: file.type,
    data: {
      extension: getExtension(file.name),
      name: file.name,
    },
    downloadSrc: url,
    syncStatus: SyncStatus.PENDING,
    drawingSyncStatus: SyncStatus.PENDING,
  } as FileReadSuccess);
}

function createDocumentationFileSignedRequestUrl(
  file: DocumentationFile
): string {
  return `/documentation/${file.documentationId}/version/${file.versionId}/download`;
}

export function documentationToFileType(
  documentation: DocumentationModel,
  organizationId: string
): DocumentationFile {
  const file = documentation.versions[documentation.versions.length - 1];
  if (!file) {
    return {
      localId: -1,
      downloadSrc: undefined,
      type: 'FILE',
      title: documentation.name,
      description: documentation.number,
      documentationId: documentation._id,
      versionId: '',
      data: { extension: '' },
      createdBy: { _id: '', label: 'unknown' },
      createdAt: DateTime.now(),
      imageConvertStatus: undefined,
    };
  }

  return {
    localId: documentation.localId,
    downloadSrc: file.localData,
    type: MIMETypes.lookup(file.extension) || 'FILE',
    title: documentation.name,
    description: documentation.number,
    documentationId: documentation._id,
    versionId: file._id,
    data: { extension: file.extension },
    signedRequest: undefined,
    thumbnail: undefined,
    createdBy: userWithPersonalDataToOptionalLabelled(
      documentation.createdBy,
      organizationId
    ),
    createdAt: stringToViewDate(documentation.createdAt),
    imageConvertStatus: file.imageConvertStatus,
  };
}

function isLocalDocumentationFile(file: DocumentationFile): boolean {
  return (
    file.localId === undefined ||
    file.localId === -1 ||
    file.documentationId === undefined ||
    file.documentationId === '' ||
    file.versionId === undefined ||
    file.versionId === ''
  );
}

function getExtension(fileName: string): string {
  const nameSplitOnDot = fileName.split('.');
  if (nameSplitOnDot.length < 2) {
    return '';
  }
  return nameSplitOnDot.pop() as string;
}

export function getFileDetailsForDownload(
  file: DocumentationFile
):
  | { isLocal: false; url: string; fileId: string }
  | { isLocal: true; url: string; fileId: number }
  | undefined {
  if (isLocalDocumentationFile(file)) {
    return {
      fileId: file.localId,
      url: file.downloadSrc as string,
      isLocal: true,
    };
  }

  const fileId = `${file.documentationId}${file.versionId}`;
  if (!file) {
    return;
  }
  const url = createDocumentationFileSignedRequestUrl(file);
  if (!url) {
    return;
  }

  return { isLocal: false, url, fileId };
}

export function setFileBroken(
  filesStore: DisplayedFilesStore<DocumentationFile>,
  file: DocumentationFile,
  fileId: number
): void {
  const files = filesStore.get();
  const fileIndex = files.findIndex((file) => file.localId === fileId);
  if (!files[fileIndex] || files[fileIndex].signedRequest) {
    return;
  }
  const fileWithSignedRequest = {
    ...file,
    loading: false,
    downloadSrc: DOCUMENT_ERROR_SOURCE,
    thumbnail: DOCUMENT_ERROR_SOURCE,
    signedRequest: {
      signedRequest: DOCUMENT_ERROR_SOURCE,
      thumbnailSignedRequest: DOCUMENT_ERROR_SOURCE,
    },
    src: DOCUMENT_ERROR_SOURCE,
  };
  files[fileIndex] = fileWithSignedRequest;
  filesStore.set(files.slice());
  return;
}

export async function parseLocalFileBlob(response: {
  blob?: () => Promise<any>;
}): Promise<{ size: number }> {
  if (response.blob) {
    return response.blob().catch(() => ({ size: 0 }));
  }
  return { size: 0 };
}

export function useDocumentationsSearch({
  isLoadingDirectoriesStore,
  searchPhraseStore,
  directoriesStore,
}: {
  isLoadingDirectoriesStore: Store<boolean>;
  searchPhraseStore: Store<string>;
  directoriesStore: Store<DirectoryModel[]>;
}): {
  searchedDirectoriesStore: Store<DirectoryModel[]>;
  searchedDocumentationsStore: Store<DocumentationModel[]>;
  loadingStore: Store<boolean>;
} {
  const [isLoadingDocumentations, setIsLoadingDocumentations] =
    useState(false);
  const [isLoadingDirectories, setIsLoadingDirectories] = useState(
    isLoadingDirectoriesStore.get()
  );
  const [searchPhrase, setSearchPhrase] = useState(
    searchPhraseStore.get()
  );
  const { get: getSearchedDocumentations } =
    useGetSearchedDocumentations();

  useEffect(() => {
    return searchPhraseStore.subscribe(() => {
      setSearchPhrase(searchPhraseStore.get());
    });
  }, [searchPhraseStore]);

  const loadingStore = useCreateStore(
    searchPhrase ? isLoadingDocumentations || isLoadingDirectories : false
  );
  const searchedDirectoriesStore = useCreateStore<DirectoryModel[]>([]);
  const searchedDocumentationsStore = useCreateStore<DocumentationModel[]>(
    []
  );

  useEffect(() => {
    if (searchPhrase && !isLoadingDirectories) {
      return searchedDirectoriesStore.set(
        directoriesStore
          .get()
          .filter((directory: DirectoryModel) =>
            normalizeForSearch(directory.name).includes(
              normalizeForSearch(searchPhrase)
            )
          )
      );
    }
    return searchedDirectoriesStore.set([]);
  }, [
    searchPhrase,
    isLoadingDirectories,
    directoriesStore,
    searchedDirectoriesStore,
  ]);

  useEffect(() => {
    searchedDocumentationsStore.set([]);
    if (searchPhrase) {
      setIsLoadingDocumentations(true);
      const normalizedSearchPhrase = normalizeForSearch(searchPhrase);
      getSearchedDocumentations(normalizedSearchPhrase).then(
        (response: { items: DocumentationModel[] }): void => {
          if (searchPhraseStore.get() !== normalizedSearchPhrase) {
            return;
          }

          searchedDocumentationsStore.set(response.items);
          setIsLoadingDocumentations(false);
        }
      );
      return;
    }
    setIsLoadingDocumentations(false);
  }, [
    searchPhrase,
    searchedDocumentationsStore,
    getSearchedDocumentations,
    searchPhraseStore,
  ]);

  useEffect(() => {
    return isLoadingDirectoriesStore.subscribe(() => {
      setIsLoadingDirectories(isLoadingDirectoriesStore.get());
    });
  }, [isLoadingDirectoriesStore]);

  useEffect(() => {
    loadingStore.set(
      searchPhrase
        ? isLoadingDocumentations || isLoadingDirectories
        : false
    );
  }, [
    searchPhrase,
    isLoadingDirectories,
    loadingStore,
    isLoadingDocumentations,
  ]);

  return {
    searchedDirectoriesStore,
    searchedDocumentationsStore,
    loadingStore,
  };
}
