import {
  HttpRequestModel,
  HttpRequestModelType,
  HttpRequestStatus,
} from 'shared/domain/httpRequest/httpRequestModel';
import { UserDeleteUseCase } from 'shared/domain/user/deleteUser';
import { UserDeleteModel } from 'shared/domain/user/types/model';
import { add as addRequest } from 'serviceWorker/db/httpRequests';
import { UserRole } from 'shared/types/userRole';
import { debugLog } from 'shared/logger/debugLog';
import { UserHttpDeleteRequestModel } from '../httpQueue/user/types';

export class UserDeleter implements UserDeleteUseCase {
  constructor(
    private addRequest: (
      request: UserHttpDeleteRequestModel
    ) => Promise<any>
  ) {}

  async execute(
    userDeleteModel: UserDeleteModel,
    uniqueId: string
  ): Promise<void> {
    debugLog('DeleteUserUseCase', userDeleteModel._id);

    const url =
      userDeleteModel.role === UserRole.organizationAdmin
        ? `/user/${userDeleteModel._id}/permission/client/${userDeleteModel.organizationId}`
        : `/user/${userDeleteModel._id}/permission/project/${userDeleteModel.projectId}`;
    const request: UserHttpDeleteRequestModel = {
      createdAt: Date.now(),
      method: 'DELETE',
      data: {
        userId: userDeleteModel._id,
        clientId: userDeleteModel.organizationId,
        projectId: userDeleteModel.projectId,
        uniqueId,
      },
      url,
      entityType: HttpRequestModelType.user,
      status: HttpRequestStatus.NEW,
    };
    return this.addRequest(request);
  }
}

export const userDeleter = new UserDeleter(addRequest);
