import { StoreState } from 'setup/types/core';
import { userWithPermissionsInDtoToModel } from 'shared/domain/user/mapping/toModel';
import { userWithPermissionsInDtoToUserInDto } from 'shared/domain/user/mapping/toOutDto';
import {
  LabelledUser,
  userWithPermissionsInDtoToLabelled,
} from 'shared/domain/user/mapping/toView';
import {
  UserInDto,
  UserWithPermissionsInDto,
} from 'shared/domain/user/types/inDto';
import { UserModel } from 'shared/domain/user/types/model';
import { UserWithAuthAndPermissions } from 'shared/types/userRole';

export function currentProjectUserSelector(store: StoreState): UserInDto {
  const currentProjectId = store.projectData._id;

  return userWithPermissionsInDtoToUserInDto(
    currentProjectId,
    store.user.data
  );
}

export function currentLabelledUserSelector(
  store: StoreState
): LabelledUser {
  return userWithPermissionsInDtoToLabelled(
    store.user.data,
    store.projectData.organizationId,
    store.projectData._id
  );
}

export function currentProjectUserModelSelector(
  store: StoreState
): UserModel {
  const currentProjectId = store.projectData._id;

  return userWithPermissionsInDtoToModel(
    currentProjectId,
    store.user.data
  );
}

export function currentUserSelector(
  state: StoreState
): UserWithAuthAndPermissions {
  return state.user;
}

function equalData(
  prev: UserWithPermissionsInDto,
  curr: UserWithPermissionsInDto
): boolean {
  return (
    prev._id === curr._id &&
    prev.permissions === curr.permissions &&
    prev.personalData === curr.personalData &&
    prev.email === curr.email
  );
}

function equalError(prev: null | string, curr: null | string): boolean {
  return prev === curr;
}

function equalIsAuthenticated(prev: boolean, curr: boolean): boolean {
  return prev === curr;
}

export function userEqualityFunction(
  prev: UserWithAuthAndPermissions,
  curr: UserWithAuthAndPermissions
): boolean {
  return (
    equalData(prev.data, curr.data) &&
    equalError(prev.error, curr.error) &&
    equalIsAuthenticated(prev.isAuthenticated, curr.isAuthenticated)
  );
}
