import {
  addBatch as addCompanyBatch,
  updateOne as updateOneCompany,
} from 'serviceWorker/db/companies';
import * as config from 'serviceWorker/db/config';
import {
  addBatch as addContractBatch,
  updateOne as updateOneContract,
} from 'serviceWorker/db/contracts';
import { removeBatch as deleteDocuments } from 'serviceWorker/db/documents';
import {
  addBatch as addLevelBatch,
  updateOne as updateOneLevel,
} from 'serviceWorker/db/levels';
import { addBatch as addOrganizationBatch } from 'serviceWorker/db/organizations';
import {
  addBatch as addProjectBatch,
  updateOne as updateOneProject,
} from 'serviceWorker/db/projects';
import {
  addBatch as addSiteBatch,
  updateOne as updateOneSite,
} from 'serviceWorker/db/sites';
import { addBatch as addUserBatch } from 'serviceWorker/db/users';
import { removeBatch } from 'serviceWorker/db/worktypes';
import {
  uploadCompany,
  uploadCompanyChanges,
  uploadCompanyDelete,
  uploadCompanyRestore,
} from 'serviceWorker/repository/company/uploadCompany';
import {
  uploadContract,
  uploadContractChanges,
  uploadContractDelete,
  uploadContractRestore,
} from 'serviceWorker/repository/contract/uploadContract';
import { setDirectorySync } from 'serviceWorker/repository/directory/setDirectorySync';
import { uploadDirectory } from 'serviceWorker/repository/directory/uploadDirectory';
import { uploadDirectoryChange } from 'serviceWorker/repository/directory/uploadDirectoryChange';
import { uploadClearDrawing } from 'serviceWorker/repository/document/uploadClearDrawing';
import { uploadDocument } from 'serviceWorker/repository/document/uploadDocument';
import { uploadDocumentChange } from 'serviceWorker/repository/document/uploadDocumentChange';
import { uploadDocumentDelete } from 'serviceWorker/repository/document/uploadDocumentDelete';
import { uploadDrawing } from 'serviceWorker/repository/document/uploadDrawing';
import { setDocumentationSync } from 'serviceWorker/repository/documentation/setDocumentationSync';
import { uploadDocumentation } from 'serviceWorker/repository/documentation/uploadDocumentation';
import { uploadDocumentationChange } from 'serviceWorker/repository/documentation/uploadDocumentationChange';
import { uploadDocumentationDelete } from 'serviceWorker/repository/documentation/uploadDocumentationDelete';
import { uploadDocumentationMove } from 'serviceWorker/repository/documentation/uploadDocumentationMove';
import {
  uploadLevel,
  uploadLevelChanges,
  uploadLevelDelete,
  uploadLevelRestore,
} from 'serviceWorker/repository/level/uploadLevel';
import {
  uploadOrganization,
  uploadOrganizationChanges,
} from 'serviceWorker/repository/organization/uploadOrganization';
import {
  uploadProject,
  uploadProjectChanges,
  uploadProjectDelete,
  uploadProjectRestore,
} from 'serviceWorker/repository/project/uploadProject';
import {
  uploadSite,
  uploadSiteChanges,
  uploadSiteDelete,
  uploadSiteRestore,
} from 'serviceWorker/repository/site/uploadSite';
import {
  uploadUser,
  uploadUserDelete,
  uploadUserLabelAndPhoneChange,
  uploadUserPermissionsChange,
} from 'serviceWorker/repository/user/uploadUser';
import { uploadVisibleFieldChanges } from 'serviceWorker/repository/visibleField/uploadVisibleField';
import {
  __HttpRequestModel__,
  HttpRequestModelType,
} from 'shared/domain/httpRequest/httpRequestModel';
import { IHttpRequestRunner } from 'shared/domain/httpRequest/httpRequestRunner';
import { OrganizationInDto } from 'shared/domain/organization/types/dto';
import { ProjectInDto } from 'shared/domain/project/types/dto';
import { UserInDto } from 'shared/domain/user/types/inDto';
import { CompanyInDto } from 'shared/dtos/in/company';
import { ContractInDto } from 'shared/dtos/in/contract';
import { LevelInDto } from 'shared/dtos/in/level';
import { SiteInDto } from 'shared/dtos/in/site';
import { debugLog } from 'shared/logger/debugLog';
import { getDirectories, getDirectory } from '../directories';
import { getDocumentation, getDocumentations } from '../documentations';
import { getDocument, updateDocument } from '../documents';
import { sendDocumentUrlBuilder } from './buildDocumentUrl';
import { CompanyRequestRunner } from './company/requestRunner';
import { ContractRequestRunner } from './contractRequestRunner';
import { DirectoryRequestRunner } from './directoryRequestRunner';
import { DocumentationDeleteRequestRunner } from './documentationDeleteRequestRunner';
import { DocumentationMoveRequestRunner } from './documentationMoveRequestRunner';
import { DocumentationRequestRunner } from './documentationRequestRunner';
import {
  DocumentRequestRunner,
  DrawingRequestRunner,
} from './documentRequestRunner';
import { fetch } from './fetch';
import { Fetcher } from './fetcher';
import { IssueRequestRunner } from './issue/issueRequestRunner';
import { LevelRequestRunner } from './level/requestRunner';
import { OrganizationRequestRunner } from './organization/requestRunner';
import { ProjectRequestRunner } from './project/requestRunner';
import { ReportRequestRunner } from './reportRequestRunner';
import { SiteRequestRunner } from './site/requestRunner';
import { UserRequestRunner } from './user/requestRunner';
import { VisibleFieldRequestRunner } from './visibleFieldRequestRunner';

export interface IRequestRunner {
  execute(request: __HttpRequestModel__): Promise<unknown>;
}

type RunnerMap = {
  [key in HttpRequestModelType]: IRequestRunner;
};

class HttpRequestRunner implements IHttpRequestRunner {
  constructor(private runnerMap: RunnerMap) {}

  execute(request: __HttpRequestModel__): Promise<any> {
    debugLog('Executing request: ', request);
    return this.runnerMap[request.entityType].execute(request);
  }
}

export const httpRequestRunner = new HttpRequestRunner({
  [HttpRequestModelType.document]: new DocumentRequestRunner(
    Fetcher,
    getDocument,
    deleteDocuments,
    sendDocumentUrlBuilder,
    uploadDocument,
    uploadDocumentChange,
    uploadDocumentDelete,
    updateDocument
  ),
  [HttpRequestModelType.drawing]: new DrawingRequestRunner(
    Fetcher,
    getDocument,
    sendDocumentUrlBuilder,
    uploadDrawing,
    uploadClearDrawing
  ),
  [HttpRequestModelType.issue]: new IssueRequestRunner(config.get, fetch),
  [HttpRequestModelType.report]: new ReportRequestRunner(
    config.get,
    fetch
  ),
  [HttpRequestModelType.documentation]: new DocumentationRequestRunner(
    Fetcher,
    uploadDocumentation,
    uploadDocumentationChange,
    getDocumentation
  ),
  [HttpRequestModelType.level]: new LevelRequestRunner(
    Fetcher,
    uploadLevel,
    (levelInDto: LevelInDto) => addLevelBatch([levelInDto]),
    uploadLevelChanges,
    uploadLevelDelete,
    (levelId: string) => updateOneLevel(levelId, { deleted: 1 }),
    uploadLevelRestore,
    (levelId: string) => updateOneLevel(levelId, { deleted: 0 })
  ),
  [HttpRequestModelType.directory]: new DirectoryRequestRunner(
    Fetcher,
    uploadDirectory,
    uploadDirectoryChange,
    getDirectory
  ),
  [HttpRequestModelType.documentationMove]:
    new DocumentationMoveRequestRunner(
      Fetcher,
      uploadDocumentationMove,
      getDocumentations,
      getDirectories
    ),
  [HttpRequestModelType.documentationDelete]:
    new DocumentationDeleteRequestRunner(
      Fetcher,
      uploadDocumentationDelete,
      getDocumentations,
      getDirectories,
      setDocumentationSync,
      setDirectorySync
    ),
  [HttpRequestModelType.site]: new SiteRequestRunner(
    Fetcher,
    uploadSite,
    (siteInDto: SiteInDto) => addSiteBatch([siteInDto]),
    uploadSiteChanges,
    uploadSiteDelete,
    (siteId: string) => updateOneSite(siteId, { deleted: 1 }),
    uploadSiteRestore,
    (siteId: string) => updateOneSite(siteId, { deleted: 0 })
  ),
  [HttpRequestModelType.visibleField]: new VisibleFieldRequestRunner(
    Fetcher,
    uploadVisibleFieldChanges
  ),
  // [HttpRequestModelType.project]: new ProjectRequestRunner(
  //   config.get,
  //   fetch
  // ),
  [HttpRequestModelType.project]: new ProjectRequestRunner(
    // config.get,
    Fetcher,
    uploadProject,
    (projectInDto: ProjectInDto) => addProjectBatch([projectInDto]),
    uploadProjectChanges,
    uploadProjectDelete,
    (projectId: string) => updateOneProject(projectId, { deleted: 1 }),
    uploadProjectRestore,
    (projectId: string) => updateOneProject(projectId, { deleted: 0 })
  ),
  [HttpRequestModelType.organization]: new OrganizationRequestRunner(
    Fetcher,
    uploadOrganization,
    (organizationInDto: OrganizationInDto) =>
      addOrganizationBatch([organizationInDto]),
    uploadOrganizationChanges
  ),
  [HttpRequestModelType.company]: new CompanyRequestRunner(
    Fetcher,
    uploadCompany,
    (companyInDto: CompanyInDto) => addCompanyBatch([companyInDto]),
    uploadCompanyChanges,
    uploadCompanyDelete,
    (companyId: string) => updateOneCompany(companyId, { deleted: 1 }),
    uploadCompanyRestore,
    (companyId: string) => updateOneCompany(companyId, { deleted: 0 })
  ),
  [HttpRequestModelType.contract]: new ContractRequestRunner(
    Fetcher,
    uploadContract,
    (contractInDto: ContractInDto) => addContractBatch([contractInDto]),
    uploadContractChanges,
    uploadContractDelete,
    (contractId: string) => updateOneContract(contractId, { deleted: 1 }),
    uploadContractRestore,
    (contractId: string) => updateOneContract(contractId, { deleted: 0 })
  ),
  [HttpRequestModelType.user]: new UserRequestRunner(
    Fetcher,
    uploadUser,
    (userInDto: UserInDto) => addUserBatch([userInDto]),
    uploadUserLabelAndPhoneChange,
    uploadUserPermissionsChange,
    uploadUserDelete,
    (userId: string) => removeBatch([userId])
  ),
});
