import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { HeaderContext } from 'components/core/Layout/HeaderContext';
import { StoreState } from '../../setup/types/core';
import { ButtonContained } from 'components/general';
import {
  TextField,
  Card,
  CardContent,
  Box,
  Typography,
  Stack,
  Divider,
  Alert,
} from '@mui/material';
import { ClientContext } from '../../with-client';
import useFormEdit from 'views/issue/forms/useFormEdit';
import { FieldSize } from 'shared/types/fieldSize';
import { logError } from 'setup/logger/logError';

const LABEL_MAX_LENGTH = FieldSize.M;

export const SuperadminUser = (): React.ReactElement => {
  const formName = 'currentSuperadminUser';
  const { setRouteFor } = useContext(HeaderContext);
  const user = useSelector((state: StoreState) => state.user);
  const { setEditing } = useFormEdit();
  const intl = useIntl();
  const client = useContext(ClientContext);

  const [label, setLabel] = React.useState('');
  const [initialLabel, setInitialLabel] = React.useState('');
  const [labelError, setLabelError] = React.useState('');

  useEffect(() => {
    setRouteFor('userEdit');
  }, [setRouteFor]);

  useEffect(() => {
    if (user.data.superadminLabel) {
      setLabel(user.data.superadminLabel);
      setInitialLabel(user.data.superadminLabel);
    }
  }, [user.data.superadminLabel]);

  const validateLabel = (value: string): string => {
    if (value.length > LABEL_MAX_LENGTH) {
      return intl.formatMessage(
        { id: 'validation_max_length' },
        { maxLength: LABEL_MAX_LENGTH }
      );
    }
    return '';
  };

  const handleLabelChange = (value: string): void => {
    const error = validateLabel(value);
    setLabelError(error);
    setLabel(value);
    setEditing(true, formName);
  };

  const hasModifications = (): boolean => {
    return label !== initialLabel;
  };

  const handleSave = async (): Promise<void> => {
    try {
      await client.updateSuperadminUser({ superadminLabel: label });

      setEditing(false, formName);
      setInitialLabel(label);
    } catch (error) {
      logError('Failed to update superadmin data:', error);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '600px',
        margin: '0 auto',
        height: '100%',
        p: 3,
      }}
    >
      <Card
        elevation={3}
        sx={{
          borderRadius: 2,
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            bgcolor: 'primary.main',
            color: 'primary.contrastText',
            p: 3,
          }}
        >
          <Typography variant='h5' sx={{ fontWeight: 500 }}>
            <FormattedMessage id='user_view_header' />
          </Typography>
        </Box>

        <Divider />

        <CardContent sx={{ p: 3 }}>
          <Stack spacing={3}>
            <TextField
              fullWidth
              variant='outlined'
              value={label}
              onChange={(e) => handleLabelChange(e.target.value)}
              data-qa='user-view-label-input'
              label={intl.formatMessage({
                id: 'user_view_name_input_label',
              })}
              error={!!labelError}
              helperText={
                labelError || `${label.length}/${LABEL_MAX_LENGTH}`
              }
              inputProps={{ maxLength: LABEL_MAX_LENGTH }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: 1.5,
                },
              }}
            />

            {labelError && (
              <Alert severity='error' sx={{ mt: 2 }}>
                <FormattedMessage id='validation_errors_message' />
              </Alert>
            )}

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                mt: 3,
              }}
            >
              <ButtonContained
                onClick={handleSave}
                disabled={!hasModifications() || !!labelError}
                data-qa='user-view-save-button'
                sx={{
                  minWidth: 120,
                  borderRadius: 2,
                  textTransform: 'none',
                  fontSize: '1rem',
                }}
              >
                <FormattedMessage id='general_save' />
              </ButtonContained>
            </Box>
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
};
