import {
  LabelledUser,
  userModelToLabelled,
} from 'shared/domain/user/mapping/toView';
import { UserModel } from 'shared/domain/user/types/model';
import { UserRole } from 'shared/types/userRole';

export function usersWithAccessSelector(
  roles: UserRole[],
  site: string,
  process: string,
  users: UserModel[],
  organizationId: string
): LabelledUser[] {
  return users
    .filter(
      (user) =>
        roles.includes(user.role) &&
        user.sites.includes(site) &&
        user.processes.includes(process)
    )
    .map((user) => userModelToLabelled(user, organizationId));
}
