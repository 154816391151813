import {
  CreatableOnView,
  Identificable,
  ModifiableOnView,
  NonDeletableOnViewBase,
  OnViewBase,
} from 'shared/types/commonView';
import { stringToViewDate } from 'shared/utils/date/stringToViewDate';
import { CreatableModel, ModelBase, ModifiableModel } from './commonModel';
import { userWithPersonalDataToOptionalLabelled } from './user/mapping/toView';
import { UserWithPersonalData } from './user/types/model';

function modelToCreatableOnView(
  createdBy: UserWithPersonalData | undefined,
  createdAt: string,
  organizationId: string
): CreatableOnView {
  return {
    createdBy: userWithPersonalDataToOptionalLabelled(
      createdBy,
      organizationId
    ),
    createdAt: stringToViewDate(createdAt),
  };
}
function modelToModifiableOnView(
  modifiedBy: UserWithPersonalData | undefined,
  modifiedAt: string,
  organizationId: string
): ModifiableOnView {
  return {
    modifiedBy: userWithPersonalDataToOptionalLabelled(
      modifiedBy,
      organizationId
    ),
    modifiedAt: stringToViewDate(modifiedAt),
  };
}

export function modelToViewBase(
  model: ModelBase,
  organizationId: string
): OnViewBase {
  return {
    ...modelToNonDeletableViewBase(model, organizationId),
    deleted: !!model.deleted,
  };
}

export function modelToNonDeletableViewBase(
  model: Identificable & CreatableModel & ModifiableModel,
  organizationId: string
): NonDeletableOnViewBase {
  const { createdBy, createdAt } = modelToCreatableOnView(
    model.createdBy,
    model.createdAt,
    organizationId
  );
  const { modifiedBy, modifiedAt } = modelToModifiableOnView(
    model.modifiedBy,
    model.modifiedAt,
    organizationId
  );

  return {
    _id: model._id,
    createdAt,
    createdBy,
    modifiedAt,
    modifiedBy,
  };
}
