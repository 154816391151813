import { UserRole } from 'shared/types/userRole';

export const EDIT_DELETE_USER_PERMISSIONS: Record<UserRole, boolean> = {
  [UserRole.superAdmin]: true,
  [UserRole.organizationAdmin]: true,
  [UserRole.projectAdmin]: true,
  [UserRole.manager]: false,
  [UserRole.viewer]: false,
  [UserRole.standard]: false,
  [UserRole.visible_no_access]: false,
};

export const EDIT_COMPANY_PERMISSIONS: Record<UserRole, boolean> = {
  [UserRole.superAdmin]: true,
  [UserRole.organizationAdmin]: true,
  [UserRole.projectAdmin]: true,
  [UserRole.manager]: true,
  [UserRole.viewer]: false,
  [UserRole.standard]: false,
  [UserRole.visible_no_access]: false,
};

export const CREATE_COMPANY_PERMISSIONS: Record<UserRole, boolean> = {
  [UserRole.superAdmin]: true,
  [UserRole.organizationAdmin]: true,
  [UserRole.projectAdmin]: true,
  [UserRole.manager]: true,
  [UserRole.viewer]: false,
  [UserRole.standard]: false,
  [UserRole.visible_no_access]: false,
};

export const EDIT_CONTRACT_PERMISSIONS: Record<UserRole, boolean> = {
  [UserRole.superAdmin]: true,
  [UserRole.organizationAdmin]: true,
  [UserRole.projectAdmin]: true,
  [UserRole.manager]: true,
  [UserRole.viewer]: false,
  [UserRole.standard]: false,
  [UserRole.visible_no_access]: false,
};

export const CREATE_CONTRACT_PERMISSIONS: Record<UserRole, boolean> = {
  [UserRole.superAdmin]: true,
  [UserRole.organizationAdmin]: true,
  [UserRole.projectAdmin]: true,
  [UserRole.manager]: true,
  [UserRole.viewer]: false,
  [UserRole.standard]: false,
  [UserRole.visible_no_access]: false,
};

export const CREATE_LEVEL_PERMISSIONS: Record<UserRole, boolean> = {
  [UserRole.superAdmin]: true,
  [UserRole.organizationAdmin]: true,
  [UserRole.projectAdmin]: true,
  [UserRole.manager]: false,
  [UserRole.viewer]: false,
  [UserRole.standard]: false,
  [UserRole.visible_no_access]: false,
};

export const CREATE_LOCATION_PERMISSIONS: Record<UserRole, boolean> = {
  [UserRole.superAdmin]: true,
  [UserRole.organizationAdmin]: true,
  [UserRole.projectAdmin]: true,
  [UserRole.manager]: false,
  [UserRole.viewer]: false,
  [UserRole.standard]: false,
  [UserRole.visible_no_access]: false,
};

export const INSPECTION_CRUD_PERMISSIONS: Record<UserRole, boolean> = {
  [UserRole.superAdmin]: true,
  [UserRole.organizationAdmin]: true,
  [UserRole.projectAdmin]: true,
  [UserRole.manager]: true,
  [UserRole.viewer]: false,
  [UserRole.standard]: false,
  [UserRole.visible_no_access]: false,
};

export const CREATE_USER_PERMISSIONS: Record<UserRole, boolean> = {
  [UserRole.superAdmin]: true,
  [UserRole.organizationAdmin]: true,
  [UserRole.projectAdmin]: true,
  [UserRole.manager]: false,
  [UserRole.viewer]: false,
  [UserRole.standard]: false,
  [UserRole.visible_no_access]: false,
};

export const CREATE_ORGANIZATION_PERMISSIONS: Record<UserRole, boolean> = {
  [UserRole.superAdmin]: true,
  [UserRole.organizationAdmin]: true,
  [UserRole.projectAdmin]: true,
  [UserRole.manager]: true,
  [UserRole.viewer]: true,
  [UserRole.standard]: true,
  [UserRole.visible_no_access]: false,
};

export const EDIT_ORGANIZATION_PERMISSIONS: Record<UserRole, boolean> = {
  [UserRole.superAdmin]: true,
  [UserRole.organizationAdmin]: true,
  [UserRole.projectAdmin]: false,
  [UserRole.manager]: false,
  [UserRole.viewer]: false,
  [UserRole.standard]: false,
  [UserRole.visible_no_access]: false,
};

export const EDIT_PROJECT_PERMISSIONS: Record<UserRole, boolean> = {
  [UserRole.superAdmin]: true,
  [UserRole.organizationAdmin]: true,
  [UserRole.projectAdmin]: false,
  [UserRole.manager]: false,
  [UserRole.viewer]: false,
  [UserRole.standard]: false,
  [UserRole.visible_no_access]: false,
};
