import { OrganizationModel } from 'shared/domain/organization/types/model';
import { UserPermissionDto, UserRole } from 'shared/types/userRole';

export function getClientAdminPermission(
  basePermissions: UserPermissionDto,
  organizations: Map<string, OrganizationModel>
): Map<string, boolean> {
  const permissionsMap = new Map();

  if (basePermissions.role === UserRole.superAdmin) {
    organizations.forEach((organization) =>
      permissionsMap.set(organization._id, true)
    );
    return permissionsMap;
  }

  basePermissions.clients?.forEach((client) => {
    if (client.role === UserRole.organizationAdmin) {
      permissionsMap.set(client._id, true);
    }
  });

  return permissionsMap;
}
