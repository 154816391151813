import { BroadcastChannel } from 'broadcast-channel';
import { ChannelNames } from 'shared/domain/channelNames';
import {
  ApiChannelMessage,
  ServiceMethods,
  Services,
} from 'shared/domain/messages/message';
import { UserCreateCustomEventDetail } from 'shared/domain/messages/user/eventMessages';
import { debugLog } from 'shared/logger/debugLog';
import { UserCreateModel } from './types/model';

export function startUserCreate(
  userCreateModel: UserCreateModel,
  uniqueId: string
): void {
  debugLog('startUserCreate', userCreateModel, uniqueId);
  const broadcast = new BroadcastChannel<
    ApiChannelMessage<UserCreateCustomEventDetail>
  >(ChannelNames.apiChannel);
  broadcast.postMessage({
    service: Services.USERS,
    method: ServiceMethods.CREATE,
    data: {
      userCreateModel,
      uniqueId,
    },
  });
  broadcast.close();
}
