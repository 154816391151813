import { SubmitOptions } from 'components/dataCreationForms/withInputForm';
import { SetableInspectionFields } from 'components/dataProviders/withInspectionSubmit/types';
import { InspectionTemplateItemModel } from 'components/dataProviders/withRequiredInspectionTemplate/types';
import { EntityName } from 'shared/domain/fieldValue/fields';
import { LevelOnView } from 'shared/domain/level/types/view';
import { HashMap, LabelledEntity } from 'shared/types/commonView';

export type InspectionFormProps = {
  loading: boolean;
  onSubmit: (options?: SubmitOptions) => void;
  onCancel: () => void;
  isPosting: boolean;
};

export type InspectionFormPresentationalProps = {
  template: InspectionTemplateItemModel;
  onSubmit: () => void;
  onFinish: () => void;
  onCancel: () => void;
  isPosting: boolean;

  executionFieldProps: InspectionFieldProps;
  workTypesFieldProps: InspectionFieldProps;
  sitesFieldProps: InspectionFieldProps;
  levelsFieldProps: InspectionFieldProps;
  controlledPartiesFieldProps: InspectionFieldProps;
  contractsFieldProps: InspectionFieldProps;
  commentFieldProps: InspectionFieldProps;
  checklist: InspectionTemplateItemModel['checklist'];
  protocolIssues: number;
};

export type InspectionFieldProps = FormFieldProps<SetableInspectionFields>;
type Common<T> = {
  formKey: keyof T;
  required: boolean;
  labelId: string;
  fieldName: string;
  localStorageKey?: string;
  disabled?: boolean | { reason: string };
  available?: LabelledEntity[] | HashMap<LabelledEntity[]>;
  getOptionLabel?: (option: LabelledEntity) => string;
  getOptionSelected?: (
    option: LabelledEntity,
    value: LabelledEntity
  ) => boolean;
  getOptionDisabled?: (option: LabelledEntity) => boolean;
  dependsOn?: keyof T;
  extractor?: (
    levels: HashMap<LevelOnView[]>,
    site: LabelledEntity
  ) => LabelledEntity[] | undefined;
  minRows?: number;
  disableClearable?: boolean;
  autoSelect?: 'asSingleSelect' | 'asMultiSelect';
  reserveSpaceForHelperText: boolean;
  dense: boolean;
  'data-qa': string;
};
export type FormFieldProps<T> = {
  minDate?: Date | null;
  maxDate?: Date | null;
  type?: 'number';
} & Common<T>;

export type MultiChoiceFormFieldProps<T> = {
  entityName: EntityName | '';
} & Common<T>;

export type SingleChoiceFormFieldProps<T> = MultiChoiceFormFieldProps<T>;

export enum InspectionFieldName {
  inspectionDate = 'inspection-date',
  multiselectWorkTypes = 'multiselect-worktypes',
  location = 'location',
  multiselectLevels = 'multiselect-levels',
  multiselectControlledParties = 'multiselect-controlled-parties',
  multiselectContracts = 'multiselect-contracts',
  textFieldComment = 'text-field-comment',
}
