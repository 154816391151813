import React, {
  Fragment,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import 'react-base-table/styles.css';
import 'components/table/tableOverrides.scss';
import { MemoButtonPlusCreate } from 'components/general/ButtonPlusCreate';
import { useStyles } from './styles';
import { useSelector } from 'react-redux';

import { MemoInspectionsTable } from 'components/inspection/Table';
import TableSkeleton from 'components/table/skeleton';
import {
  checkProcessesEquality,
  toProcessesObject,
} from 'redux/selectors/processes';
import { ResizeObserverChange } from './types';
import { resize } from './helpers';
import { withFetcherReady } from 'components/common/withFetcherReady';
import { withProcessesData } from 'components/common/withProcessesData';
import { noop } from 'shared/utils/function';
import { projectDataSelector } from 'redux/selectors/project';
import {
  useInspections,
  withInspections,
} from 'components/dataProviders/withInspections';
import {
  useInspectionTemplates,
  withInspectionTemplates,
} from 'components/dataProviders/withInspectionTemplates';
import {
  toTemplateModelsObject,
  useInspectionDependencies,
} from 'components/dataProviders/withInspection/model';
import { toInspectionOnTableView } from 'presentation/inspection/inspectionModelToInspectionOnView';
import { InspectionOnTableView } from 'presentation/inspection/inspectionOnView';
import { withCompanies } from 'components/dataProviders/withCompanies';
import { withContracts } from 'components/dataProviders/withContracts';
import { withCorrectiveActionTypes } from 'components/dataProviders/withCorrectiveActionTypes';
import { withEnvironmentalAspects } from 'components/dataProviders/withEnvironmentalAspects';
import { withHazardCategories } from 'components/dataProviders/withHazardCategories';
import { withLevels } from 'components/dataProviders/withLevels';
import { withSites } from 'components/dataProviders/withSites';
import { withWorktypes } from 'components/dataProviders/withWorktypes';

function Inspections(): ReactElement {
  const tableWrapperRef = useRef<HTMLDivElement | null>(null);
  const [width, setWidth] = React.useState(0);
  const [height, setHeight] = React.useState(0);
  const [data, setData] = React.useState<InspectionOnTableView[]>([]);
  const [loaded, setLoaded] = React.useState(false);
  const processes = useSelector(toProcessesObject, checkProcessesEquality);
  const { timezone, organizationId } = useSelector(projectDataSelector);

  const { inspectionTemplates, loading: templatesLoading } =
    useInspectionTemplates();
  const { inspections, loading: inspectionsLoading } = useInspections();

  const {
    loading: loadingDependencies,
    sites,
    levels,
    workTypes,
    companies,
    contracts,
  } = useInspectionDependencies();

  useEffect(() => {
    const isLoaded = !(
      templatesLoading ||
      inspectionsLoading ||
      loadingDependencies
    );
    setLoaded(isLoaded);
    if (isLoaded) {
      const templatesObject = inspectionTemplates.items.reduce(
        toTemplateModelsObject,
        {}
      );
      setData(
        inspections.items
          .map((insp) =>
            toInspectionOnTableView(
              processes,
              templatesObject,
              insp,
              sites,
              levels,
              workTypes,
              companies,
              contracts,
              organizationId
            )
          )
          .sort(
            (a, b) => b.modifiedAt.toMillis() - a.modifiedAt.toMillis()
          )
      );
    }
  }, [
    templatesLoading,
    inspectionsLoading,
    inspectionTemplates,
    inspections,
    processes,
    timezone,
    loadingDependencies,
    sites,
    levels,
    workTypes,
    companies,
    contracts,
  ]);

  const classes = useStyles();

  useEffect(() => {
    const ResizeObserver = (window as any).ResizeObserver;
    if (!ResizeObserver) return;
    if (!tableWrapperRef.current) return;
    const theRef = tableWrapperRef.current;
    let timeout: ReturnType<typeof setTimeout>;
    const resizeObserver = new ResizeObserver(
      (changes: ResizeObserverChange[]) => {
        clearTimeout(timeout);
        timeout = setTimeout(
          () => resize(changes, setWidth, setHeight, tableWrapperRef),
          100
        );
      }
    );

    resizeObserver.observe(theRef);
    resizeObserver.observe(document.body);
    return (): void => {
      clearTimeout(timeout);
      resizeObserver.unobserve(theRef);
      resizeObserver.unobserve(document.body);
    };
  }, []);

  useEffect(() => {
    setWidth(tableWrapperRef.current?.clientWidth || 0);
    setHeight(tableWrapperRef.current?.clientHeight || 0);
  }, []);

  const canCreate = useCallback(() => {
    return {
      permission: true,
    };
  }, []);

  return (
    <Fragment>
      <div className={classes.head} style={{ display: 'none' }}>
        <MemoButtonPlusCreate
          id='create-inspection-button'
          textId='button_create_inspection'
          dataQaId='create-inspection-button'
          canCreate={canCreate}
          creationAction={noop}
        />
      </div>
      <div className={classes.relativeWrapper}>
        <div
          id='hustro-inspections-table'
          className={classes.absoluteWrapper}
          ref={tableWrapperRef}
        >
          {loaded ? (
            <MemoInspectionsTable
              width={width}
              height={height}
              data={data}
            />
          ) : (
            <TableSkeleton />
          )}
        </div>
      </div>
    </Fragment>
  );
}
export const MemoInspections = React.memo(
  withFetcherReady(
    withProcessesData(
      withSites(
        withLevels(
          withWorktypes(
            withHazardCategories(
              withEnvironmentalAspects(
                withCorrectiveActionTypes(
                  withContracts(
                    withCompanies(
                      withInspectionTemplates(withInspections(Inspections))
                    )
                  )
                )
              )
            )
          )
        )
      )
    )
  )
);
