import { UserWithPermissionsInDto } from 'shared/domain/user/types/inDto';
import { Process } from './process';

export function isBelowSuperAdminPermission(
  permissions: UserPermissionDto
): permissions is BelowSuperAdminPermission {
  return (permissions as BelowSuperAdminPermission).clients !== undefined;
}

export enum UserRole {
  visible_no_access = 'visible_no_access',
  standard = 'standard',
  manager = 'manager',
  viewer = 'viewer',
  projectAdmin = 'admin',
  organizationAdmin = 'clientadmin',
  superAdmin = 'superadmin',
}

// it't not possible to create superadmin
export type CreatableUserRole =
  | UserRole.visible_no_access
  | UserRole.standard
  | UserRole.viewer
  | UserRole.manager
  | UserRole.projectAdmin
  | UserRole.organizationAdmin;

export const USER_ROLE_LABEL_ID: Record<UserRole, string> = {
  [UserRole.visible_no_access]: 'role_visible_no_access',
  [UserRole.standard]: 'role_standard',
  [UserRole.manager]: 'role_manager',
  [UserRole.viewer]: 'role_viewer',
  [UserRole.projectAdmin]: 'role_project_admin',
  [UserRole.superAdmin]: 'role_admin',
  [UserRole.organizationAdmin]: 'role_admin',
};

type StandardManagerOrViewerProjectPermission = {
  role: UserRole.standard | UserRole.viewer | UserRole.manager;
  // project id
  _id: string;
  processes: Process[];
  sites: string[];
};

type VisibleWithNoAccessProjectPermission = {
  role: UserRole.visible_no_access;
  // project id
  _id: string;
  processes: never;
  sites: never;
};

type ProjectAdminPermission = {
  role: UserRole.projectAdmin;
  // project id
  _id: string;
  processes: never;
  sites: never;
};

type PermissionOnProject =
  | ProjectAdminPermission
  | StandardManagerOrViewerProjectPermission
  | VisibleWithNoAccessProjectPermission;

export type BelowOrganizationAdminPermission = {
  projects: PermissionOnProject[];
  role?: never;
  // organization id
  _id: string;
};

type OrganizationAdminPermission = {
  role: UserRole.organizationAdmin;
  projects: never;
  // organization id
  _id: string;
};

type PermissionOnOrganization =
  | OrganizationAdminPermission
  | BelowOrganizationAdminPermission;

type SuperAdminPermission = {
  role: UserRole.superAdmin;
};

type BelowSuperAdminPermission = {
  clients: PermissionOnOrganization[];
  role?: never;
};

export type UserPermissionDto =
  | SuperAdminPermission
  | BelowSuperAdminPermission;

export type UserWithAuthAndPermissions = {
  isAuthenticated: boolean;
  data: UserWithPermissionsInDto;
  error: null | string;
};
