import {
  ProjectCreateModel,
  ProjectEditModel,
  ProjectModel,
} from 'shared/domain/project/types/model';
import { DayMomentValidation } from 'shared/types/time';
import { dateTimeToDayMomentDateTime } from 'shared/utils/date/dates';
import { ProjectEntity } from '../types/entity';
import { ProjectCreateOnView, ProjectEditOnView } from '../types/view';

export function projectEntityToModel(
  projectEntity: ProjectEntity
): ProjectModel {
  const {
    createdAt,
    deleted,
    modifiedAt,
    _id,
    clientId,
    name,
    timezone,
    budget,
    currency,
    projectStartDate,
    projectEndDate,
    localeCode,
    processes,
  } = projectEntity;

  return {
    createdAt,
    deleted: Boolean(deleted),
    modifiedAt,
    _id,
    organizationId: clientId,
    name,
    timezone,
    budget,
    currency,
    projectStartDate,
    projectEndDate,
    localeCode,
    processes,
  };
}

export function projectCreateOnViewToProjectCreateModel({
  name,
  organization,
  currency,
  locale,
  processes,
  timezone,

  projectStartDate,
  projectEndDate,
  budget,
}: ProjectCreateOnView): ProjectCreateModel {
  const projectCreateModel: ProjectCreateModel = {
    name,
    organizationId: organization._id,
    currency: currency._id,
    localeCode: locale._id,
    processes: processes.map((process) => process._id),
    timezone: timezone._id,
  };

  if (budget) {
    projectCreateModel.budget = parseFloat(
      budget.replaceAll('.', '').replaceAll(',', '.')
    );
  }
  if (projectStartDate) {
    projectCreateModel.projectStartDate =
      dateTimeToDayMomentDateTime(
        projectStartDate,
        DayMomentValidation.start
      )?.toISO() || undefined;
  }
  if (projectEndDate) {
    projectCreateModel.projectEndDate =
      dateTimeToDayMomentDateTime(
        projectEndDate,
        DayMomentValidation.end
      )?.toISO() || undefined;
  }

  return projectCreateModel;
}

export function projectEditOnViewToEditModel(
  project: ProjectEditOnView
): ProjectEditModel {
  return {
    ...projectCreateOnViewToProjectCreateModel(project),
    _id: project._id,
  };
}
