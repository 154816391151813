import { LevelOnView } from 'shared/domain/level/types/view';
import { SiteModel } from 'shared/domain/site/types/model';
import { userWithPersonalDataToOptionalLabelled } from 'shared/domain/user/mapping/toView';
import { HashMap } from 'shared/types/commonView';
import { stringToViewDate } from 'shared/utils/date/stringToViewDate';
import { SiteOnView } from '../../shared/domain/site/types/view';
import { modelToViewBase } from 'shared/domain/toBaseView';

export function siteModelToSiteOnView(
  model: SiteModel,
  levelsPerSite: HashMap<LevelOnView[]>,
  organizationId: string
): SiteOnView {
  const onViewBase = modelToViewBase(model, organizationId);

  return {
    ...onViewBase,
    label: model.label,
    code: model.code,
    levels: levelsPerSite[model._id] || [],
  };
}
