import { entityToNonDeletableModelBase } from 'shared/domain/toBaseModel';
import {
  UserCreateOnView,
  UserEditOnView,
} from 'shared/domain/user/types/view';
import { debugLog } from 'shared/logger/debugLog';
import { Identificable } from 'shared/types/commonView';
import { CreatableUserRole, UserRole } from 'shared/types/userRole';
import { nowISO } from 'shared/utils/date/dates';
import { PersonalData, UserEntity } from '../types/entity';
import {
  ProjectPermissionDto,
  UserInDto,
  UserWithPermissionsInDto,
} from '../types/inDto';
import {
  PermissionsModel,
  UserCreateModel,
  UserEditModel,
  UserModel,
} from '../types/model';
import { setPersonalDataForOrganization } from './toView';

function createPermissionsForNewUser(
  userCreateOnView: UserCreateOnView,
  {
    projectId,
    organizationId,
  }: {
    projectId: string;
    organizationId: string;
  }
): PermissionsModel {
  return permissionsForRole({
    role: userCreateOnView.role._id,
    processes: userCreateOnView.processes,
    sites: userCreateOnView.sites,
    projectId,
    organizationId,
  });
}

function createPermissionsForExistingUser(
  user: UserCreateOnView | UserEditOnView,
  {
    previousRole,
    previousSites,
    previousProcesses,
    projectId,
    organizationId,
  }: {
    previousRole: CreatableUserRole;
    previousSites: Identificable[];
    previousProcesses: Identificable[];
    projectId: string;
    organizationId: string;
  }
): PermissionsModel | undefined {
  const newRole = user.role._id;
  const isRoleUnchanged = newRole === previousRole;
  if (
    newRole === UserRole.organizationAdmin ||
    newRole === UserRole.projectAdmin
  ) {
    if (isRoleUnchanged) {
      return;
    }
    return getAdminPermissions(newRole, organizationId, projectId);
  }
  if (
    isRoleUnchanged &&
    isPermissionEqual(
      {
        processes: user.processes.map((p) => p._id),
        sites: user.sites.map((s) => s._id),
      },
      {
        processes: previousProcesses.map((p) => p._id),
        sites: previousSites.map((s) => s._id),
      }
    )
  ) {
    return;
  }
  return permissionsForRole({
    role: user.role._id,
    processes: user.processes,
    sites: user.sites,
    projectId,
    organizationId,
  });
}

function getClientAdminPermissions(clientId: string): PermissionsModel {
  return {
    clients: [
      {
        _id: clientId,
        role: UserRole.organizationAdmin,
      },
    ],
  };
}

function getProjectAdminPermissions(
  clientId: string,
  projectId: string
): PermissionsModel {
  return {
    clients: [
      {
        _id: clientId,
        projects: [{ _id: projectId, role: UserRole.projectAdmin }],
      },
    ],
  };
}

function getAdminPermissions(
  adminType: UserRole.organizationAdmin | UserRole.projectAdmin,
  clientId: string,
  projectId: string
): PermissionsModel {
  if (adminType === UserRole.projectAdmin) {
    return getProjectAdminPermissions(clientId, projectId);
  } else if (adminType === UserRole.organizationAdmin) {
    return getClientAdminPermissions(clientId);
  }
}

function getNonAdminPermissions(
  clientId: string,
  projectId: string,
  sites: Identificable[],
  processes: Identificable[],
  role: UserRole.standard | UserRole.viewer | UserRole.manager
): PermissionsModel {
  return {
    clients: [
      {
        _id: clientId,
        projects: [
          {
            _id: projectId,
            sites: sites.map((site) => site._id),
            processes: processes.map((process) => process._id),
            role,
          },
        ],
      },
    ],
  };
}

function getVisibleNoAccessProjectPermissions(
  clientId: string,
  projectId: string
): PermissionsModel {
  return {
    clients: [
      {
        _id: clientId,
        projects: [
          {
            _id: projectId,
            role: UserRole.visible_no_access,
          },
        ],
      },
    ],
  };
}

function permissionsForRole({
  role,
  processes,
  sites,
  projectId,
  organizationId,
}: {
  role: CreatableUserRole;
  processes: Identificable[];
  sites: Identificable[];
  projectId: string;
  organizationId: string;
}): PermissionsModel {
  if (role === UserRole.organizationAdmin) {
    return getClientAdminPermissions(organizationId);
  }

  if (role === UserRole.projectAdmin) {
    return getProjectAdminPermissions(organizationId, projectId);
  }

  if (role === UserRole.visible_no_access) {
    return getVisibleNoAccessProjectPermissions(organizationId, projectId);
  }

  return getNonAdminPermissions(
    organizationId,
    projectId,
    sites,
    processes,
    role
  );
}

function isPermissionEqual(object1: {}, object2: {}): boolean {
  const keys1 = Object.keys(object1).sort((a, b) => a.localeCompare(b));
  const keys2 = Object.keys(object2).sort((a, b) => a.localeCompare(b));
  if (JSON.stringify(keys1) !== JSON.stringify(keys2)) {
    return false;
  }

  return keys1.every((key) => {
    const type1 = typeof object1[key];
    const type2 = typeof object2[key];

    if (type1 !== type2) {
      return false;
    }

    if (type1 === 'string') {
      return type1 === type2;
    }

    const isArray1 = Array.isArray(object1[key]);
    const isArray2 = Array.isArray(object2[key]);
    if (isArray1 !== isArray2) {
      return false;
    }

    if (
      isArray1 &&
      isArray2 &&
      JSON.stringify(object1[key].sort((a, b) => a.localeCompare(b))) !==
        JSON.stringify(object2[key].sort((a, b) => a.localeCompare(b)))
    ) {
      return false;
    }

    return true;
  });
}

export function userCreateOnViewToUserCreateModel({
  userCreateOnView,
  projectId,
  organizationId,
}: {
  userCreateOnView: UserCreateOnView;
  projectId: string;
  organizationId: string;
}): UserCreateModel {
  return {
    email: userCreateOnView.email,
    permissions: createPermissionsForNewUser(userCreateOnView, {
      projectId,
      organizationId,
    }),
    projectId,
    organizationId,
  };
}

export function userEditOnViewToUserEditModel({
  userEditOnView,
  projectId,
  organizationId,
  previousValues,
}: {
  userEditOnView: UserEditOnView;
  projectId: string;
  organizationId: string;
  previousValues: {
    role: CreatableUserRole;
    sites: Identificable[];
    processes: Identificable[];
    label: string;
    phone: string;
  };
}): UserEditModel {
  const userEditModel: UserEditModel = {
    _id: userEditOnView._id,
    email: userEditOnView.email,
    projectId,
    organizationId,
  };
  const newPermissions = createPermissionsForExistingUser(userEditOnView, {
    previousRole: previousValues.role,
    projectId,
    organizationId,
    previousSites: previousValues.sites,
    previousProcesses: previousValues.processes,
  });
  if (newPermissions) {
    userEditModel.permissions = newPermissions;
  }

  const hasChangedLabel =
    !!userEditOnView.label &&
    userEditOnView.label !== previousValues.label;
  if (hasChangedLabel) {
    userEditModel.label = userEditOnView.label;
  }

  const hasChangedPhone =
    !!userEditOnView.phone &&
    userEditOnView.phone !== previousValues.phone;
  if (hasChangedPhone) {
    userEditModel.phone = userEditOnView.phone;
  }

  return userEditModel;
}

export function userEntityToOptionalModel(
  user: UserEntity | undefined,
  users: UserEntity[]
): UserModel | undefined {
  if (!user) {
    return undefined;
  }
  return userEntityToModel(user, users);
}

export function userEntityToModel(
  entity: UserEntity,
  users: UserEntity[]
): UserModel {
  const modelBase = entityToNonDeletableModelBase({ entity, users });

  return {
    ...modelBase,

    sites: entity.sites,
    personalData: entity.personalData,
    processes: entity.processes,
    role: entity.role,
    email: entity.email,
  };
}

function getPermissionsOrCreateDefault(
  projectPermissions?: ProjectPermissionDto
): Pick<UserInDto, 'role' | 'sites' | 'processes'> {
  const { role, processes, sites } = projectPermissions ?? {
    role: UserRole.standard,
    sites: [],
    processes: [],
  };

  return { role, processes, sites };
}

export function getRoleFromUserWithPermissionsInDto(
  userWithPermissionsInDto: UserWithPermissionsInDto,
  currentProjectId: string
): UserRole {
  const role =
    userWithPermissionsInDto.basePermissions.role ||
    userWithPermissionsInDto.permissions.find(
      (permission) => permission.project === currentProjectId
    )?.role;
  if (!role) {
    debugLog('returning default role');
    return UserRole.standard;
  }
  return role;
}

export function userWithPermissionsInDtoToModel(
  currentProjectId: string,
  userWithPermissionsInDto: UserWithPermissionsInDto
): UserModel {
  const { _id, permissions, email, personalData } =
    userWithPermissionsInDto;

  const projectPermissions = permissions.find(
    (permission) => permission.project === currentProjectId
  );

  // TODO user on client has no created at and modified at properties
  return {
    createdAt: nowISO(),
    modifiedAt: nowISO(),
    _id,
    email,
    personalData,
    ...getPermissionsOrCreateDefault(projectPermissions),
  };
}
