import {
  EventDescriptionPartToViewMapper,
  MentionToViewMapper,
  SelfMentionToViewMapper,
  TextDescriptionToViewMapper,
} from 'components/dataProviders/withIssue/eventDescription';
import { IssueEventOnView } from 'components/dataProviders/withIssue/model';
import {
  EventDescriptionPartModel,
  IssueEventModel,
  MentionModel,
  TextDescriptionModel,
} from 'shared/domain/issue/issueModel';
import { modelToViewBase } from 'shared/domain/toBaseView';
import {
  userModelToLabelledWithEmail,
  userWithPersonalDataToOptionalLabelled,
} from 'shared/domain/user/mapping/toView';
import { UserModel } from 'shared/domain/user/types/model';
import { EventDescriptionType } from 'shared/types/eventDescription';
import { stringToViewDate } from 'shared/utils/date/stringToViewDate';

function EventDescriptionPartModelToOnView(
  description: EventDescriptionPartModel,
  currentUserId: string,
  users: UserModel[],
  organizationId: string
): EventDescriptionPartToViewMapper | null {
  switch (description.type) {
    case EventDescriptionType.text:
      return new TextDescriptionToViewMapper(
        description as TextDescriptionModel
      );
    case EventDescriptionType.mention:
      const mention = description as MentionModel;
      const user = users.find(
        (userModel) => userModel._id === mention.user
      );
      if (!user) {
        return null;
      }
      const labelledUser = userModelToLabelledWithEmail(
        user,
        organizationId
      );
      return mention.user === currentUserId
        ? new SelfMentionToViewMapper({
            type: mention.type,
            user: labelledUser,
          })
        : new MentionToViewMapper({
            type: mention.type,
            user: labelledUser,
          });
  }
}

export function issueEventModeltoOnView(
  event: IssueEventModel,
  currentUserId: string,
  users: UserModel[],
  organizationId: string
): IssueEventOnView {
  const onViewBase = modelToViewBase(event, organizationId);

  return {
    ...onViewBase,
    title: event.title,
    type: event.type,
    description: event.description.reduce<
      EventDescriptionPartToViewMapper[]
    >((descriptionPartsOnView, partModel) => {
      const part = EventDescriptionPartModelToOnView(
        partModel,
        currentUserId,
        users,
        organizationId
      );
      if (part !== null) {
        descriptionPartsOnView.push(part);
      }

      return descriptionPartsOnView;
    }, []),
    loading: false,
  };
}
