import {
  IUserUploader,
  UploadLabelAndPhoneUserChange,
  UploadPermissionsUserChange,
  UploadUser,
  UploadUserDelete,
} from 'serviceWorker/repository/user/uploadUser';
import { __HttpRequestModel__ } from 'shared/domain/httpRequest/httpRequestModel';
import { UserInDto } from 'shared/domain/user/types/inDto';
import { IRequestRunner } from '../httpRequestRunner';
import {
  UserHttpCreateRequestModel,
  UserHttpDeleteRequestModel,
  UserHttpEditLabelAndPhoneRequestModel,
  UserHttpEditPermissionsRequestModel,
} from './types';

export class UserRequestRunner implements IRequestRunner {
  constructor(
    private userUploader: IUserUploader,
    private uploadUserUseCase: UploadUser,
    private saveUser: (data: UserInDto) => Promise<unknown>,
    private uploadUserLabelAndPhoneChangesUseCase: UploadLabelAndPhoneUserChange,
    private uploadUserPermissionChangesUseCase: UploadPermissionsUserChange,
    private uploadUserDeleteUseCase: UploadUserDelete,
    private deleteUser: (userId: string) => Promise<unknown>
  ) {}
  async execute(request: __HttpRequestModel__): Promise<unknown> {
    switch (request.method) {
      case 'POST': {
        return this.uploadUser(request as UserHttpCreateRequestModel);
      }
      case 'PUT': {
        // TODO: should be separated
        if (request.data?.userEditPermissionsOutDto) {
          return this.uploadUserPermissionChanges(
            request as UserHttpEditPermissionsRequestModel
          );
        }
        return this.uploadUserPhoneAndLabelChanges(
          request as UserHttpEditLabelAndPhoneRequestModel
        );
      }
      case 'DELETE': {
        return this.uploadUserDelete(
          request as UserHttpDeleteRequestModel
        );
      }
    }
  }

  private async uploadUser(
    request: UserHttpCreateRequestModel
  ): Promise<UserInDto> {
    const userInDto = await this.uploadUserUseCase(
      this.userUploader,
      request.data.userCreateOutDto,
      request.url
    );
    const result = await this.saveUser(userInDto);
    return userInDto;
  }

  private async uploadUserPhoneAndLabelChanges(
    request: UserHttpEditLabelAndPhoneRequestModel
  ): Promise<unknown> {
    return this.uploadUserLabelAndPhoneChangesUseCase(
      this.userUploader,
      request.data?.userLabelAndPhoneEditOutDto,
      request.url
    );
  }

  private async uploadUserPermissionChanges(
    request: UserHttpEditPermissionsRequestModel
  ): Promise<unknown> {
    return this.uploadUserPermissionChangesUseCase(
      this.userUploader,
      request.data.userEditPermissionsOutDto,
      request.url
    );
  }

  private async uploadUserDelete(
    request: UserHttpDeleteRequestModel
  ): Promise<boolean> {
    await this.uploadUserDeleteUseCase(this.userUploader, request.url);
    await this.deleteUser(request.data.userId);
    return true;
  }
}
