import { DialogType } from 'components/core/Dialog/common/Dialog';
import { DialogFactory } from 'components/core/Dialog/common/DialogContext';
import { SetValuesOptions } from 'components/dataCreationForms/withInputForm';
import { useCallback, useState } from 'react';
import { IntlShape } from 'react-intl';
import { LabelledEntity } from 'shared/types/commonView';
import { CreatableUserRole, UserRole } from 'shared/types/userRole';

type UseAdminSelectionProps = {
  intl: IntlShape;
  createDialog: DialogFactory;
  processesValue: LabelledEntity;
  sitesValue: LabelledEntity;
  availableSites: LabelledEntity[];
  availableProcesses: LabelledEntity[];
  setValues: (key: string, value: any, options?: SetValuesOptions) => void;
};

type UseAdminSelection = {
  adminSelected?: CreatableUserRole;
  handleAdminSelection: (role?: CreatableUserRole) => void;
  handleAdminDeselection: () => void;
};

export const useAdminSelection = ({
  intl,
  createDialog,
  processesValue,
  sitesValue,
  availableSites,
  availableProcesses,
  setValues,
}: UseAdminSelectionProps): UseAdminSelection => {
  const [adminSelected, setAdminSelected] = useState<
    CreatableUserRole | undefined
  >();

  const handleAdminSelection = useCallback(
    (role: CreatableUserRole | undefined) => {
      createDialog({
        title: intl.formatMessage({
          id:
            role === UserRole.projectAdmin
              ? 'set_admin_role_dialog_title'
              : 'set_client_admin_role_dialog_title',
        }),
        description: (
          <span>
            {intl.formatMessage({
              id:
                role === UserRole.projectAdmin
                  ? 'set_admin_role_dialog_body'
                  : 'set_client_admin_role_dialog_body',
            })}
          </span>
        ),
        type: DialogType.info,
        submitLabel: 'general_ok',
      }).then(() => {
        setAdminSelected(role);
        setValues('sites', availableSites);
        setValues('processes', availableProcesses);
      });
    },
    [
      createDialog,
      intl,
      processesValue,
      sitesValue,
      availableSites,
      availableProcesses,
      setValues,
    ]
  );

  const handleAdminDeselection = useCallback(() => {
    setAdminSelected(undefined);
    setValues('sites', sitesValue);
    setValues('processes', processesValue);
  }, [setValues, sitesValue, processesValue]);

  return { adminSelected, handleAdminSelection, handleAdminDeselection };
};
