import { Block } from '@mui/icons-material';
import { useIsOffline } from 'components/common/withIsOffline';
import { useDialog } from 'components/core/Dialog/common/DialogContext';
import { useOrganizations } from 'components/dataProviders/withOrganizations';
import { ButtonTextIcon } from 'components/general';
import { MemoForbiddenTooltip } from 'components/general/ForbiddenTooltip/ForbiddenTooltip';
import { isClientOrSuperAdmin } from 'shared/domain/role/isClientOrSuperAdmin';
import { startUserDelete } from 'shared/domain/user/startUserRemove';
import {
  Permission,
  noPermission,
  noPermissionInOffline,
} from 'helpers/permission';
import {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { projectDataSelector } from 'redux/selectors/project';
import { UserRole } from 'shared/types/userRole';
import { createUniqueId } from 'shared/utils/id/id';

export function DeleteClientAdmin({
  label,
  userId,
  authorRole,
  authorId,
}): ReactElement {
  const intl = useIntl();
  const history = useHistory();
  const createDialog = useDialog();
  const { organizationId, _id: projectId } = useSelector(
    projectDataSelector
  );
  const { organizationsMap } = useOrganizations();
  const curentOrganization = organizationsMap.get(organizationId);

  const isOffline = useIsOffline();

  const abortSignalRef = useRef<AbortController>(new AbortController());

  useEffect(() => {
    abortSignalRef.current.abort();
    abortSignalRef.current = new AbortController();

    return (): void => {
      abortSignalRef.current.abort();
    };
  }, []);

  const deletePermission = useMemo((): Permission => {
    if (authorId === userId) {
      return noPermission('tooltip_action_forbidden');
    }
    if (!isClientOrSuperAdmin(authorRole)) {
      return noPermission('tooltip_action_forbidden');
    }
    if (isOffline) {
      return noPermissionInOffline();
    }
    return {
      permission: true,
    };
  }, [authorRole, isOffline, authorId, userId]);

  const handleRemoveUserFromProject = useCallback(() => {
    // TODO: find out what's wrong
    const descriptionText: any = intl.formatMessage(
      {
        id: 'remove_client_admin_from_projects_dialog_body',
      },
      {
        user: <strong style={{ color: 'crimson' }}>{label}</strong>,
        clientName: curentOrganization?.name || '',
      }
    );

    const title = intl.formatMessage(
      {
        id: 'remove_client_admin_from_projects_dialog_title',
      },
      {
        clientName: curentOrganization?.name || '',
      }
    );

    createDialog({
      title: title,
      description: <span>{descriptionText}</span>,
      submitLabel: intl.formatMessage({ id: 'general_ok' }),
    })
      .then(() => {
        startUserDelete(
          {
            _id: userId,
            role: UserRole.organizationAdmin,
            organizationId,
            projectId,
          },
          createUniqueId()
        );
        history.push('/user');
      })
      .catch(() => {});
  }, [
    intl,
    label,
    curentOrganization?.name,
    createDialog,
    userId,
    organizationId,
    projectId,
    history,
  ]);

  return (
    <MemoForbiddenTooltip
      visibleWhen={!deletePermission.permission}
      reason={deletePermission.reason}
      inlined
    >
      <ButtonTextIcon
        disabled={!deletePermission.permission}
        startIcon={Block}
        onClick={handleRemoveUserFromProject}
      >
        {intl.formatMessage(
          {
            id: 'remove_client_admin_from_projects',
          },
          {
            clientName: curentOrganization?.name || '',
          }
        )}
      </ButtonTextIcon>
    </MemoForbiddenTooltip>
  );
}
