import {
  CompanyWithFinances,
  FinancialData,
} from 'shared/domain/company/types/financialData';
import { CompanyModel } from 'shared/domain/company/types/model';
import { CompanyOnView } from 'shared/domain/company/types/view';
import { Finances } from 'shared/types/analytics';
import { LabelledEntity } from 'shared/types/commonView';
import { CompanyCreateModel, CompanyEditModel } from '../types/model';

function lookupLabelForId(
  searchedId: string,
  labelledEntities: LabelledEntity[]
): string {
  return (
    labelledEntities.find((site) => site._id === searchedId)?.label ?? ''
  );
}

export function populateData(
  companies: CompanyModel[],
  sites: LabelledEntity[],
  finances: Finances | undefined,
  loading: boolean,
  recievedAt: number
): CompanyWithFinances[] {
  return companies.map((company) => ({
    ...company,
    // @ts-ignore unable to type it
    ...populateFinances(company._id, finances, loading),
    customId: createCustomId(company._id, recievedAt),
    sites: company.sites
      .map((siteId) => lookupLabelForId(siteId, sites))
      .filter((x) => x),
  }));
}

function populateFinances(
  companyId: string,
  finances: Finances | undefined,
  loading: boolean
): FinancialData {
  if (loading || !finances) {
    return {
      financialData: undefined,
    };
  }
  return {
    financialData: {
      expectedFine: finances.expectedFines[companyId],
      imposedFine: finances.imposedFines[companyId],
      estimatedCost: finances.estimatedCosts[companyId],
      finalCost: finances.finalCosts[companyId],
    },
  };
}

function createCustomId(companyId: string, recievedAt: number): string {
  return `${companyId}_${recievedAt}`;
}

export function companyOnViewToCompanyCreateModel(
  fields: CompanyOnView,
  projectId: string
): CompanyCreateModel {
  return {
    shortLabel: fields.shortLabel,
    longLabel: fields.longLabel,
    deleted: fields.deleted,
    createdAt: fields.createdAt.toISO(),
    modifiedAt: fields.modifiedAt.toISO(),
    projectInvolvementType: fields.projectInvolvementType._id,
    sites: fields.sites.map((site) => site._id),
    taxNumber: fields.taxNumber,
    projectId,
  };
}

function textOnViewToTextOnModel(
  value: string | undefined | null
): string | undefined {
  if (value === null) {
    return '';
  }
  return value;
}

export function companyOnViewToCompanyEditModel(
  fields: CompanyOnView,
  projectId
): CompanyEditModel {
  return {
    shortLabel: fields.shortLabel,
    longLabel: fields.longLabel,
    _id: fields._id,
    // contracts: fields.contracts.map(contract => contract._id),
    projectInvolvementType: fields.projectInvolvementType._id,
    sites: fields.sites.map((site) => site._id),
    taxNumber: textOnViewToTextOnModel(fields.taxNumber),
    projectId,
  };
}
