import { SnackbarOrigin } from '@mui/material';
import { DateTime } from 'luxon';
import { IssueModel } from 'shared/domain/issue/issueModel';
import { PersonalData } from 'shared/domain/user/types/entity';
import { UserInDto } from 'shared/domain/user/types/inDto';
import { SortingOrder } from 'shared/filter/sort/types';
import {
  HashMap,
  Identificable,
  LabelledEntity,
} from 'shared/types/commonView';
import { ProcessType } from 'shared/types/form';
import { UserWithAuthAndPermissions } from 'shared/types/userRole';
import { AppLocales } from '../../intl/IntlProviderWrapper';
import { DocumentTypes } from '../../shared/types/document';
import { StageInternalNames } from '../../shared/types/stages';
import { UploadStatus } from '../../shared/types/uploadStatus';

type ErrorType = {
  message: string;
};

export type ToasterMessageProp = {
  id: string;
  content?: string;
  messageParams?: HashMap<string | number | null | boolean>;
};

type UIToasterType = {
  toasterVisible?: boolean;
  toasterMessage?: ToasterMessageProp;
  toasterType?: number;
  toasterPosition?: SnackbarOrigin;
  hideDelay?: number;
};

export type DateType = string | null;
export enum DateTypeIndicator {
  FROM = 'from',
  TO = 'to',
}

export enum IssueStateType {
  ACTIVE = 'active',
  DELETED = 'deleted',
}

export type ColumnWidths = HashMap<number | undefined>;

type IssueViewSubType = {
  viewType: number;
  tableHeaders: string[];
  tableSorting?: { headerName: string; sorting: SortingOrder };
  columnWidths?: ColumnWidths;
};

export type UiReducerType = {
  sidebarOpen: boolean;
  isFormEditing: HashMap<boolean | undefined>;
  editingEventId: string;
  currentLocale: AppLocales;
  searchPhrase: string;
  toaster: UIToasterType;
  issueView: IssueViewSubType;
  mismatchedVersion?: boolean;
};

type ProjectSync = HashMap<string>;

export type IssueProp = {
  list: Issue[];
  error: ErrorType | null;
  loading: boolean;
  syncData: ProjectSync;
  activeCount: number;
  deletedCount: number;
  hasMore: boolean;
  fetchedDeleted: boolean;
  fetchedActive: boolean;
};

export type IconsProp = {
  loading: boolean;
  error: ErrorType | null;
  icons: {
    document: {
      form: string;
      files: { blank: string; [key: string]: string };
    };
    process: {
      ncr: string;
    };
  } | null;
};

export type ProjectInfo = Identificable & {
  organizationId: string;
  code: string;
  name: string;
  processes: ProcessType[];
  error: ErrorType | null;
  userHasAccess: boolean;
  timezone: string;
  budget: number;
  currency: string;
  projectStartDate: DateTime | null;
  projectEndDate: DateTime | null;
  localeCode: AppLocales;
  pdfFooter: AssetDto | undefined; //TODO https://hustro.atlassian.net/browse/PT-3825
  pdfHeader: AssetDto | undefined; //TODO https://hustro.atlassian.net/browse/PT-3825
  pdfContactFooter: AssetDto | undefined; //TODO https://hustro.atlassian.net/browse/PT-3825
};

export type StoreState = {
  ui: UiReducerType;
  user: UserWithAuthAndPermissions;
  issue: IssueProp;
  projectData: ProjectInfo;
  icons: IconsProp;
  users: UsersProp;
};

export type UsersProp = {
  loading: boolean;
  error: ErrorType | null;
  data: HashMap<UserInDto>;
};
// TODO: PT-3769 remove
export type IssueDocument = Identificable & {
  title: string;
  type: string;
  data: FileInfo;
  description?: string | null;
  deleted: boolean;
  modifiedAt: string;
};

export type FileInfo = {
  name: string;
  type: string;
  documentType: DocumentTypes;
  key: string;
  extension: string;
  url: string;
  bucketName: string;
  uploadStatus: UploadStatus;
  size?: number;
  isDrawn: boolean;
  drawingSrc?: string;
  drawingSignedRequest?: string;
};

export type DocumentDescription = HashMap<string>;

interface AssetDto {
  _id: string;
  extension: string;
}

export type FlatIssue = IssueModel['primaryData'] &
  IssueModel['extendedData'] & {
    stage: StageInternalNames;
    process: LabelledEntity;
    inspection?: string;
    protocolItem?: string;
  };

export type Issue = IssueModel;

export type UserUpdateDto = { personalData: PersonalData[] };
export type UserPersonalDataUpdateDto = { label?: string; phone?: string };
export type SuperadminUserUpdateDto = { superadminLabel: string };

export type IssuesInfo = {
  issues: Issue[];
  syncedAt: number;
  totalCount: number;
  hasMore: boolean;
  syncKey: string;
};
