import { userWithPersonalDataToOptionalLabelled } from 'shared/domain/user/mapping/toView';
import { stringToViewDate } from 'shared/utils/date/stringToViewDate';
import { LevelModel } from '../types/model';
import { LevelOnView } from '../types/view';
import { modelToViewBase } from 'shared/domain/toBaseView';

export function levelModelToLevelOnView(
  model: LevelModel,
  organizationId: string
): LevelOnView {
  const onViewBase = modelToViewBase(model, organizationId);

  return {
    ...onViewBase,
    label: model.label,
    site: model.site,
  };
}
