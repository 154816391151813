import { PropsWithChildren, ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { ProductFruits } from 'react-product-fruits';
import { useSelector } from 'react-redux';
import { currentLabelledUserSelector } from 'redux/selectors/users';
import { ENVIRONMENTS } from 'shared/enums';
import { getEnvironment } from 'shared/utils/environment';

export function WithProductFruits({
  children,
}: PropsWithChildren<{}>): ReactElement {
  const intl = useIntl();
  const user = useSelector(currentLabelledUserSelector);
  const userInfo = {
    username: user.label || 'unknown user',
    email: user.email,
    role: user.role || 'unknown',
  };
  const workspaceCode = getWorkspaceCodeByEnvironment(getEnvironment());

  if (!workspaceCode || process.env.REACT_APP_DISABLE_PRODUCTFRUITS) {
    return <>{children}</>;
  }

  return (
    <>
      <ProductFruits
        workspaceCode={workspaceCode}
        language={intl.locale.slice(0, 2)}
        user={userInfo}
        lifeCycle='unmount'
      />
      {children}
    </>
  );
}

function getWorkspaceCodeByEnvironment(environment: ENVIRONMENTS): string {
  switch (environment) {
    case ENVIRONMENTS.INTEGRATION_DEV:
    case ENVIRONMENTS.INTEGRATION:
      return 'cmyE7VLgNf1jfEix';
    case ENVIRONMENTS.STAGING_DEV:
    case ENVIRONMENTS.STAGING:
      return '75YhlIGxdBRuyO9w';
    case ENVIRONMENTS.PRODUCTION:
      return 'fKIRkkDT9zMXHLn6';
    case ENVIRONMENTS.DEVELOPMENT:
      return '';
  }
}
