import { BroadcastChannel } from 'broadcast-channel';
import { useUserChannelListener } from 'components/broadcastChannelListeners/withUserChannelListener';
import { EmptyListReason } from 'components/common/noTableContentInfo/NoTableContentInfo';
import { useEntityDataSubscription } from 'components/common/useEntityDataSubscription';
import {
  Message,
  DomainMessagesTypes,
} from 'shared/domain/messages/message';
import { filterOutSuperAdmin } from 'shared/domain/user/filterSuperAdmin';
import { UserModel } from 'shared/domain/user/types/model';
import { useGetAllUsers } from 'hooks/useGetAllUsers';
import React, {
  PropsWithChildren,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ChannelNames } from 'shared/domain/channelNames';
import { useEmptyListReason } from '../../../hooks/table/useEmptyListReason';
import { filterBySearchPhrase } from '../../common/SearchInput/helpers';
import { UsersContextType, UsersResponse } from './types';

const initialUsers = { items: [], total: 0 };
const userSearchableFields = ['label', 'email'];

export const UsersContext = React.createContext<
  UsersContextType | undefined
>(undefined);

const WithUsers: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [users, setUsers] = useState<UsersResponse>(initialUsers);
  const [loading, setLoading] = useState<boolean>(true);

  const [searchPhrase, setSearchPhrase] = useState<string>('');

  const filtered: UserModel[] = useMemo(() => {
    return filterBySearchPhrase(
      users.items,
      searchPhrase,
      userSearchableFields
    );
  }, [searchPhrase, users]);

  const [emptyListReason, setEmptyListReason] = useState<
    EmptyListReason | undefined
  >(useEmptyListReason(searchPhrase, filtered.length, 0));

  const { subscribe } = useUserChannelListener();
  const { getAll: getAllUsers } = useGetAllUsers();

  useEntityDataSubscription({
    subscribe,
    getAll: getAllUsers,
    setEntity: setUsers,
    setLoading,
    entityName: 'users',
  });

  useEffect(() => {
    if (users.total === 0) {
      if (searchPhrase.length) {
        return setEmptyListReason(EmptyListReason.SEARCHPHRASE_MISMATCH);
      }
      return setEmptyListReason(EmptyListReason.NOTHING_IN_DB);
    }
    setEmptyListReason(undefined);
  }, [users, searchPhrase]);

  useEffect(() => {
    const broadcast = new BroadcastChannel(ChannelNames.userChannel);
    let mounted = true;

    broadcast.onmessage = (event: Message): void => {
      if (!mounted) {
        return;
      }

      if (event.data && event.type === DomainMessagesTypes.allUsers) {
        const hasData = event.data.hasAll;
        if (hasData) {
          // const users: UserModel[] = event.data.items.filter(
          //   filterOutSuperAdmin
          // );
          setUsers({
            items: event.data.items,
            total: event.data.total,
          });
          setLoading(false);
        }
      }
    };

    broadcast.postMessage({
      type: DomainMessagesTypes.getState,
    });

    return (): void => {
      broadcast.close();
      mounted = false;
    };
  }, []);

  const ctx = {
    filtered: { items: filtered, total: filtered.length },
    all: { items: users.items, total: users.items.length },
    loading,
    emptyListReason,
    setSearchPhrase,
  };

  return (
    <UsersContext.Provider value={ctx}>{children}</UsersContext.Provider>
  );
};

const withUsers =
  (Component: React.ComponentType<any>) =>
  ({ ...props }): ReactElement => (
    <WithUsers>
      <Component {...props} />
    </WithUsers>
  );

function useUsers(): UsersContextType {
  const context = React.useContext(UsersContext);
  if (context === undefined) {
    throw new Error('useUsers must be used within a UsersContextProvider');
  }
  return context;
}

export { WithUsers, useUsers, withUsers };
