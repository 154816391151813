import { EntityLabel } from './types';
import { isPhraseFound } from 'helpers/filtering';
import { deepFind } from 'shared/utils/object';
import { UserModel } from 'shared/domain/user/types/model';
import { CompanyModel } from 'shared/domain/company/types/model';
import { ContractModel } from 'shared/domain/contract/types/model';

export function searchPhraseValidation(searchPhrase: string): boolean {
  return searchPhrase.length >= 1;
}

export type SearchPhraseChangeEventDetail = {
  searchPhrase: string;
};

export const getSearchPhraseChangeEventName = (
  entityLabel: EntityLabel
): string => `${entityLabel}_SEARCH_PHRASE_CHANGE`;

export function releaseSearchPhraseChangeEvent(
  searchPhrase: string,
  entityLabel: EntityLabel
): void {
  window.dispatchEvent(
    new CustomEvent<SearchPhraseChangeEventDetail>(
      getSearchPhraseChangeEventName(entityLabel),
      { detail: { searchPhrase } }
    )
  );
}

const getItemKey = (projectId: string, entityLabel: EntityLabel): string =>
  `${projectId}_${entityLabel}_search_phrase`;

export function saveSearchPhraseInStorage(
  projectId: string,
  entityLabel: EntityLabel,
  searchPhrase: string
): void {
  localStorage.setItem(getItemKey(projectId, entityLabel), searchPhrase);
}

export function getSearchPhraseFromStorage(
  projectId: string,
  entityLabel: EntityLabel
): string {
  return localStorage.getItem(getItemKey(projectId, entityLabel)) ?? '';
}

function matchSearchPhrase(
  item: CompanyModel | UserModel | ContractModel,
  searchPhrase: string,
  searchableFields: string[]
): boolean {
  return searchableFields.some((field) => {
    const fieldValues = deepFind(item, field);
    return isPhraseFound(fieldValues, searchPhrase);
  });
}

export function filterBySearchPhrase<
  T extends UserModel | CompanyModel | ContractModel,
>(items: T[], searchPhrase: string, searchableFields: string[]): T[] {
  if (!searchPhrase) {
    return items;
  }

  return items.filter((item) =>
    matchSearchPhrase(item, searchPhrase, searchableFields)
  );
}
