import React from 'react';
import { useSelector } from 'react-redux';
import { SuperadminUser } from './superadminUser';
import { CreatableUser } from './creatableUser';
import { toUserRole } from 'redux/selectors/role';

export const CurrentUser = (): React.ReactElement => {
  const userRole = useSelector(toUserRole);
  const isSuperAdmin = userRole === 'superadmin';

  if (isSuperAdmin) {
    return <SuperadminUser />;
  }

  return <CreatableUser />;
};
