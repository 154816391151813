import {
  HttpRequestModelType,
  HttpRequestStatus,
} from 'shared/domain/httpRequest/httpRequestModel';
import { isAdmin } from 'shared/domain/role/isAdmin';
import { isClientAdmin } from 'shared/domain/role/isClientAdmin';
import { UserEditUseCase } from 'shared/domain/user/editUser';
import { UserEditModel } from 'shared/domain/user/types/model';
import { add as addRequest } from 'serviceWorker/db/httpRequests';
import { getOne as getUser } from 'serviceWorker/db/users';
import { UserRole } from 'shared/types/userRole';
import { debugLog } from 'shared/logger/debugLog';
import { userDeleter } from './deleteUser';
import {
  UserHttpCreateRequestModel,
  UserHttpDeleteRequestModel,
  UserHttpEditPermissionsRequestModel,
  UserHttpEditLabelAndPhoneRequestModel,
} from '../httpQueue/user/types';
import { createUniqueId } from 'shared/utils/id/id';

export class UserEditor implements UserEditUseCase {
  constructor(
    private addRequest: (
      request:
        | UserHttpEditLabelAndPhoneRequestModel
        | UserHttpCreateRequestModel
        | UserHttpDeleteRequestModel
        | UserHttpEditPermissionsRequestModel
    ) => Promise<any>
  ) {}

  private async recreatePermissions({
    userId,
    userRole,
    projectId,
    organizationId,
    userPermissionEditRequest,
  }: {
    userRole: UserRole.projectAdmin | UserRole.organizationAdmin;
    organizationId: string;
    userPermissionEditRequest: UserHttpEditPermissionsRequestModel;
    projectId: string;
    userId: string;
  }): Promise<void> {
    await userDeleter.execute(
      { _id: userId, role: userRole, projectId, organizationId },
      createUniqueId()
    );
    await this.addRequest(userPermissionEditRequest);
  }

  async execute(
    userEditModel: UserEditModel,
    uniqueId: string
  ): Promise<void> {
    debugLog('UserEditUseCase', userEditModel);
    const userId = await getUser(userEditModel._id);
    if (!userId) {
      throw new Error('Cannot find user.');
    }
    if (!userEditModel.organizationId) {
      throw new Error('Cannot find client.');
    }
    const userPersonalDataUrl = `/user/${userId._id}/personalData/client/${userEditModel.organizationId}`;
    const userPermissionUrl = `/user/${userId._id}/permission`;
    const { label, phone, permissions } = userEditModel;
    // uniqueId cannot be used twice
    let usedUniqueId = false;
    if (permissions) {
      // it is not allowed to simply downgrade admin permissions.
      // you have to use DELETE first and POST user after it
      if (isAdmin(userId.role) || isClientAdmin(userId.role)) {
        const userPermissionEditRequest: UserHttpEditPermissionsRequestModel =
          {
            createdAt: Date.now(),
            method: 'PUT',
            data: {
              userEditPermissionsOutDto: { permissions },
              uniqueId,
            },
            url: userPermissionUrl,
            entityType: HttpRequestModelType.user,
            status: HttpRequestStatus.NEW,
          };

        this.recreatePermissions({
          userId: userEditModel._id,
          projectId: userEditModel.projectId,
          organizationId: userEditModel.organizationId,
          userPermissionEditRequest,
          userRole: UserRole.projectAdmin,
        });
        usedUniqueId = true;
      } else {
        const permissionChangeRequest: UserHttpEditPermissionsRequestModel =
          {
            createdAt: Date.now(),
            method: 'PUT',
            data: {
              userEditPermissionsOutDto: { permissions },
              uniqueId,
            },
            url: userPermissionUrl,
            entityType: HttpRequestModelType.user,
            status: HttpRequestStatus.NEW,
          };
        await this.addRequest(permissionChangeRequest);
        usedUniqueId = true;
      }
    }

    if (label || phone) {
      debugLog('labelAndPhoneChangeRequest', label, phone);

      const labelAndPhoneChangeRequest: UserHttpEditLabelAndPhoneRequestModel =
        {
          createdAt: Date.now(),
          method: 'PUT',
          data: {
            userLabelAndPhoneEditOutDto: { label, phone },
            uniqueId: usedUniqueId ? createUniqueId() : uniqueId,
          },
          url: userPersonalDataUrl,
          entityType: HttpRequestModelType.user,
          status: HttpRequestStatus.NEW,
        };
      await this.addRequest(labelAndPhoneChangeRequest);
    }
  }
}

export const userEditor = new UserEditor(addRequest);
