import InsufficientPermissionsPage from 'components/core/InsufficientPermissionsPage/InsufficientPermissionsPage';
import { isDefined } from 'shared/domain/role/isDefined';
import { isManagerOrAdmin } from 'shared/domain/role/isManagerOrAdmin';
import { isStandard } from 'shared/domain/role/isStandard';
import { isViewer } from 'shared/domain/role/isViewer';
import { UserInDto } from 'shared/domain/user/types/inDto';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { currentProjectUserSelector } from 'redux/selectors/users';
import { MemoTemplateList } from 'views/template/list';
import { MemoTemplateWizard } from 'views/template/wizard';
import { MemoGenericRoleAuthorizer } from '../GenericRoleAuthorizer';
export const AuthorizableTemplate = React.memo((): React.ReactElement => {
  const currentUser: UserInDto = useSelector(currentProjectUserSelector);

  const isCurrentUserAuthorized = useMemo(() => {
    return isDefined(currentUser.role) && !isStandard(currentUser.role);
  }, [currentUser]);
  return (
    <MemoGenericRoleAuthorizer
      authorized={isCurrentUserAuthorized}
      unauthorized={isStandard(currentUser.role)}
      authorizedComponent={MemoTemplateList}
      unauthorizedComponent={InsufficientPermissionsPage}
    />
  );
});

export const AuthorizableTemplateWizard = React.memo(
  (): React.ReactElement => {
    const currentUser: UserInDto = useSelector(currentProjectUserSelector);
    const isCurrentUserStandardOrViewer = useMemo(() => {
      return isStandard(currentUser.role) || isViewer(currentUser.role);
    }, [currentUser]);
    return (
      <MemoGenericRoleAuthorizer
        authorized={isManagerOrAdmin(currentUser.role)}
        unauthorized={isCurrentUserStandardOrViewer}
        authorizedComponent={MemoTemplateWizard}
        unauthorizedComponent={InsufficientPermissionsPage}
      />
    );
  }
);
