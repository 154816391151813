import { withIssueChannelListener } from 'components/broadcastChannelListeners/withIssueChannelListener';
import { useBacktrack } from 'components/common/withBacktrack';
import {
  useIssueForm,
  withIssueForm,
} from 'components/common/withIssueForm';
import { withProjectChangeDialog } from 'components/common/withProjectChangeDialog';
import { HeaderContext } from 'components/core/Layout/HeaderContext';
import { withCreateSingleIssueReport } from 'components/dataCreationForms/withCreateSingleIssueReport';
import {
  useIssueEditMessage,
  withIssueEditMessage,
} from 'components/dataCreationForms/withIssueEditMessage';
import { withCompanies } from 'components/dataProviders/withCompanies';
import { withContracts } from 'components/dataProviders/withContracts';
import {
  useCorrectiveActionTypes,
  withCorrectiveActionTypes,
} from 'components/dataProviders/withCorrectiveActionTypes';
import {
  useEnvironmentalAspects,
  withEnvironmentalAspects,
} from 'components/dataProviders/withEnvironmentalAspects';
import {
  useHazardCategories,
  withHazardCategories,
} from 'components/dataProviders/withHazardCategories';
import { useIssue, withIssue } from 'components/dataProviders/withIssue';
import { IssueOnSingleView } from 'components/dataProviders/withIssue/IssueOnSingleView';
import { withIssueFilters } from 'components/dataProviders/withIssueFilters';
import { WithIssueIdList } from 'components/dataProviders/withIssueIdList';
import { withLevels } from 'components/dataProviders/withLevels';
import {
  useLevelsToSelect,
  withLevelsToSelect,
} from 'components/dataProviders/withLevelsToSelect';
import { useSites, withSites } from 'components/dataProviders/withSites';
import { useUsers, withUsers } from 'components/dataProviders/withUsers';
import { usersWithAccessSelector } from 'components/dataProviders/withUsers/model';
import { withFieldVisibility } from 'components/dataProviders/withVisibleFields';
import {
  useWorktypes,
  withWorktypes,
} from 'components/dataProviders/withWorktypes';
import { keepUndeleted } from 'shared/domain/deletable/filters';
import { useDeepCompareEffectNoCheck } from 'helpers/validators';
import { useSyncIssues } from 'hooks/useSyncIssues';
import { DocumentOnView } from 'presentation/document/documentOnView';
import { filterFieldValuesByProcess } from 'presentation/fieldValue/filterFieldValues';
import React, {
  memo,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { displayGenericErrorToaster } from 'redux/actions/toasterActions';
import { projectDataSelector } from 'redux/selectors/project';
import { currentProjectUserSelector } from 'redux/selectors/users';
import { ProjectInfo } from 'setup/types/core';
import { UserRole } from 'shared/types/userRole';
import ViewWrapper from 'components/core/Layout/ViewWrapper';
import { HashMap } from 'shared/types/commonView';
import { ProcessType } from 'shared/types/form';
import { IssueContext } from '../../issueContext/issueContext';
import { ISSUE_REQUEST_STATUS } from '../helpers';
import { issueStateController } from '../IssueStateController';
import { generateForm } from './generateForm';
import {
  canEdit,
  getIssueProcess,
  processes,
  setIssueStatus,
} from './helpers';

enum EntityStatuses {
  NOT_FOUND = 'NOT_FOUND',
  DELETED = 'DELETED',
}

type EntityStatusType = {
  entity: ENTITY;
  status: EntityStatuses;
};

enum ENTITY {
  PROJECT = 'project',
  EVENT = 'event',
  DOCUMENT = 'document',
  ISSUE = 'issue',
}

const IssueView = React.lazy(
  () => import('components/issue/UnifiedIssue')
);
const IssueSkeleton = React.lazy(() => import('../IssueSkeleton'));
const EntityRemovedView = React.lazy(() => import('../EntityRemovedView'));
const EntityNotFoundView = React.lazy(
  () => import('../EntityNotFoundView')
);

type IssueViewContainerProps = {
  projectData: ProjectInfo;
  processesList: ProcessType[];
};

const IssueViewContainer: React.FC<IssueViewContainerProps> = ({
  projectData,
  processesList,
}) => {
  const { setRouteFor } = useContext(HeaderContext);
  const { projectId, issueId, eventId, documentId } =
    useParams<HashMap<string>>();
  const { back } = useBacktrack();
  const history = useHistory<any>();
  // ready to take issue from state when _id not defined
  const stateIssue = history.location.state?.issue;
  const user = useSelector(currentProjectUserSelector);

  const {
    sites: { items: sites },
  } = useSites();
  const { levels } = useLevelsToSelect();
  const { items: workTypes } = useWorktypes();
  const { items: hazardCategories } = useHazardCategories();
  const { items: environmentalAspects } = useEnvironmentalAspects();
  const { items: correctiveActionTypes } = useCorrectiveActionTypes();
  const {
    all: { items: users },
  } = useUsers();
  const { issueForm } = useIssueForm();

  const [process, setProcess] = useState<ProcessType | undefined>();

  const { subscribe } = useIssueEditMessage();
  const {
    issueStore,
    documentsStore,
    loadingStore,
    setIssueId,
    resync,
    errorStore,
  } = useIssue();

  const [issueDocuments, setIssueDocuments] = useState(
    documentsStore.get().issueDocuments
  );

  const [eventDocuments, setEventDocuments] = useState(
    documentsStore.get().eventDocuments
  );

  useEffect(() => {
    const action = (): void => {
      const { issueDocuments, eventDocuments } = documentsStore.get();
      setIssueDocuments(issueDocuments);
      setEventDocuments(eventDocuments);
    };
    action();
    return documentsStore.subscribe(action);
  }, [documentsStore]);

  const [entityStatus, setEntityStatus] = useState<
    EntityStatusType | undefined
  >(
    errorStore.get()
      ? {
          entity: ENTITY.ISSUE,
          status: EntityStatuses.NOT_FOUND,
        }
      : undefined
  );

  useEffect(() => {
    return errorStore.subscribe(() => {
      setEntityStatus({
        entity: ENTITY.ISSUE,
        status: EntityStatuses.NOT_FOUND,
      });
    });
  }, [errorStore]);

  const [issue, setIssue] = useState<IssueOnSingleView | undefined>(
    issueStore.get()
  );
  const [loading, setLoading] = useState<boolean>(loadingStore.get());

  useEffect(() => {
    return issueStore.subscribe(() => {
      setIssue(issueStore.get());
    });
  }, [issueStore]);

  useEffect(() => {
    return loadingStore.subscribe(() => {
      setLoading(loadingStore.get());
    });
  }, [loadingStore]);

  const issueProcessId = issue?.process._id;
  const workTypesOnCurrentProcess = useMemo(
    () => filterFieldValuesByProcess(workTypes, issueProcessId),
    [workTypes, issueProcessId]
  );
  const hazardCategoriesOnCurrentProcess = useMemo(
    () => filterFieldValuesByProcess(hazardCategories, issueProcessId),
    [hazardCategories, issueProcessId]
  );
  const correctiveActionTypesOnCurrentProcess = useMemo(
    () =>
      filterFieldValuesByProcess(correctiveActionTypes, issueProcessId),
    [correctiveActionTypes, issueProcessId]
  );
  const environmentalAspectsOnCurrentProcess = useMemo(
    () => filterFieldValuesByProcess(environmentalAspects, issueProcessId),
    [environmentalAspects, issueProcessId]
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const id = issueId || stateIssue?._id || stateIssue?.localId;
    if (!id) {
      // TODO: show error?
      back();
    } else {
      setIssueId(id);
    }
  }, [issueId, stateIssue, setIssueId, back]);

  useEffect(() => {
    const next = getIssueProcess(processesList, issue);
    if (issue && next) {
      setProcess((prev) => {
        if (prev?._id !== next._id) {
          return next;
        }
        return prev;
      });
    }
  }, [issue, processesList]);

  useSyncIssues(true);

  useEffect(() => {
    const unsubscribe = subscribe(
      (issue) => {
        if (issueId && issue._id === issueId) {
          resync();
        }
      },
      (error) => {
        displayGenericErrorToaster(dispatch);
      }
    );
    return (): void => {
      unsubscribe();
    };
  }, [issueId, subscribe, dispatch, resync]);

  const siteId = issue?.primaryData.site._id || '';

  const usersWithPossibleAccess = useMemo(
    () =>
      usersWithAccessSelector(
        [
          UserRole.organizationAdmin,
          UserRole.projectAdmin,
          UserRole.manager,
          UserRole.viewer,
          UserRole.standard,
        ],
        siteId,
        process?._id || '',
        users,
        projectData.organizationId
      ),
    [siteId, process, users]
  );

  useEffect(() => {
    setRouteFor('issueView');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const issueStatus = setIssueStatus({
    isLoading: loading,
    data: issue,
    error: null,
  });

  const { primaryFields, extendedFields } = useMemo(() => {
    if (!process) {
      return { primaryFields: [], extendedFields: [] };
    }

    return generateForm(
      issueForm,
      workTypesOnCurrentProcess,
      hazardCategoriesOnCurrentProcess,
      environmentalAspectsOnCurrentProcess,
      correctiveActionTypesOnCurrentProcess,
      levels.filter(keepUndeleted),
      sites.filter(keepUndeleted),
      usersWithPossibleAccess
    );
  }, [
    process,
    issueForm,
    workTypesOnCurrentProcess,
    hazardCategoriesOnCurrentProcess,
    environmentalAspectsOnCurrentProcess,
    correctiveActionTypesOnCurrentProcess,
    levels,
    sites,
    usersWithPossibleAccess,
  ]);

  const issueLoading = loading || primaryFields.length === 0;
  const issueAccess = canEdit(user, issue?.userAccesses);

  const handleEntityStatus = (
    entity: ENTITY,
    status: EntityStatuses
  ): void => {
    setEntityStatus({ entity, status });
  };

  useEffect(() => {
    if (projectId !== projectData._id) {
      handleEntityStatus(ENTITY.PROJECT, EntityStatuses.NOT_FOUND);
    }
  }, [projectId, projectData._id]);

  const dispatchDocumentPreview = useCallback(
    (
      issue: Pick<IssueOnSingleView, 'events'>
    ): React.ReactElement | void => {
      const event = issue.events.find((e) => e._id === eventId);

      if (eventId) {
        if (!event) {
          handleEntityStatus(ENTITY.EVENT, EntityStatuses.NOT_FOUND);
          return;
        } else if (event?.deleted) {
          handleEntityStatus(ENTITY.EVENT, EntityStatuses.DELETED);
          return;
        } else {
          setEntityStatus(undefined);
        }
      }

      if (documentId) {
        // PT-4274 bugfix 'docs' can be undefined
        const docs: DocumentOnView[] | undefined = eventId
          ? eventDocuments[eventId]
          : documentsStore.get().issueDocuments;

        const currentDocument = docs?.find(
          (dcmnt) => dcmnt._id === documentId
        );

        if (!currentDocument) {
          handleEntityStatus(ENTITY.DOCUMENT, EntityStatuses.NOT_FOUND);
          return;
        } else if (currentDocument?.deleted) {
          handleEntityStatus(ENTITY.DOCUMENT, EntityStatuses.DELETED);
          return;
        } else {
          setEntityStatus(undefined);
        }
      }
    },
    [eventId, documentId, documentsStore, eventDocuments]
  );

  useDeepCompareEffectNoCheck(() => {
    // show document preview when entityStatus havent been set
    // and issue is loaded
    if (!entityStatus?.entity && loading === false && issue) {
      if (eventId || documentId) {
        dispatchDocumentPreview(issue);
      }
    }
    // eslint-disable-next-line
  }, [loading, entityStatus, issue]);

  const setIssueInStore = useCallback(
    (data: Partial<IssueOnSingleView>): void => {
      setIssue({
        ...(issueStore.get() || {}),
        ...data,
      } as IssueOnSingleView);
    },
    [issueStore]
  );
  const addToIssue = useCallback(
    (callback: SetStateAction<IssueOnSingleView | undefined>): void => {
      setIssue(callback);
    },
    []
  );

  if (entityStatus) {
    const { entity, status } = entityStatus;
    const { NOT_FOUND, DELETED } = EntityStatuses;

    if (status === NOT_FOUND) {
      return <EntityNotFoundView entity={entity} />;
    }
    if (status === DELETED) {
      return <EntityRemovedView entity={entity} />;
    }
  }

  if (issueStatus.status !== ISSUE_REQUEST_STATUS.LOADED) {
    return issueStateController(issueStatus);
  }

  if (issueLoading) {
    return <IssueSkeleton />;
  }

  if (!issue) {
    return null;
  }

  const contextValue = {
    issue,
    issueStatus,
    issueAccess,
    issueManagers: usersWithPossibleAccess,
    issueDocuments,
    eventDocuments,
    primaryFields,
    extendedFields,
    setIssue: setIssueInStore,
    addToIssue: addToIssue,
  };

  //TODO https://hustro.atlassian.net/browse/PT-2401
  return (
    <IssueContext.Provider value={contextValue}>
      <ViewWrapper>
        <WithIssueIdList>
          <IssueView />
        </WithIssueIdList>
      </ViewWrapper>
    </IssueContext.Provider>
  );
};

/**
 * It prevents rendering Container if project/process data is not available yet (after login in particular)
 * @constructor
 */
const ContainerDataProvider = (): React.ReactElement => {
  const projectData = useSelector(projectDataSelector);
  const processesList = useSelector(processes);

  const { issueId } = useParams<{
    issueId: string;
  }>();

  const isDataLoaded =
    Boolean(projectData._id) && processesList.length > 0;

  if (isDataLoaded) {
    return (
      <IssueViewContainer
        key={issueId} //required to remount component when route changes after creating similar issue
        projectData={projectData}
        processesList={processesList}
      />
    );
  }

  return <IssueSkeleton />;
};

export default withIssueForm(
  withIssueEditMessage(
    withSites(
      withLevels(
        withLevelsToSelect(
          withIssueChannelListener(
            withWorktypes(
              withHazardCategories(
                withEnvironmentalAspects(
                  withCorrectiveActionTypes(
                    withUsers(
                      withContracts(
                        withCompanies(
                          withIssue(
                            withFieldVisibility(
                              withCreateSingleIssueReport(
                                withProjectChangeDialog(
                                  withIssueFilters(
                                    memo(ContainerDataProvider)
                                  )
                                )
                              )
                            )
                          )
                        )
                      )
                    )
                  )
                )
              )
            )
          )
        )
      )
    )
  )
);
