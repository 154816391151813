import {
  CreatableEntity,
  ModifiableEntity,
} from 'shared/types/commonEntities';
import {
  Deletable,
  Identificable,
  IndexableDeletable,
} from 'shared/types/commonView';
import {
  CreatableModel,
  ModelBase,
  ModifiableModel,
  NonCreatableModel,
  NonDeletableModel,
} from './commonModel';
import { toOptionalUserWithPersonalData } from './user/mapping/toView';
import { UserEntity } from './user/types/entity';
import { Dto, NonDeletableDto } from 'shared/dtos/in/common';
import { UserModel } from './user/types/model';

export function stringsToCreatableModel(
  createdBy: string | undefined,
  createdAt: string,
  users: UserEntity[] | UserModel[]
): CreatableModel {
  const user = (users as (UserEntity | UserModel)[]).find(
    (user) => user._id === createdBy
  );
  return {
    createdBy: toOptionalUserWithPersonalData(user),
    createdAt,
  };
}
export function stringsToModifiableModel(
  modifiedBy: string | undefined,
  modifiedAt: string,
  users: UserEntity[] | UserModel[]
): ModifiableModel {
  const user = (users as (UserEntity | UserModel)[]).find(
    (user) => user._id === modifiedBy
  );
  return {
    modifiedBy: toOptionalUserWithPersonalData(user),
    modifiedAt,
  };
}

export function entityToModelBase({
  entity,
  users,
}: {
  entity: Identificable &
    CreatableEntity &
    ModifiableEntity &
    (IndexableDeletable | Deletable);
  users: UserEntity[];
}): ModelBase {
  return {
    ...entityToNonDeletableModelBase({ entity, users }),
    deleted: !!entity.deleted,
  };
}

export function dtoToModelBase({
  dto,
  users,
}: {
  dto: Dto;
  users: UserModel[];
}): ModelBase {
  return {
    ...dtoToNonDeletableModelBase({ dto, users }),
    deleted: !!dto.deleted,
  };
}

export function entityToNonDeletableModelBase({
  entity,
  users,
}: {
  entity: Identificable & CreatableEntity & ModifiableEntity;
  users: UserEntity[];
}): NonDeletableModel {
  const { createdBy, createdAt } = stringsToCreatableModel(
    entity.createdBy,
    entity.createdAt,
    users
  );
  const { modifiedBy, modifiedAt } = stringsToModifiableModel(
    entity.modifiedBy,
    entity.modifiedAt,
    users
  );

  return {
    _id: entity._id,
    createdAt,
    createdBy,
    modifiedAt,
    modifiedBy,
  };
}

export function dtoToNonDeletableModelBase({
  dto,
  users,
}: {
  dto: NonDeletableDto;
  users: UserModel[];
}): NonDeletableModel {
  const { createdBy, createdAt } = stringsToCreatableModel(
    dto.createdBy,
    dto.createdAt,
    users
  );
  const { modifiedBy, modifiedAt } = stringsToModifiableModel(
    dto.modifiedBy,
    dto.modifiedAt,
    users
  );

  return {
    _id: dto._id,
    createdAt,
    createdBy,
    modifiedAt,
    modifiedBy,
  };
}

export function entityToNonCreatableModelBase({
  entity,
  users,
}: {
  entity: Identificable & ModifiableEntity;
  users: UserEntity[];
}): NonCreatableModel {
  const { modifiedBy, modifiedAt } = stringsToModifiableModel(
    entity.modifiedBy,
    entity.modifiedAt,
    users
  );

  return {
    _id: entity._id,
    modifiedAt,
    modifiedBy,
  };
}
