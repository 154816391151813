import { fileToCreateDocumentationModel } from 'presentation/documentation/fileToCreateDocumentationModel';
import { Dispatch, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { customMessageErrorToaster } from 'redux/actions/toasterActions';
import { currentUserSelector } from 'redux/selectors/users';
import { saveDocumentationLocally } from 'shared/domain/documentation/saveDocumentation';
import { userWithPermissionsInDtoToUserWithPersonalData } from 'shared/domain/user/mapping/toView';
import { UserWithPersonalData } from 'shared/domain/user/types/model';
import {
  FileReadError,
  FileReadResult,
  FileReadSuccess,
  ResultType,
} from '../../common/graphicUploader/types';
import { useCurrentDirectoryContext } from '../withCurrentDirectory';
import { toFileReadResult } from './model';
import { DocumentationUploader } from './types';
import { projectDataSelector } from 'redux/selectors/project';

function processNewFile(file: File): Promise<FileReadResult> {
  return new Promise((res) => {
    if (!file) {
      const error: FileReadError = {
        resultType: ResultType.error,
        error: 'no_file',
      };
      return res(error);
    }
    toFileReadResult(res, file);
  });
}

function saveLocally(
  file: FileReadSuccess,
  author: UserWithPersonalData,
  currentDirectory: string
): Promise<any> {
  return saveDocumentationLocally(
    fileToCreateDocumentationModel(file, author, currentDirectory)
  );
}

export function useDocumentationSaver(): DocumentationUploader {
  const dispatch = useDispatch();
  const { data: currentUser } = useSelector(currentUserSelector);
  const { _id: projectId } = useSelector(projectDataSelector);
  const { currentDirectoryStore } = useCurrentDirectoryContext();
  const addDocumentationsFromFiles = useCallback(
    async (files: File[]) => {
      const processedFiles = await Promise.all(
        files.map((file) => processNewFile(file))
      );

      const failedFiles: FileReadError[] = [];
      const successfullFiles = processedFiles.filter(
        (readResult): readResult is FileReadSuccess => {
          const success = readResult.resultType === ResultType.success;
          if (!success) {
            failedFiles.push(readResult as FileReadError);
          }
          return success;
        }
      );

      const author = userWithPermissionsInDtoToUserWithPersonalData(
        currentUser,
        projectId
      );
      successfullFiles.forEach((file) =>
        saveLocally(file, author, currentDirectoryStore.get())
      );

      showErrors(dispatch, failedFiles);
    },
    [dispatch, currentUser, currentDirectoryStore]
  );

  return useMemo(() => {
    return {
      addDocumentationsFromFiles: addDocumentationsFromFiles,
    };
  }, [addDocumentationsFromFiles]);
}

function showErrors(
  dispatch: Dispatch<any>,
  failedFiles: FileReadError[]
): AnyAction | void {
  if (failedFiles.length) {
    if (failedFiles.length > 1) {
      return dispatch(
        customMessageErrorToaster('many_files_error', {
          amount: failedFiles.length,
        })
      );
    } else {
      return dispatch(
        customMessageErrorToaster('file_upload_error_file_tooBig')
      );
    }
  }
}
