import { UserCreateOutDto } from 'shared/domain/user/types/outDto';
import { UserInDto, UserWithPermissionsInDto } from '../types/inDto';
import { UserCreateModel } from '../types/model';
import { userWithPermissionsInDtoToModel } from './toModel';

export function userCreateModelToCreateOutDto(
  userCreateModel: UserCreateModel
): UserCreateOutDto {
  return {
    email: userCreateModel.email,
    permissions: userCreateModel.permissions,
  };
}

export function userWithPermissionsInDtoToUserInDto(
  currentProjectId: string,
  userWithPermissionsInDto: UserWithPermissionsInDto
): UserInDto {
  return userWithPermissionsInDtoToModel(
    currentProjectId,
    userWithPermissionsInDto
  ) as UserInDto;
}
