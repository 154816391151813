import { Spinner } from 'components/core';
import { findModelForEditing } from 'components/dataCreationForms/common';
import {
  EditContext,
  OpenEditDialogParams,
} from 'components/dataCreationForms/types';
import { useLevelsToSelect } from 'components/dataProviders/withLevelsToSelect';
import { useSites } from 'components/dataProviders/withSites';
import { getUpdatedMessageListener } from 'shared/domain/commonModel';
import { siteOnViewToSiteEditModel } from 'shared/domain/site/mapping/toModel';
import { startSiteEdit } from 'shared/domain/site/startSiteEdit';
import { SiteModel } from 'shared/domain/site/types/model';
import { projectIdSelector } from 'helpers/misc';
import { useGetAllSites } from 'hooks/useGetAllSites';
import { useCancelConfirmation } from 'presentation/dialogForms/dialogFormsHooks';
import {
  ComponentType,
  ReactElement,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { displayGenericErrorToaster } from 'redux/actions/toasterActions';
import { ChannelNames } from 'shared/domain/channelNames';
import { Identificable } from 'shared/types/commonView';
import { createUniqueId } from 'shared/utils/id/id';
import { siteModelToSiteOnView } from 'views/site/mapping';
import { SiteOnView } from 'shared/domain/site/types/view';
import { handleFormValidation, validate } from '../validation';
import { projectDataSelector } from 'redux/selectors/project';
const formName = 'site';
const SUBMIT_EVENT_TYPE = 'submit-site';
const WithEditSiteContext = createContext<
  EditContext<SiteOnView> | undefined
>(undefined);

function WithEditSite({
  children,
  onSuccessCallback,
}: {
  children: ReactElement;
  onSuccessCallback?: (_id: string, label: string) => void;
}): ReactElement {
  const {
    sites: { items: sites },
    loading: loadingSites,
  } = useSites();
  const { organizationId, _id: projectId } = useSelector(
    projectDataSelector
  );

  const { levelsPerSite } = useLevelsToSelect();
  const intl = useIntl();
  const { getAll: getAllSites } = useGetAllSites();
  const fetchSites = getAllSites;

  const [isPosting, setIsPosting] = useState<boolean>(false);

  const dispatch = useDispatch();
  const releaseSubmitEvent = useCallback(() => {
    window.dispatchEvent(new CustomEvent(SUBMIT_EVENT_TYPE));
  }, []);

  const [currentSiteId, setCurrentSiteId] = useState('');
  const { id } = useParams<{ id: string }>();
  if (id) {
    setCurrentSiteId(id);
  }

  const [existingValues, setExistingValues] = useState<
    SiteOnView | undefined
  >(undefined);

  useEffect(() => {
    const existingSiteModel = findModelForEditing(sites, currentSiteId);
    let siteOnView;
    if (existingSiteModel) {
      siteOnView = siteModelToSiteOnView(
        existingSiteModel,
        levelsPerSite,
        organizationId
      );
      setExistingValues(siteOnView);
    }
  }, [sites, intl, currentSiteId, levelsPerSite]);

  const [open, setOpen] = useState(false);
  const openDialog = useCallback(({ _id }: OpenEditDialogParams) => {
    setOpen(true);
    setCurrentSiteId(_id);
  }, []);
  const onSuccess = useCallback(
    (_id, label) => onSuccessCallback && onSuccessCallback(_id, label),
    [onSuccessCallback]
  );
  const confirmCancel = useCancelConfirmation(formName);
  const openConfirmationDialog = useCallback(async () => {
    const { cancelConfirmed } = await confirmCancel();
    if (!cancelConfirmed) {
      return;
    }
    setOpen(false);
    setExistingValues(undefined);
  }, [confirmCancel]);

  const submitForm = useCallback(
    async (values: SiteOnView) => {
      setIsPosting(true);

      const uniqueId = createUniqueId();
      const siteEditModel = siteOnViewToSiteEditModel(values, projectId);
      return new Promise((resolve, reject) => {
        let timeout: ReturnType<typeof setTimeout>;
        const broadcast = getUpdatedMessageListener(
          (message: SiteModel) => {
            broadcast.close();
            resolve(message);
            setOpen(false);
            setIsPosting(false);
            clearTimeout(timeout);
            fetchSites();
          },
          (error: Error) => {
            broadcast.close();
            reject(error);
            setIsPosting(false);
            displayGenericErrorToaster(dispatch);
            clearTimeout(timeout);
          },
          (message) => {
            return message.uniqueId === uniqueId;
          },
          ChannelNames.siteChannel
        );
        timeout = setTimeout(() => {
          broadcast.close();
          reject(new Error('WithEditSite: Timeout on update.'));
          setIsPosting(false);
          displayGenericErrorToaster(dispatch);
        }, 15000);

        startSiteEdit(siteEditModel, uniqueId);
      })
        .then((site: unknown): any => {
          if (site && (site as Identificable)._id) {
            setOpen(false);
            return site;
          }
        })
        .catch(() => {});
    },
    [dispatch, fetchSites, projectId]
  );

  const ctx: EditContext<SiteOnView> = useMemo(() => {
    return {
      open,
      openDialog,
      closeDialog: openConfirmationDialog,
      submitForm,
      isPosting,
      existingValues,
      SUBMIT_EVENT_TYPE,
      releaseSubmitEvent,
      validate,
      handleFormValidation,
      onSuccess,
      formName,
    };
  }, [
    openConfirmationDialog,
    existingValues,
    isPosting,
    open,
    openDialog,
    releaseSubmitEvent,
    submitForm,
    onSuccess,
  ]);
  if (loadingSites || loadingSites) {
    return (
      <Spinner
        reason={`SiteWizard loadingSites: ${loadingSites} || loadingSites: ${loadingSites} || existingValues: ${existingValues}`}
      />
    );
  }
  return (
    <WithEditSiteContext.Provider value={ctx}>
      {children}
    </WithEditSiteContext.Provider>
  );
}

function useEditSite(): EditContext<SiteOnView> {
  const context = useContext(WithEditSiteContext);
  if (context === undefined) {
    throw new Error('useEditSite must be used within an EditSiteContext');
  }
  return context;
}

const withEditSite =
  (Component: ComponentType<any>) =>
  ({ ...props }): ReactElement => (
    <WithEditSite>
      <Component {...props} />
    </WithEditSite>
  );

export { WithEditSite, useEditSite, withEditSite };
