import {
  DirectoryCreateModel,
  DirectoryEditModel,
  DirectoryModel,
} from 'shared/domain/directory/directoryModel';
import { SyncStatus } from 'shared/domain/entitySyncStatus/syncStatus';
import { toOptionalUserWithPersonalData } from 'shared/domain/user/mapping/toView';
import { UserEntity } from 'shared/domain/user/types/entity';
import { DirectoryInDto } from 'shared/dtos/in/directory';
import { Changes } from 'shared/types/commonEntities';
import {
  DirectoryEntity,
  DirectoryInsertEntity,
  DirectoryLocalInsertEntity,
} from './entity';
import {
  entityToModelBase,
  stringsToCreatableModel,
  stringsToModifiableModel,
} from 'shared/domain/toBaseModel';

export function directoryInDtoToCreateEntity(
  directoryInDto: DirectoryInDto
): DirectoryInsertEntity {
  return {
    _id: directoryInDto._id,
    name: directoryInDto.name,
    parentId: directoryInDto.parentId ? directoryInDto.parentId : '',
    createdAt: directoryInDto.createdAt,
    createdBy: directoryInDto.createdBy,
    deleted: directoryInDto.deleted ? 1 : 0,
    modifiedAt: directoryInDto.modifiedAt,
    modifiedBy: directoryInDto.modifiedBy,
    syncStatus: SyncStatus.SUCCESS,
  };
}

export function directoryEntityToModel(
  directoryEntity: DirectoryEntity,
  users: UserEntity[]
): DirectoryModel {
  const { createdBy, createdAt } = stringsToCreatableModel(
    directoryEntity.createdBy,
    directoryEntity.createdAt,
    users
  );
  const { modifiedBy, modifiedAt } = stringsToModifiableModel(
    directoryEntity.modifiedBy,
    directoryEntity.modifiedAt,
    users
  );
  return {
    _id: directoryEntity._id,
    localId: directoryEntity.localId,
    name: directoryEntity.name,
    parentId: directoryEntity.parentId,
    createdAt,
    deleted: directoryEntity.deleted ? true : false,
    modifiedAt,
    createdBy,
    modifiedBy,
    syncStatus: directoryEntity.syncStatus,
  };
}

export function directoryCreateModelToLocalInsertEntity(
  directoryCreateModel: DirectoryCreateModel
): DirectoryLocalInsertEntity {
  return {
    name: directoryCreateModel.name,
    parentId: directoryCreateModel.parentId
      ? directoryCreateModel.parentId
      : '',
    createdAt: directoryCreateModel.createdAt,
    createdBy: directoryCreateModel.createdBy?._id,
    deleted: directoryCreateModel.deleted ? 1 : 0,
    modifiedAt: directoryCreateModel.modifiedAt,
    modifiedBy: directoryCreateModel.modifiedBy?._id,
    syncStatus: SyncStatus.PENDING,
  };
}

export function directoryEditModelToDirectoryChanges(
  directoryEditModel: DirectoryEditModel
): Changes<DirectoryEntity> {
  return {
    name: directoryEditModel.name,
    modifiedBy: directoryEditModel.modifiedBy,
  };
}
