import {
  DialogFactory,
  useDialog,
} from 'components/core/Dialog/common/DialogContext';
import { ErrorPresentation } from 'helpers/validators';
import { useFormCleanupOnUnmount } from 'presentation/dialogForms/dialogFormsHooks';
import { FormName } from 'presentation/forms';
import React, {
  Dispatch,
  FC,
  MutableRefObject,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { HashMap } from 'shared/types/commonView';
import useFormEdit from 'views/issue/forms/useFormEdit';
import { GU } from '../../common/graphicUploader/types';

export type SubmitOptions =
  | {
      finish?: boolean;
    }
  | undefined;

type FormValidationOptions =
  | {
      finish?: boolean;
    }
  | undefined;

export type CustomSubmitEvent = CustomEvent<SubmitOptions>;

export type FormValidation<T> = (
  errorsSetter: Dispatch<SetStateAction<HashMap<ErrorPresentation>>>,
  form: T,
  options: FormValidationOptions
) => boolean;

export type SetValuesOptions = {
  skipValidation?: boolean;
  skipFormEdit?: boolean;
  forceKeySet?: boolean;
};
type SetInputValuesOptions = {
  withFormEdit?: boolean;
};

type InputFormType = {
  values: HashMap<any>;
  errors: HashMap<ErrorPresentation>;
  setValues: (key: string, value: any, options?: SetValuesOptions) => void;
  dispatchSubmit: (options?: SubmitOptions) => void;
  submitForm: (options?: SubmitOptions) => Promise<any>;
  validate: (options?: FormValidationOptions) => boolean;
  registerField: (
    key: string,
    fieldRef: HTMLDivElement,
    labelId: string,
    dependsOn?: string
  ) => void;
  registerUploader: (key: string, uploader: GU) => void;
  setInputValues: (
    key: string,
    value: any,
    options?: SetInputValuesOptions
  ) => void;
};

const InputFormContext = React.createContext<InputFormType | undefined>(
  undefined
);

type WithInputFormProps = PropsWithChildren<{
  values: HashMap<any>;
  errors: HashMap<ErrorPresentation>;
  handleFormValidation: (
    errorsSetter: Dispatch<
      React.SetStateAction<HashMap<ErrorPresentation>>
    >,
    form: any,
    options?: FormValidationOptions
  ) => boolean;
  handleFieldValidation: (
    key: any,
    value: any,
    allValues?: any
  ) => ErrorPresentation;
  onSubmit: (
    values: any,
    uploaders?: HashMap<GU>,
    submitOptions?: SubmitOptions
  ) => Promise<any>;
  eventType: string;
  dispatchSubmit: (submitOptions?: SubmitOptions) => void;
  formName: FormName;
}>;
// @ts-ignore TODO MUI5
const WithInputForm: FC<WithInputFormProps> = ({
  children,
  values,
  errors,
  handleFormValidation,
  handleFieldValidation,
  onSubmit,
  eventType,
  dispatchSubmit,
  formName,
}) => {
  const canScroll = useRef(false);
  const valuesStateRef = useRef<HashMap<any>>({});
  const labelsRef = useRef<HashMap<string>>({});
  const [valuesState, _setValues] = useState(values);
  const [errorsState, _setErrors] = useState(errors);
  const [inputValuesState, _setInputValues] = useState<HashMap<any>>({});
  const { setEditing } = useFormEdit();
  const createDialog = useDialog();
  useFormCleanupOnUnmount(formName);

  const fieldsRef = useRef<HashMap<HTMLDivElement>>({});
  const dependsParents = useRef<HashMap<Set<string>>>({});
  const registerField = useCallback(
    (
      key: string,
      fieldRef: HTMLDivElement,
      labelId: string,
      dependsOn?: string
    ) => {
      fieldsRef.current[key] = fieldRef;
      labelsRef.current[key] = labelId;
      if (dependsOn) {
        if (dependsParents.current[dependsOn]) {
          dependsParents.current[dependsOn].add(key);
        } else {
          dependsParents.current[dependsOn] = new Set([key]);
        }
      }
    },
    []
  );

  const uploadersRef = useRef<HashMap<GU>>({});
  const registerUploader = useCallback((key: string, uploader: GU) => {
    uploadersRef.current[key] = uploader;
  }, []);

  useEffect(() => {
    valuesStateRef.current = valuesState;
  }, [valuesState]);

  useEffect(() => {
    if (!canScroll.current) {
      return;
    }
    canScroll.current = false;

    const fieldsWithErrors = Object.keys(errorsState).map((key) => {
      return fieldsRef.current[key];
    });
    let topErrorElement: HTMLElement | undefined;
    fieldsWithErrors.forEach((errorElement) => {
      if (!errorElement) {
        return;
      }
      if (!topErrorElement) {
        topErrorElement = errorElement;
        return;
      }

      if (errorElement.offsetTop < topErrorElement.offsetTop) {
        topErrorElement = errorElement;
      }
    });

    if (topErrorElement) {
      topErrorElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
    }
  }, [errorsState]);

  useEffect(() => {
    function submitValues(e: CustomEvent<SubmitOptions>): void {
      canScroll.current = true;
      const finish = e.detail?.finish || false;
      const isInvalid = handleFormValidation(_setErrors, valuesState, {
        finish,
      });
      const isInputInvalid = Object.keys(inputValuesState).some(
        (key) => inputValuesState[key]
      );
      if (isInvalid || isInputInvalid) {
        return;
      }
      setEditing(false, formName);
      onSubmit(valuesState, uploadersRef.current, e.detail);
    }

    //@ts-ignore typescript does not like custom event
    window.addEventListener(eventType, submitValues);

    return (): void => {
      //@ts-ignore typescript does not like custom event
      window.removeEventListener(eventType, submitValues);
    };
  }, [
    eventType,
    onSubmit,
    valuesState,
    inputValuesState,
    handleFormValidation,
    formName,
    setEditing,
  ]);

  const submitForm = useCallback(
    (options: SubmitOptions) => {
      canScroll.current = true;

      const isInvalid = handleFormValidation(
        _setErrors,
        valuesState,
        options
      );
      const isInputInvalid = Object.keys(inputValuesState).some(
        (key) => inputValuesState[key]
      );

      if (isInvalid || isInputInvalid) {
        throw new Error('Form validation failed.');
      }

      setEditing(false, formName);
      return onSubmit(valuesState, uploadersRef.current, options);
    },
    [
      onSubmit,
      valuesState,
      inputValuesState,
      handleFormValidation,
      setEditing,
      formName,
    ]
  );

  const setValuesWithOptions = useCallback(
    (key: string, value: any, options: SetValuesOptions = {}): void => {
      const { skipFormEdit, skipValidation, forceKeySet } = options;
      const previousValue = valuesStateRef.current[key];
      if (!skipFormEdit) {
        setEditing(true, formName);
      }

      if (!skipValidation) {
        const error = handleFieldValidation(key, value, {
          // not sure how to pass current values properly. I guess there is some better way
          ...valuesState,
          [key]: value,
        });
        _setErrors((prev) => {
          if (
            prev[key] === error &&
            prev[key]?.helperText.id === error?.helperText.id
          )
            return prev;
          return {
            ...prev,
            [key]: error,
          };
        });
      }

      showDialogOnDependantChange(
        createDialog,
        dependsParents,
        key,
        valuesStateRef,
        labelsRef
      )
        .then(() => {
          _setValues((prev) => {
            if (forceKeySet) {
              const isKeySet = Object.keys(prev).includes(key);
              if (isKeySet) {
                return prev;
              }
            }

            if (prev[key] === value && !forceKeySet) {
              return prev;
            }
            return {
              ...prev,
              [key]: value,
            };
          });
        })
        .catch(() => {
          _setValues((prev) => {
            if (forceKeySet) {
              const isKeySet = Object.keys(prev).includes(key);
              if (isKeySet) {
                return prev;
              }
            }

            return {
              ...prev,
              [key]: previousValue,
            };
          });
        });
    },
    [handleFieldValidation, formName, setEditing, createDialog]
  );

  const validate = useCallback(() => {
    canScroll.current = true;
    return handleFormValidation(_setErrors, valuesState);
  }, [valuesState, handleFormValidation]);

  const setInputValues = useCallback(
    (formKey, value, options?: SetInputValuesOptions) => {
      _setInputValues((prev) => {
        return {
          ...prev,
          [formKey]: value,
        };
      });
      if (options?.withFormEdit) {
        setEditing(true, formName);
      }
    },
    [setEditing, formName]
  );

  const ctx = {
    values: valuesState,
    errors: errorsState,
    setValues: setValuesWithOptions,
    dispatchSubmit: dispatchSubmit,
    submitForm: submitForm,
    validate: validate,
    registerField: registerField,
    registerUploader: registerUploader,
    setInputValues: setInputValues,
  };

  return (
    <InputFormContext.Provider value={ctx}>
      {children}
    </InputFormContext.Provider>
  );
};

function useInputForm(): InputFormType {
  const context = React.useContext(InputFormContext);
  if (context === undefined) {
    throw new Error(
      'useInputForm must be used within a InputFormContextProvider'
    );
  }
  return context;
}

export { WithInputForm, useInputForm };

function showDialogOnDependantChange(
  createDialog: DialogFactory,
  dependsParents: MutableRefObject<HashMap<Set<string>>>,
  key: string,
  valuesStateRef: MutableRefObject<HashMap<any>>,
  labelsRef: MutableRefObject<HashMap<string>>
): Promise<void> {
  if (dependsParents.current[key]) {
    const fields = Array.from(dependsParents.current[key]).filter(
      (value) => {
        return Array.isArray(valuesStateRef.current[value])
          ? valuesStateRef.current[value].length
          : valuesStateRef.current[value];
      }
    );

    if (fields.length) {
      return createDialog({
        catchOnCancel: true,
        title: <FormattedMessage id='dialog_depended_warning_title' />,
        description: (
          <span>
            <FormattedMessage
              id='dialog_depended_form_field_reset_warning_web'
              values={{
                fieldsCount: fields.length,
                fields: fields.map((field) => (
                  <FormattedMessage
                    id={labelsRef.current[field]}
                  ></FormattedMessage>
                )),
                // @ts-ignore
                b: (text: string): React.ReactElement => (
                  <strong style={{ color: 'crimson' }}>{text}</strong>
                ),
              }}
            />
          </span>
        ),
        customControllerLabels: ['general_cancel', 'general_ok'],
      });
    }
  }

  return Promise.resolve();
}
