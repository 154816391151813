import {
  HttpRequestModelType,
  HttpRequestStatus,
} from 'shared/domain/httpRequest/httpRequestModel';
import { UserCreateUseCase } from 'shared/domain/user/createUser';
import { userCreateModelToCreateOutDto } from 'shared/domain/user/mapping/toOutDto';
import { UserCreateModel } from 'shared/domain/user/types/model';
import { add as addRequest } from 'serviceWorker/db/httpRequests';
import { debugLog } from 'shared/logger/debugLog';
import { UserHttpCreateRequestModel } from '../httpQueue/user/types';

export class UserSaver implements UserCreateUseCase {
  constructor(
    private addRequest: (
      request: UserHttpCreateRequestModel
    ) => Promise<any>
  ) {}
  async execute(
    userCreateModel: UserCreateModel,
    uniqueId: string
  ): Promise<void> {
    debugLog('UserCreateUseCase', userCreateModel);

    const userCreateOutDto =
      userCreateModelToCreateOutDto(userCreateModel);

    await this.addRequest({
      createdAt: Date.now(),
      method: 'POST',
      data: {
        userCreateOutDto,
        projectId: userCreateModel.projectId,
        uniqueId: uniqueId,
      },
      url: '/user',
      entityType: HttpRequestModelType.user,
      status: HttpRequestStatus.NEW,
    });
  }
}

export const userSaver = new UserSaver(addRequest);
