import { Box, Typography } from '@mui/material';
import {
  NavigationDestination,
  isGivenLocation,
} from 'helpers/navigation';
import React, { Ref } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import useStyles from './styles';

type NavigationItemButtonProps = {
  isSubItem?: boolean;
  componentKey: NavigationDestination;
  textId: string;
  redirectAddress?: string;
  // why capital I: https://stackoverflow.com/a/37414418 also it's a component
  Icon: React.ElementType;
  isNavigationExpanded: boolean;
  // onClick?: Handler;
  disabled?: boolean;
};

const NavigationItemButton = ({
  isSubItem,
  componentKey,
  textId,
  redirectAddress,
  Icon,
  isNavigationExpanded,
  // onClick,
  disabled,
}: NavigationItemButtonProps): JSX.Element => {
  const classes = useStyles();

  let buttonClass = classes.button;

  const clickable = Boolean(!disabled && redirectAddress);
  const { pathname } = useLocation();
  const isButtonRedirectLocationCurrentLocation: boolean =
    !!redirectAddress && isGivenLocation(componentKey, pathname);

  if (isNavigationExpanded) {
    if (clickable) {
      if (isSubItem) {
        if (isButtonRedirectLocationCurrentLocation) {
          buttonClass = classes.clickableSubItemButtonActiveLocation;
        } else {
          buttonClass = classes.clickableSubItemButtonNotActiveLocation;
        }
      } else {
        if (isButtonRedirectLocationCurrentLocation) {
          buttonClass = classes.clickableItemButtonActiveLocation;
        } else {
          buttonClass = classes.clickableItemButtonNotActiveLocation;
        }
      }
    }

    return (
      <Box
        className={buttonClass}
        // onClick={onClick}
      >
        <Icon fill='#757575' className={classes.svg} />

        <Typography variant='h4' component='span' className={classes.text}>
          <FormattedMessage id={textId} />
        </Typography>
      </Box>
    );
  } else {
    return (
      <Box
        className={classes.button}
        data-qa={`navigation-item-${componentKey}`}
        // onClick={onClick}
      >
        <Icon
          className={
            isButtonRedirectLocationCurrentLocation
              ? classes.svgWhenCollapsedAndCurrentLocation
              : classes.svg
          }
        />
      </Box>
    );
  }
};

export default React.memo(NavigationItemButton);
