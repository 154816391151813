import { EditOutlined } from '@mui/icons-material';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { useIsOffline } from 'components/common/withIsOffline';
import { ButtonTextIcon } from 'components/general';
import { MemoForbiddenTooltip } from 'components/general/ForbiddenTooltip/ForbiddenTooltip';
import {
  MemoPreviewField,
  MemoPreviewPhoneField,
} from 'components/general/PreviewField';
import {
  Permission,
  noPermission,
  noPermissionInOffline,
} from 'helpers/permission';
import useIsMobile from 'hooks/useIsMobile';
import React, { ReactElement, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toUserRole } from 'redux/selectors/role';
import { currentUserSelector } from 'redux/selectors/users';
import { EDIT_DELETE_USER_PERMISSIONS } from 'setup/permissions';
import { USER_ROLE_LABEL_ID, UserRole } from 'shared/types/userRole';
import { UserOnView, UserRoleOnView } from 'shared/domain/user/types/view';
import { useCommonSingleEntityViewStyles } from '../../common/styles/useCommonSingleEntityViewStyles';
import { DeleteClientAdmin } from './DeleteClientAdmin';
import { DeleteOther } from './DeleteOther';
import { useStyles } from './styles';
import { MemoDisabledPhoneField } from 'components/common/simpleInputFields/phoneInput';

type SingleUserProps = UserOnView;

export function SingleUser(props: SingleUserProps): React.ReactElement {
  const isMobile = useIsMobile();
  const classes = useCommonSingleEntityViewStyles({ isMobile });
  const specificClasses = useStyles({ isMobile });
  const intl = useIntl();
  const isOffline = useIsOffline();
  const history = useHistory();

  const authorRole = useSelector(toUserRole);
  const {
    data: { _id: authorId },
  } = useSelector(currentUserSelector);

  const {
    label,
    phone,
    email,
    processes,
    role,
    sites,
    _id: userId,
  } = props;

  const editPermission = useMemo((): Permission => {
    if (role._id === UserRole.organizationAdmin) {
      return noPermission('tooltip_action_forbidden');
    }
    if (!EDIT_DELETE_USER_PERMISSIONS[authorRole]) {
      return noPermission('tooltip_action_forbidden');
    }
    if (isOffline) {
      return noPermissionInOffline();
    }
    return {
      permission: true,
    };
  }, [authorRole, isOffline, role]);

  const deletePermission = useMemo((): Permission => {
    if (authorId === userId) {
      return noPermission('tooltip_action_forbidden');
    }
    if (role._id === UserRole.organizationAdmin) {
      return noPermission('tooltip_action_forbidden');
    }
    if (!EDIT_DELETE_USER_PERMISSIONS[authorRole]) {
      return noPermission('tooltip_action_forbidden');
    }
    if (isOffline) {
      return noPermissionInOffline();
    }
    return {
      permission: true,
    };
  }, [authorRole, isOffline, role, authorId, userId]);

  const edit = useCallback(() => {
    history.push(`/user/${userId}/edit`);
  }, [userId, history]);

  return (
    <Box>
      {/* <h1 className={specificClasses.breakWord}>{label}</h1> */}
      <Typography sx={{ m: 3 }} variant='h1' margin='dense'>
        {label}
      </Typography>
      <Box sx={{ marginBottom: 2, mx: 2 }}>
        <div>
          {role._id === UserRole.organizationAdmin ? null : (
            <MemoForbiddenTooltip
              visibleWhen={!editPermission.permission}
              reason={editPermission.reason}
              inlined
            >
              <ButtonTextIcon
                disabled={!editPermission.permission}
                startIcon={EditOutlined}
                onClick={edit}
              >
                {intl.formatMessage({
                  id: 'general_edit',
                })}
              </ButtonTextIcon>
            </MemoForbiddenTooltip>
          )}

          <DeleteUser
            deletePermission={deletePermission}
            label={label}
            userId={userId}
            displayedUserRole={role}
            authorRole={authorRole}
            authorId={authorId}
          />
        </div>
      </Box>

      <Divider />
      <Grid container sx={{ m: 2 }} columnSpacing={1} rowSpacing={0}>
        <Grid item sx={{ my: 1 }} xs={8} sm={6}>
          <MemoPreviewField
            isHalf={!isMobile}
            label={intl.formatMessage({ id: 'user_email' })}
            value={email}
            dense={true}
          />
        </Grid>
        <Grid item sx={{ my: 1 }} xs={8} sm={6}>
          <MemoPreviewPhoneField
            label={intl.formatMessage({ id: 'phone_number' })}
            value={phone}
            dense={true}
          />
        </Grid>
        <Grid item sx={{ my: 1 }} xs={8} sm={6}>
          <MemoPreviewField
            isHalf={!isMobile}
            label={intl.formatMessage({ id: 'access_management_role' })}
            value={intl.formatMessage({
              id: USER_ROLE_LABEL_ID[role._id],
            })}
            dense={true}
          />
        </Grid>

        <Grid item sx={{ my: 1 }} xs={8} sm={6}>
          <MemoPreviewField
            label={intl.formatMessage({ id: 'filters_filter_type_site' })}
            value={sites.map(({ label }) => label)}
            dense={true}
          />
        </Grid>
        <Grid item sx={{ my: 1 }} xs={8} sm={6}>
          <MemoPreviewField
            isHalf={!isMobile}
            label={intl.formatMessage({ id: 'general_process' })}
            value={processes.map(({ label }) => label)}
            dense={true}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

function DeleteUser({
  deletePermission,
  label,
  userId,
  displayedUserRole,
  authorRole,
  authorId,
}: {
  deletePermission: Permission;
  label: string;
  userId: string;
  displayedUserRole: UserRoleOnView;
  authorRole: UserRole;
  authorId: string;
}): ReactElement {
  return displayedUserRole._id === UserRole.organizationAdmin ? (
    <DeleteClientAdmin
      label={label}
      userId={userId}
      authorRole={authorRole}
      authorId={authorId}
    />
  ) : (
    <DeleteOther
      deletePermission={deletePermission}
      label={label}
      userId={userId}
      role={displayedUserRole}
    />
  );
}
