import { debugLog } from 'shared/logger/debugLog';
import { LogLevel } from 'shared/types/logger';
import { appLog } from './appLog';
import { provideLogInfo } from './provideLogInfo';

export const logError = (
  message: string,
  errorObjectWithPotentiallySensitiveData?: any
): void => {
  const additionalInfo = provideLogInfo();
  debugLog(
    message,
    LogLevel.ERROR,
    additionalInfo,
    errorObjectWithPotentiallySensitiveData
  );
  appLog(
    message,
    LogLevel.ERROR,
    additionalInfo,
    errorObjectWithPotentiallySensitiveData
  );
};
