import { getDefaultCountryCode } from 'intl/helpers';
import {
  matchIsValidTel,
  MuiTelInput,
  MuiTelInputCountry,
  MuiTelInputInfo,
} from 'mui-tel-input';
import {
  ReactElement,
  Ref,
  forwardRef,
  memo,
  useCallback,
  useState,
} from 'react';
import { DisabledPhoneInputProps, PhoneInputProps } from './types';
import { DateValidationError } from '@mui/x-date-pickers';
import { formName } from 'components/dataCreationForms/user/withCreateUser';
import { useIntl } from 'react-intl';
import { getHelperTextFromError } from './common';

function DisabledPhoneInput(
  { value, localisedLabel, dense }: DisabledPhoneInputProps<string>,
  ref: Ref<HTMLDivElement>
): ReactElement {
  return (
    <MuiTelInput
      fullWidth
      value={value}
      disabled={true}
      data-qa='user-view-phone-input'
      label={localisedLabel}
      defaultCountry={getDefaultCountryCode() as MuiTelInputCountry}
      margin={dense ? 'dense' : 'none'}
      sx={{
        '& .MuiOutlinedInput-root': {
          borderRadius: 1.5,
          //   paddingLeft: 0,
          //   '& input': {
          //     // lub '& .MuiOutlinedInput-input'
          //     paddingLeft: 0,
          //   },
          //   '& .MuiOutlinedInput-input': {
          //     // lub '& .MuiOutlinedInput-input'
          //     paddingLeft: 0,
          //   },
        },
      }}
      //   InputLabelProps={{ style: { paddingLeft: 0 } }}
      InputProps={{ style: { paddingLeft: 0 } }}
    />
  );
}

function PhoneInput(
  {
    value,
    localisedLabel,
    handleChange,
    error,
    dense,
    reserveSpaceForHelperText,
  }: PhoneInputProps<string>,
  ref: Ref<HTMLDivElement>
): ReactElement {
  const intl = useIntl();
  const [_error, _setError] = useState<string | undefined>(error);
  const handlePhoneChange = (
    newValue: string,
    _: MuiTelInputInfo
  ): void => {
    const error = validatePhone(newValue);

    _setError(error);
    // because stateful component expects error on the first place
    handleChange({}, newValue, _);
  };
  const validatePhone = (value: string): string => {
    if (value && !matchIsValidTel(value)) {
      return intl.formatMessage({ id: 'validation_invalid_phone' });
    }

    return '';
  };
  const [helperText, _setHelperText] = useState(
    getHelperTextFromError(error, intl)
  );
  const setHelperText = useCallback(
    (error: DateValidationError) =>
      _setHelperText(getHelperTextFromError(error, intl)),
    [intl]
  );
  return (
    <MuiTelInput
      fullWidth
      value={value}
      onChange={handlePhoneChange}
      data-qa='user-view-phone-input'
      label={localisedLabel}
      error={!!_error}
      helperText={
        getHelperTextFromError(_error, intl) ||
        (reserveSpaceForHelperText && ' ')
      }
      defaultCountry={getDefaultCountryCode() as MuiTelInputCountry}
      margin={dense ? 'dense' : 'none'}
      sx={{
        '& .MuiOutlinedInput-root': {
          borderRadius: 1.5,
        },
      }}
    />
  );
}
export const MemoPhoneInput = memo(forwardRef(PhoneInput));
export const MemoDisabledPhoneField = memo(forwardRef(DisabledPhoneInput));
