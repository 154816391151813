import { CompanyEntity } from 'shared/domain/company/types/entity';
import {
  ContractModel,
  ContractPartyModel,
} from 'shared/domain/contract/types/model';
import { toOptionalUserWithPersonalData } from 'shared/domain/user/mapping/toView';
import { UserEntity } from 'shared/domain/user/types/entity';
import { ContractInDto } from 'shared/dtos/in/contract';
import { ContractEntity, ContractPartyEntity } from './entity';
import { entityToModelBase } from 'shared/domain/toBaseModel';

function contractPartyEntityToContractPartyModel(
  contractParty: ContractPartyEntity,
  companies: CompanyEntity[]
): ContractPartyModel {
  const company = companies.find(
    (company) => company._id === contractParty.companyId
  );
  const {
    correspondenceAddress,
    overrideCompanyCorrespondenceAddress,
    companyId,
    signingDate,
    contactPerson,
  } = contractParty;
  return {
    correspondenceAddress,
    overrideCompanyCorrespondenceAddress,
    companyId,
    signingDate,
    contactPerson,
    companyShortLabel: company?.shortLabel || '',
    companyLongLabel: company?.longLabel || '',
  };
}

export function contractEntityToModel(
  contractEntity: ContractEntity,
  companies: CompanyEntity[],
  users: UserEntity[]
): ContractModel {
  const {
    label,
    term,
    monetaryValue,
    timeToRespondToIssue,
    timeToResolveIssue,
  } = contractEntity;

  const modelBase = entityToModelBase({ entity: contractEntity, users });
  return {
    ...modelBase,
    label,
    term,
    monetaryValue,
    timeToRespondToIssue,
    timeToResolveIssue,
    parties: contractEntity.parties.map((contractParty) =>
      contractPartyEntityToContractPartyModel(contractParty, companies)
    ),
  };
}

export function contractInDtoToEntity(
  inDto: ContractInDto
): ContractEntity {
  const {
    createdAt,
    createdBy,
    modifiedAt,
    modifiedBy,
    deleted,
    _id,
    label,
    term,
    monetaryValue,
    timeToRespondToIssue,
    timeToResolveIssue,
  } = inDto;

  return {
    createdAt,
    createdBy,
    modifiedAt,
    modifiedBy,
    deleted: deleted ? 1 : 0,
    _id,
    label,
    term,
    monetaryValue,
    timeToRespondToIssue,
    timeToResolveIssue,
    parties: inDto.parties,
  };
}
