import {
  listenToOpenDirectoryForm,
  OpenEditDirectoryEvent,
} from 'presentation/directory/openDirectoryInput';
import {
  ComponentType,
  createContext,
  FC,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { displayGenericErrorToaster } from 'redux/actions/toasterActions';
import { projectDataSelector } from 'redux/selectors/project';
import { currentUserSelector } from 'redux/selectors/users';
import { startDirectoryCreate } from 'shared/domain/directory/startDirectoryCreate';
import { startDirectoryEdit } from 'shared/domain/directory/startDirectoryEdit';
import { userWithPermissionsInDtoToUserWithPersonalData } from 'shared/domain/user/mapping/toView';
import { nowISO } from 'shared/utils/date/dates';
import { createUniqueId } from 'shared/utils/id/id';
import { useCurrentDirectoryContext } from '../../dataProviders/withCurrentDirectory';
import { getFormResultListener as getListener } from './model';
import {
  DirectoryFields,
  DirectoryFormContext,
  DirectoryInForm,
  SubmitForm,
} from './types';

const WithDirectoryFormContext = createContext<
  DirectoryFormContext | undefined
>(undefined);

const WithDirectoryForm: FC<{ children?: ReactNode }> = ({ children }) => {
  const dispatch = useDispatch();
  const { currentDirectoryStore } = useCurrentDirectoryContext();
  const { data: currentUser } = useSelector(currentUserSelector);
  const formName: 'directory' = 'directory';
  const SUBMIT_EVENT_TYPE = 'directory_submit_event';
  const [isPosting, setIsPosting] = useState(false);
  const afterSubmitSuccess = useRef<CallableFunction | undefined>(
    undefined
  );
  const [directory, setDirectory] = useState<DirectoryInForm | undefined>(
    undefined
  );
  const { _id: projectId } = useSelector(projectDataSelector);
  const [isOpen, setIsOpen] = useState(false);
  const closeDialog = useCallback(() => {
    setIsOpen(false);
    setDirectory(undefined);
  }, []);
  const openDialog = useCallback((directory?: DirectoryInForm) => {
    setDirectory(directory);
    setIsOpen(true);
  }, []);

  const submitForm: SubmitForm = useCallback(
    async (values: DirectoryFields) => {
      setIsPosting(true);

      const timeout = setTimeout(onError, 15000);
      const uniqueId = directory?._id || createUniqueId();
      const broadcast = getListener(
        () => {
          clearTimeout(timeout);
          setIsPosting(false);
          setIsOpen(false);
        },
        uniqueId,
        onError,
        directory
      );

      function onError(): void {
        broadcast.close();
        displayGenericErrorToaster(dispatch);
        setIsPosting(false);
      }

      const author = userWithPermissionsInDtoToUserWithPersonalData(
        currentUser,
        projectId
      );

      if (directory) {
        startDirectoryEdit(
          {
            ...values,
            localId: directory.localId,
            modifiedBy: author._id,
          },
          uniqueId
        );
        return;
      }

      startDirectoryCreate(
        {
          ...values,
          parentId: currentDirectoryStore.get(),
          createdBy: author,
          modifiedBy: author,
          createdAt: nowISO(),
          modifiedAt: nowISO(),
          deleted: false,
        },
        uniqueId
      );
    },
    [dispatch, currentUser, currentDirectoryStore, directory]
  );

  const releaseSubmitEvent: () => void = useCallback(() => {
    window.dispatchEvent(new CustomEvent(SUBMIT_EVENT_TYPE));
  }, []);

  const setAfterSubmitSuccess = useCallback((action) => {
    if (typeof action === 'function' || typeof action === undefined)
      afterSubmitSuccess.current = action;
  }, []);

  useEffect(() => {
    const onEvent = (event: CustomEvent<OpenEditDirectoryEvent>): void => {
      openDialog(event.detail);
    };
    // @ts-ignore CustomEvent;
    return listenToOpenDirectoryForm(onEvent);
  }, [openDialog]);

  const ctx = useMemo(() => {
    return {
      directory,
      isOpen,
      closeDialog,
      openDialog,
      submitForm,
      isPosting,
      SUBMIT_EVENT_TYPE,
      releaseSubmitEvent,
      formName,
      setAfterSubmitSuccess,
    };
  }, [
    directory,
    isOpen,
    closeDialog,
    openDialog,
    isPosting,
    releaseSubmitEvent,
    submitForm,
    setAfterSubmitSuccess,
  ]);

  return (
    <WithDirectoryFormContext.Provider value={ctx}>
      {children}
    </WithDirectoryFormContext.Provider>
  );
};

export function useDirectoryForm(): DirectoryFormContext {
  const context = useContext(WithDirectoryFormContext);
  if (context === undefined) {
    throw new Error(
      'useDirectoryForm must be used within an DirectoryFormContext'
    );
  }
  return context;
}

export const withDirectoryForm =
  (Component: ComponentType<any>) =>
  ({ ...props }): ReactElement => (
    <WithDirectoryForm>
      <Component {...props} />
    </WithDirectoryForm>
  );
