import { useIssueForm } from 'components/common/withIssueForm';
import { useCorrectiveActionTypes } from 'components/dataProviders/withCorrectiveActionTypes';
import { useEnvironmentalAspects } from 'components/dataProviders/withEnvironmentalAspects';
import { useHazardCategories } from 'components/dataProviders/withHazardCategories';
import { useIssueFilters } from 'components/dataProviders/withIssueFilters';
import {
  useLevelsToSelect,
  withLevelsToSelect,
} from 'components/dataProviders/withLevelsToSelect';
import { useSites } from 'components/dataProviders/withSites';
import { useUsers, withUsers } from 'components/dataProviders/withUsers';
import {
  useFieldVisibility,
  withFieldVisibility,
} from 'components/dataProviders/withVisibleFields';
import { useWorktypes } from 'components/dataProviders/withWorktypes';
import { keepUndeleted } from 'shared/domain/deletable/filters';
import { levelModelToLevelOnView } from 'shared/domain/level/mapping/toView';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from 'redux/actionTypes';
import { FiltersType, getFilterableFields } from 'redux/selectors/filters';
import { projectDataSelector } from 'redux/selectors/project';
import { toUserRole } from 'redux/selectors/role';
import { getStages } from '../stages';
import Presentational from './Presentational';
import { useInspectionsFilterItems } from './model';

const FilterSidebar = ({
  sidebarOpen,
  toggleSidebar,
}: {
  sidebarOpen: boolean;
  toggleSidebar?: () => void;
}): React.ReactElement => {
  const { filters, resetFilters } = useIssueFilters();
  const { fieldVisibilityStore } = useFieldVisibility();
  const [visibleFields, setVisibleFields] = useState(
    fieldVisibilityStore.get()
  );
  useEffect(() => {
    setVisibleFields(fieldVisibilityStore.get());
    return fieldVisibilityStore.subscribe(() => {
      setVisibleFields(fieldVisibilityStore.get());
    });
  }, [fieldVisibilityStore]);

  const [createdFilters, setCreatedFilters] = useState<FiltersType>({
    generalFields: [],
    dateFields: [],
  });

  const {
    all: { items: users },
  } = useUsers();
  const { issueForm } = useIssueForm();

  const { items: workTypes } = useWorktypes();
  const { items: hazardCategories } = useHazardCategories();
  const { items: environmentalAspects } = useEnvironmentalAspects();
  const { items: correctiveActionTypes } = useCorrectiveActionTypes();
  const { levels } = useLevelsToSelect();
  const {
    sites: { items: sites },
  } = useSites();

  const { processes, organizationId } = useSelector(projectDataSelector);

  const currentUserRole = useSelector(toUserRole);

  const dispatch = useDispatch();
  const intl = useIntl();

  const inspectionsFilterItems = useInspectionsFilterItems(
    processes,
    currentUserRole
  );

  useEffect(() => {
    const stages = getStages(intl); //TODO https://hustro.atlassian.net/browse/PT-3892
    const { generalFields, dateFields } = getFilterableFields(
      visibleFields,
      processes,
      filters,
      stages,
      users,
      issueForm,
      currentUserRole,
      inspectionsFilterItems,
      {
        workTypes: workTypes,
        hazardCategory: hazardCategories,
        environmentalAspect: environmentalAspects,
        proposedCorrectiveAction: correctiveActionTypes,
        level: levels
          .filter(keepUndeleted)
          .map((level) => levelModelToLevelOnView(level, organizationId)),
        site: sites.filter(keepUndeleted),
      },
      organizationId
    );
    setCreatedFilters({ generalFields, dateFields });
  }, [
    visibleFields,
    processes,
    filters,
    users,
    intl,
    issueForm,
    inspectionsFilterItems,
    currentUserRole,
    workTypes,
    hazardCategories,
    environmentalAspects,
    correctiveActionTypes,
    levels,
    sites,
  ]);

  const defaultToggle = (): void => {
    dispatch({ type: actions.TOGGLE_FILTERS_SIDEBAR });
  };

  return (
    <Presentational
      sidebarOpen={sidebarOpen}
      toggleSidebar={toggleSidebar ? toggleSidebar : defaultToggle}
      resetFilters={resetFilters}
      createdFilters={createdFilters}
    />
  );
};

export default withUsers(
  withLevelsToSelect(withFieldVisibility(FilterSidebar))
);
