import { BroadcastChannel } from 'broadcast-channel';
import { useCompanies } from 'components/dataProviders/withCompanies';
import { useUsers } from 'components/dataProviders/withUsers';
import { sortLabelledEntities } from 'helpers/sort';
import { useGetSingleChart } from 'hooks/useGetSingleChart';
import { useMountedRef } from 'hooks/useMountRef';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { projectDataSelector } from 'redux/selectors/project';
import { ChannelNames } from 'shared/domain/channelNames';
import {
  DomainMessagesTypes,
  Message,
} from 'shared/domain/messages/message';
import { isSuperAdmin } from 'shared/domain/role/isSuperAdmin';
import { userModelToLabelledWithEmail } from 'shared/domain/user/mapping/toView';
import { UserModel } from 'shared/domain/user/types/model';
import { AnyFilters, Chart } from 'shared/types/analytics';
import { LabelledEntity } from 'shared/types/commonView';
import { GetChart } from './types';

function toAssignees(
  users: UserModel[],
  organizationId: string
): LabelledEntity[] {
  return Object.values(users)
    .filter((user) => !isSuperAdmin(user.role))
    .map((user) => userModelToLabelledWithEmail(user, organizationId))
    .sort(sortLabelledEntities);
}

export function useSubcontractors(): LabelledEntity[] {
  const {
    companies: { items: companies },
  } = useCompanies();

  const subcontractors = useMemo(
    () =>
      companies
        .map(({ _id, shortLabel }) => ({
          _id,
          label: shortLabel,
        }))
        .sort(sortLabelledEntities),
    [companies]
  );

  return subcontractors;
}

export function useAssignees(): LabelledEntity[] {
  const {
    all: { items: users },
  } = useUsers();
  const { organizationId } = useSelector(projectDataSelector);
  return useMemo(() => toAssignees(users, organizationId), [users]);
}

export function useGetChart<T>(
  chart: Chart,
  filters: AnyFilters<any> | undefined,
  additionalParams?: any
): GetChart<T> {
  const mountedRef = useMountedRef();
  const [loading, setLoading] = useState(true);
  const [series, setSeries] = useState<T>([[], []] as unknown as T);
  const [chartCalculatedAt, setChartCalculatedAt] = useState(0);
  const { getChart } = useGetSingleChart(chart, filters, additionalParams);

  const load = useCallback(
    (forceUpdate?: boolean) => {
      setLoading(true);
      getChart(forceUpdate);
    },
    [getChart]
  );

  const reload = useCallback(() => {
    return load(true);
  }, [load]);

  useEffect(() => {
    const broadcast = new BroadcastChannel(ChannelNames.analyticsChannel);

    broadcast.onmessage = (event: Message): void => {
      if (
        event.type === DomainMessagesTypes.chartData &&
        event.data.chart === chart
      ) {
        if (!mountedRef.current) return;
        setChartCalculatedAt((prev: number): number => {
          if (prev <= event.data.updatedAt) {
            setSeries(event.data.series);
            setLoading(false);
            return event.data.updatedAt;
          }
          return prev;
        });
      }
    };

    getChart();

    return (): void => {
      broadcast.close();
    };
  }, [getChart, chart, mountedRef]);

  return {
    series,
    loading,
    load,
    reload,
    updatedAt: chartCalculatedAt,
  };
}
