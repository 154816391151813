import { BroadcastChannel } from 'broadcast-channel';
import React, {
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { projectDataSelector } from 'redux/selectors/project';
import { ChannelNames } from 'shared/domain/channelNames';
import { CompanyModel } from 'shared/domain/company/types/model';
import { CompanyOnView } from 'shared/domain/company/types/view';
import {
  DomainMessagesTypes,
  Message,
} from 'shared/domain/messages/message';
import { companyModelToCompanyOnView } from 'views/companies/mapping';
import { WithContracts, useContracts } from '../withContracts';
import { useSites } from '../withSites';

type CompanyContextType = {
  company: CompanyOnView | undefined;
  loading: boolean;
};

const CompanyContext = React.createContext<CompanyContextType | undefined>(
  undefined
);

const WithCompany: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const { id } = useParams<{ id: string }>();
  const intl = useIntl();
  const {
    contracts: { items: contracts },
    loading: loadingContracts,
  } = useContracts();

  const {
    sites: { items: sites },
  } = useSites();

  const [company, setCompany] = useState<CompanyOnView | undefined>(
    undefined
  );
  const [loadingCompany, setLoadingCompany] = useState<boolean>(
    Boolean(id)
  );
  const [companyModel, setCompanyModel] = useState<
    CompanyModel | undefined
  >();
  const { organizationId } = useSelector(projectDataSelector);
  useEffect(() => {
    const broadcast = new BroadcastChannel(ChannelNames.companyChannel);
    let mounted = true;

    broadcast.onmessage = (event: Message): void => {
      if (!mounted) {
        return;
      }

      if (
        event.data &&
        event.type === DomainMessagesTypes.company &&
        event.data._id === id
      ) {
        broadcast.close();
        setCompanyModel(event.data);
        setLoadingCompany(false);
      }
    };

    broadcast.postMessage({
      type: DomainMessagesTypes.getState,
    });

    return (): void => {
      broadcast.close();
      mounted = false;
    };
  }, [id]);

  useEffect(() => {
    if (!companyModel || loadingContracts) {
      return;
    }

    setCompany(
      companyModelToCompanyOnView(
        companyModel,
        sites,
        intl,
        organizationId
      )
    );
  }, [companyModel, loadingContracts, contracts, sites, intl]);

  const ctx = {
    company: company,
    loading: loadingCompany || loadingContracts,
  };

  return (
    <CompanyContext.Provider value={ctx}>
      {children}
    </CompanyContext.Provider>
  );
};

export function useCompany(): CompanyContextType {
  const context = React.useContext(CompanyContext);
  if (context === undefined) {
    throw new Error(
      'useCompany must be used within an CompanyContextProvider'
    );
  }
  return context;
}

export const withCompany =
  (Component: React.ComponentType<any>) =>
  ({ ...props }): ReactElement => (
    // react 18 types
    // @ts-ignore
    <WithContracts>
      <WithCompany>
        <Component {...props} />
      </WithCompany>
    </WithContracts>
  );
