import { useDialog } from 'components/core/Dialog/common/DialogContext';
import { projectIdSelector } from 'helpers/misc';
import { useGetAllLevels } from 'hooks/useGetAllLevels';
import React, {
  PropsWithChildren,
  ReactElement,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { projectDataSelector } from 'redux/selectors/project';
import { keepUndeleted } from 'shared/domain/deletable/filters';
import { levelModelToLevelOnView } from 'shared/domain/level/mapping/toView';
import { startLevelDelete } from 'shared/domain/level/startLevelDelete';
import { startLevelRestore } from 'shared/domain/level/startLevelRestore';
import { LevelOnView } from 'shared/domain/level/types/view';
import {
  Deletable,
  HashMap,
  LabelledEntity,
} from 'shared/types/commonView';
import { createUniqueId } from 'shared/utils/id/id';
import { useLevelChannelListener } from '../../broadcastChannelListeners/withLevelChannelListener';
import { useEntityDataSubscription } from '../../common/useEntityDataSubscription';
import { sortLevels } from './model';
import { LevelsContextType, LevelsResponse } from './types';

const initialLevels: LevelsResponse = { items: [], total: 0 };

const LevelsContext = React.createContext<LevelsContextType | undefined>(
  undefined
);

const WithLevels: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const createDialog = useDialog();
  const [levels, setLevels] = useState<LevelsResponse>(initialLevels);
  const { organizationId } = useSelector(projectDataSelector);
  const levelsPerSite = useMemo(() => {
    return levels.items.reduce<HashMap<LevelOnView[]>>(
      (result, level): HashMap<LevelOnView[]> => {
        if (Array.isArray(result[level.site])) {
          result[level.site].push(
            levelModelToLevelOnView(level, organizationId)
          );
        } else {
          result[level.site] = [
            levelModelToLevelOnView(level, organizationId),
          ];
        }
        return result;
      },
      {}
    );
  }, [levels]);
  const [loading, setLoading] = useState<boolean>(true);

  const { getAll: getAllLevels } = useGetAllLevels();
  const { subscribe } = useLevelChannelListener();
  const setSortedLevels = useCallback((levels) => {
    setLevels({
      items: levels.items.sort(sortLevels),
      total: levels.items.length,
    });
  }, []);
  const { resync } = useEntityDataSubscription({
    subscribe,
    getAll: getAllLevels,
    setEntity: setSortedLevels,
    setLoading,
    entityName: 'levels',
  });
  const projectId = useSelector(projectIdSelector);

  const deleteLevel = useCallback(
    (level: LabelledEntity) => {
      createDialog({
        title: (
          <FormattedMessage id='dialog_confirmation_delete_level_title' />
        ),
        description: (
          <FormattedMessage
            id='dialog_confirmation_delete_level_description'
            values={{ levelName: level.label }}
          />
        ),
        customControllerLabels: ['general_cancel', 'general_archive'],
      }).then(() => {
        startLevelDelete({
          levelId: level._id,
          projectId,
          uniqueId: createUniqueId(),
        });
        setLevels((prev) => {
          const foundLevel = prev.items.find(
            (lvl) => lvl._id === level._id
          );
          if (!foundLevel) {
            return prev;
          }
          foundLevel.deleted = true;

          return {
            items: [...prev.items],
            total: prev.items.length,
          };
        });
      });
    },
    [createDialog, projectId]
  );

  const restoreLevel = useCallback(
    (level: LabelledEntity) => {
      startLevelRestore({
        levelId: level._id,
        projectId,
        uniqueId: createUniqueId(),
      });
      setLevels((prev) => {
        const foundLevel = prev.items.find((lvl) => lvl._id === level._id);
        if (!foundLevel) {
          return prev;
        }
        foundLevel.deleted = false;

        return {
          items: [...prev.items],
          total: prev.items.length,
        };
      });
    },
    [projectId]
  );

  const canDeleteLevel = useCallback((levels: Deletable[]) => {
    const deleteDisabled = levels.filter(keepUndeleted).length <= 1;
    return {
      disabled: deleteDisabled,
      reason: 'level_archive_disabled_reason_last_item',
    };
  }, []);

  const ctx: LevelsContextType = useMemo(
    () => ({
      levels: levels,
      levelsPerSite: levelsPerSite,
      loading: loading,
      resync: resync,
      deleteLevel: deleteLevel,
      canDeleteLevel: canDeleteLevel,
      restoreLevel: restoreLevel,
    }),
    [
      deleteLevel,
      levels,
      levelsPerSite,
      loading,
      resync,
      canDeleteLevel,
      restoreLevel,
    ]
  );

  return (
    <LevelsContext.Provider value={ctx}>{children}</LevelsContext.Provider>
  );
};

const withLevels =
  (Component: React.ComponentType<any>) =>
  ({ ...props }): ReactElement => (
    <WithLevels>
      <Component {...props} />
    </WithLevels>
  );

function useLevels(): LevelsContextType {
  {
    const context = React.useContext(LevelsContext);
    if (context === undefined) {
      throw new Error(
        'useLevels must be used within a LevelsContextProvider'
      );
    }
    return context;
  }
}

export { useLevels, WithLevels, withLevels };
