import { Spinner } from 'components/core';
import { findModelForEditing } from 'components/dataCreationForms/common';
import { useProjects } from 'components/dataProviders/withProjects';
import { getUpdatedMessageListener } from 'shared/domain/commonModel';
import { projectEditOnViewToEditModel } from 'shared/domain/project/mapping/toModel';
import { projectModelToProjectEditOnView } from 'shared/domain/project/mapping/toView';
import { startProjectEdit } from 'shared/domain/project/startProjectEdit';
import { ProjectModel } from 'shared/domain/project/types/model';
import { ProjectEditOnView } from 'shared/domain/project/types/view';
import { useGetAllProjects } from 'hooks/useGetAllProjects';
import { useCancelConfirmation } from 'presentation/dialogForms/dialogFormsHooks';
import {
  ComponentType,
  ReactElement,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { displayGenericErrorToaster } from 'redux/actions/toasterActions';
import { ChannelNames } from 'shared/domain/channelNames';
import { Identificable } from 'shared/types/commonView';
import { createUniqueId } from 'shared/utils/id/id';
import { EditProjectContext } from '../types';
import {
  handleProjectEditFormValidation,
  validateProjectEdit,
} from '../validation';
import { useOrganizations } from 'components/dataProviders/withOrganizations';
import { debugLog } from 'shared/logger/debugLog';
const formName = 'project';
const SUBMIT_EVENT_TYPE = 'submit-project';
const WithEditProjectContext = createContext<
  EditProjectContext | undefined
>(undefined);

function WithEditProject({
  children,
  onSuccessCallback,
}: {
  children: ReactElement;
  onSuccessCallback?: (_id: string, label: string) => void;
}): ReactElement {
  const history = useHistory();
  const { getAll: getAllProjects } = useGetAllProjects();
  const fetchProjects = getAllProjects;
  const [isPosting, setIsPosting] = useState<boolean>(false);
  const { organizationsMap } = useOrganizations();
  const dispatch = useDispatch();
  const releaseSubmitEvent = useCallback(() => {
    window.dispatchEvent(new CustomEvent(SUBMIT_EVENT_TYPE));
  }, []);

  const {
    projects: { items: projects },
    loading: loadingProjects,
  } = useProjects();
  const { id: currentProjectId } = useParams<{ id: string }>();

  const [existingValues, setExistingValues] = useState<
    ProjectEditOnView | undefined
  >(undefined);

  useEffect(() => {
    const existingProjectModel = findModelForEditing(
      projects,
      currentProjectId
    );
    let projectEditOnView;
    if (existingProjectModel) {
      projectEditOnView = projectModelToProjectEditOnView(
        existingProjectModel,
        organizationsMap.get(existingProjectModel.organizationId)?.label ||
          ''
      );
      setExistingValues(projectEditOnView);
    }
  }, [projects, currentProjectId, organizationsMap]);

  const [open, setOpen] = useState(false);

  const openDialog = useCallback(() => {
    setOpen(true);
  }, []);

  const onSuccess = useCallback(
    (_id, label) => onSuccessCallback && onSuccessCallback(_id, label),
    [onSuccessCallback]
  );

  const confirmCancel = useCancelConfirmation(formName);

  const openConfirmationDialog = useCallback(async () => {
    const { cancelConfirmed } = await confirmCancel();
    if (!cancelConfirmed) {
      return;
    }
    setOpen(false);
  }, [confirmCancel]);

  const submitForm = useCallback(
    async (values: ProjectEditOnView) => {
      setIsPosting(true);

      const uniqueId = createUniqueId();
      const projectEditModel = projectEditOnViewToEditModel(values);
      return new Promise((resolve, reject) => {
        let timeout: ReturnType<typeof setTimeout>;
        const broadcast = getUpdatedMessageListener(
          (message: ProjectModel) => {
            broadcast.close();
            resolve(message);
            setOpen(false);
            setIsPosting(false);
            clearTimeout(timeout);
            fetchProjects();
          },
          (error: Error) => {
            broadcast.close();
            reject(error);
            setIsPosting(false);
            displayGenericErrorToaster(dispatch);
            clearTimeout(timeout);
          },
          (message) => {
            return message.uniqueId === uniqueId;
          },
          ChannelNames.projectChannel
        );
        timeout = setTimeout(() => {
          broadcast.close();
          reject(new Error('WithEditProject: Timeout on update.'));
          setIsPosting(false);
          displayGenericErrorToaster(dispatch);
        }, 15000);

        startProjectEdit(projectEditModel, uniqueId);
      })
        .then((project: unknown): any => {
          if (project && (project as Identificable)._id) {
            // const redirectAfterCreation =
            //   window.location.href.indexOf('create-project') > 0;
            // redirectAfterCreation &&
            history.push(`/projectSettings?target=generalProjectData`);
            // : closeDialog();
            return project;
          }
        })
        .catch(() => {});
    },
    [dispatch, fetchProjects, history]
  );

  const ctx: EditProjectContext = useMemo(() => {
    return {
      open,
      openDialog,
      closeDialog: openConfirmationDialog,
      submitForm,
      isPosting,
      existingValues,
      SUBMIT_EVENT_TYPE,
      releaseSubmitEvent,
      validate: validateProjectEdit,
      handleFormValidation: handleProjectEditFormValidation,
      onSuccess,
      formName,
    };
  }, [
    openConfirmationDialog,
    existingValues,
    isPosting,
    open,
    openDialog,
    releaseSubmitEvent,
    submitForm,
    onSuccess,
  ]);
  if (loadingProjects) {
    return (
      <Spinner
        reason={`ProjectWizard: loadingProjects. ExistingValues: ${existingValues}`}
      />
    );
  }
  return (
    <WithEditProjectContext.Provider value={ctx}>
      {children}
    </WithEditProjectContext.Provider>
  );
}

function useEditProject(): EditProjectContext {
  const context = useContext(WithEditProjectContext);
  if (context === undefined) {
    throw new Error(
      'useEditProject must be used within an EditProjectContext'
    );
  }
  return context;
}

const withEditProject =
  (Component: ComponentType<any>) =>
  ({ ...props }): ReactElement => (
    <WithEditProject>
      <Component {...props} />
    </WithEditProject>
  );

export { WithEditProject, useEditProject, withEditProject };
