import { useEffect, useState } from 'react';
import { lastEdit } from 'shared/domain/lastEdit';
import { DateTime } from 'luxon';
import { IntlShape } from 'react-intl';
import { HashMap } from 'shared/types/commonView';
import { IssueFieldNames } from 'shared/domain/issueForm/types/fieldNames';
import { projectDataSelector } from 'redux/selectors/project';
import { useSelector } from 'react-redux';

export function useLastEditString(
  intl: IntlShape,
  modifiedAt: DateTime | string,
  modifiedBy: string | undefined,
  isExpanded: boolean,
  visibleFields: HashMap<boolean>
): string {
  const { timezone } = useSelector(projectDataSelector);
  if (typeof modifiedAt === 'string') {
    modifiedAt = DateTime.fromISO(modifiedAt, {
      zone: timezone,
    });
  }
  const [lastEditString, setLastEditString] = useState(
    getLastEditString(
      intl,
      modifiedAt,
      modifiedBy,
      isExpanded,
      visibleFields,
      timezone
    )
  );

  useEffect(() => {
    const set = (): void =>
      setLastEditString(
        getLastEditString(
          intl,
          modifiedAt,
          modifiedBy,
          isExpanded,
          visibleFields,
          timezone
        )
      );
    const interval = setInterval(set, 60000);
    set();
    return () => {
      clearInterval(interval);
    };
  }, [intl, modifiedAt, modifiedBy, isExpanded, visibleFields, timezone]);

  return lastEditString;
}

function getLastEditString(
  intl: IntlShape,
  modifiedAt: DateTime | string,
  modifiedBy: string | undefined,
  isExpanded: boolean,
  visibleFields: HashMap<boolean>,
  timezone: string
): string {
  if (!visibleFields[IssueFieldNames.modificationDate]) {
    return '';
  }
  if (typeof modifiedAt === 'string') {
    modifiedAt = DateTime.fromISO(modifiedAt, {
      zone: timezone,
    });
  }
  const editedAt = lastEdit(
    DateTime.now().setZone(timezone),
    modifiedAt.setZone(timezone),
    intl.locale
  );
  const userLabel = modifiedBy;

  if (!editedAt) {
    return '';
  }

  const skipUser =
    !visibleFields[IssueFieldNames.modifiedBy] ||
    !userLabel ||
    !isExpanded;

  if (skipUser && editedAt.now) {
    return intl.formatMessage({
      id: 'general_modified_at_related_time_just_now',
    });
  }

  if (skipUser) {
    return intl.formatMessage(
      { id: 'general_modified_at_related_time' },
      { periodText: editedAt.period }
    );
  }

  if (!skipUser && editedAt.now) {
    return intl.formatMessage(
      { id: 'general_modified_at_related_time_with_user_just_now' },
      { userLabel }
    );
  }

  return intl.formatMessage(
    { id: 'general_modified_at_related_time_with_user' },
    { userLabel, periodText: editedAt.period }
  );
}
