import { UserInDto } from 'shared/domain/user/types/inDto';
import {
  PermissionsChangeOutDto,
  UserCreateOutDto,
  UserLabelAndPhoneEditOutDto,
} from 'shared/domain/user/types/outDto';
import { UserPermissionDto } from 'shared/types/userRole';

export interface IUserUploader {
  POST: (url: string, data: any) => Promise<any>;
  PUT: (url: string, data: any) => Promise<any>;
  DELETE: (url: string) => Promise<any>;
  GET: (url: string) => Promise<any>;
}

export interface UploadLabelAndPhoneUserChange {
  (
    uploader: IUserUploader,
    body: UserLabelAndPhoneEditOutDto,
    url: string
  ): Promise<UserInDto[]>;
}

export interface UploadPermissionsUserChange {
  (
    uploader: IUserUploader,
    body: PermissionsChangeOutDto,
    url: string
  ): Promise<UserInDto[]>;
}

export interface UploadUser {
  (
    uploader: IUserUploader,
    body: UserCreateOutDto,
    url: string
  ): Promise<UserInDto>;
}

export interface UploadUserDelete {
  (uploader: IUserUploader, url: string): Promise<unknown>;
}

export function uploadUserLabelAndPhoneChange(
  uploader: IUserUploader,
  body: UserLabelAndPhoneEditOutDto,
  url: string
): Promise<UserInDto[]> {
  return uploader.PUT(url, body);
}

export function uploadUserPermissionsChange(
  uploader: IUserUploader,
  body: PermissionsChangeOutDto,
  url: string
): Promise<UserInDto[]> {
  return uploader.PUT(url, body);
}

export function uploadUser(
  uploader: IUserUploader,
  body: UserCreateOutDto,
  url: string
): Promise<UserInDto> {
  return uploader.POST(url, { ...body });
}

export function uploadUserDelete(
  uploader: IUserUploader,
  url: string
): Promise<unknown> {
  return uploader.DELETE(url);
}
