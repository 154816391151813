import { useDialog } from 'components/core/Dialog/common/DialogContext';
import { createUserRoleDropdownOptions } from 'components/dataCreationForms/user/model';
import { useInputForm } from 'components/dataCreationForms/withInputForm';
import { useSites } from 'components/dataProviders/withSites';
import {
  FormFieldProps,
  MultiChoiceFormFieldProps,
} from 'components/inspection/Form/types';
import { toLabelledEntities } from 'helpers/misc';
import { useCurrentUserRoleInSelectedProject } from 'hooks/useCurrentUserRoleInSelectedProject';
import React, { memo, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { projectDataSelector } from 'redux/selectors/project';
import { currentUserSelector } from 'redux/selectors/users';
import { keepUndeleted } from 'shared/domain/deletable/filters';
import { EditableStandardEntityName } from 'shared/domain/fieldValue/fields';
import { UserOnView } from 'shared/domain/user/types/view';
import { CreatableUserRole, UserRole } from 'shared/types/userRole';
import { UserFieldsPresentational } from './presentational';
import { UserFormProps } from './types';
import { useAdminSelection } from './useAdminSelection';
import { useVisibleNoAccess } from './useVisibleNoAccess';
import { debugLog } from 'shared/logger/debugLog';

function UserForm({
  isDialog,
  editedUserId,
  existingRoleId,
}: UserFormProps): React.ReactElement {
  const intl = useIntl();
  const author = useSelector(currentUserSelector);
  const authorRole = useCurrentUserRoleInSelectedProject();
  const { values, setValues } = useInputForm();
  const roleValue: { _id: CreatableUserRole } = values['role'];
  const sitesValue = values['sites'];
  const processesValue = values['processes'];

  const {
    sites: { items: sites },
  } = useSites();
  const availableSites = useMemo(
    () => toLabelledEntities(sites.filter(keepUndeleted)),
    [sites]
  );
  const { processes } = useSelector(projectDataSelector);

  const availableProcesses = useMemo(
    () => toLabelledEntities(processes),
    [processes]
  );

  const createDialog = useDialog();
  const disabledSelfChange = Boolean(editedUserId)
    ? author.data._id === editedUserId
    : false;

  const { adminSelected, handleAdminSelection, handleAdminDeselection } =
    useAdminSelection({
      intl,
      createDialog,
      processesValue,
      sitesValue,
      availableSites,
      availableProcesses,
      setValues,
    });

  const { handleVisibleNoAccessSelection } = useVisibleNoAccess(setValues);

  useEffect(() => {
    if (
      roleValue?._id === existingRoleId ||
      roleValue?._id === UserRole.standard ||
      roleValue?._id === UserRole.viewer ||
      roleValue?._id === UserRole.manager ||
      roleValue?._id === adminSelected
    ) {
      return;
    }

    if (roleValue?._id === UserRole.visible_no_access) {
      return handleVisibleNoAccessSelection();
    }

    handleAdminSelection(roleValue?._id);
  }, [
    roleValue,
    adminSelected,
    handleAdminSelection,
    existingRoleId,
    handleVisibleNoAccessSelection,
  ]);

  useEffect(() => {
    if (
      !adminSelected ||
      roleValue?._id === UserRole.projectAdmin ||
      roleValue?._id === UserRole.organizationAdmin
    ) {
      debugLog('efekt 2 return');
      return;
    }
    debugLog('efekt 2 handleAdminDeselection');

    handleAdminDeselection();
  }, [roleValue, adminSelected, handleAdminDeselection]);

  const isVisibleNoAccessSelected =
    roleValue?._id === UserRole.visible_no_access;
  const labelFieldProps: FormFieldProps<UserOnView> = useMemo(
    () => ({
      formKey: 'label',
      localStorageKey: 'user-label',
      required: true,
      labelId: 'access_management_name',
      fieldName: 'text-field-name',
      dense: true,
      reserveSpaceForHelperText: !isDialog,
      'data-qa': 'access_management_name_field',
    }),
    [isDialog]
  );
  const phoneFieldProps: FormFieldProps<UserOnView> = useMemo(
    () => ({
      formKey: 'phone',
      localStorageKey: 'user-phone',
      required: true,
      labelId: 'phone_number',
      fieldName: 'text-field-phone',
      dense: true,
      reserveSpaceForHelperText: !isDialog,
      'data-qa': 'user_phone_field',
    }),
    [isDialog]
  );

  const emailFieldProps: FormFieldProps<UserOnView> = useMemo(
    () => ({
      formKey: 'email',
      required: true,
      labelId: 'user_email',
      fieldName: 'text-field-email',
      disabled: !!editedUserId,
      dense: true,
      reserveSpaceForHelperText: !isDialog,
      'data-qa': 'access_management_email_field',
    }),
    [editedUserId, isDialog]
  );

  const roleFieldProps: FormFieldProps<UserOnView> = useMemo(
    () => ({
      formKey: 'role',
      required: true,
      labelId: 'access_management_role',
      fieldName: 'singleselect-role',
      available: createUserRoleDropdownOptions(authorRole, intl),
      getOptionLabel: (option): string =>
        intl.formatMessage({ id: option.label }),
      disabled: disabledSelfChange
        ? { reason: 'tooltip_access_self_edit_unavailable' }
        : false,
      dense: true,
      reserveSpaceForHelperText: !isDialog,
      'data-qa': 'access_management_role_field',
    }),
    [intl, authorRole, disabledSelfChange, isDialog]
  );

  const sitesFieldProps: MultiChoiceFormFieldProps<UserOnView> = useMemo(
    () => ({
      formKey: 'sites',
      required: false,
      labelId: 'filters_filter_type_site',
      fieldName: 'multiselect-site',
      available: availableSites,
      entityName: EditableStandardEntityName.location,
      disabled: adminSelected
        ? { reason: 'user_form_admin_all_sites_all_processes_info' }
        : disabledSelfChange
          ? { reason: 'tooltip_access_self_edit_unavailable' }
          : roleValue?._id === UserRole.visible_no_access
            ? {
                reason:
                  'user_form_visible_no_access_no_sites_no_processes_info',
              }
            : false,
      reserveSpaceForHelperText: !isDialog,
      dense: true,
      'data-qa': 'user_sites',
    }),
    [
      availableSites,
      adminSelected,
      disabledSelfChange,
      isDialog,
      roleValue?._id,
      isVisibleNoAccessSelected,
    ]
  );

  const processesFieldProps: MultiChoiceFormFieldProps<UserOnView> =
    useMemo(
      () => ({
        formKey: 'processes',
        required: !(roleValue?._id === UserRole.visible_no_access),
        labelId: 'filters_filter_type_process',
        fieldName: 'multiselect-processes',
        disabled: adminSelected
          ? { reason: 'user_form_admin_all_sites_all_processes_info' }
          : disabledSelfChange
            ? { reason: 'tooltip_access_self_edit_unavailable' }
            : roleValue?._id === UserRole.visible_no_access
              ? {
                  reason:
                    'user_form_visible_no_access_no_sites_no_processes_info',
                }
              : false,
        available: availableProcesses,
        entityName: '',
        dense: true,
        reserveSpaceForHelperText: !isDialog,
        'data-qa': 'user_processes',
        isVisibleNoAccessSelected,
      }),
      [
        availableProcesses,
        adminSelected,
        disabledSelfChange,
        isDialog,
        roleValue?._id,
      ]
    );

  return (
    <UserFieldsPresentational
      labelFieldProps={editedUserId ? labelFieldProps : null}
      phoneFieldProps={editedUserId ? phoneFieldProps : null}
      emailFieldProps={emailFieldProps}
      roleFieldProps={roleFieldProps}
      sitesFieldProps={sitesFieldProps}
      processesFieldProps={processesFieldProps}
    />
  );
}

export const MemoUserForm = memo(UserForm);
