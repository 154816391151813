import InsufficientPermissionsPage from 'components/core/InsufficientPermissionsPage/InsufficientPermissionsPage';
import { isAdmin } from 'shared/domain/role/isAdmin';
import { UserInDto } from 'shared/domain/user/types/inDto';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { currentProjectUserSelector } from 'redux/selectors/users';
import { MemoSiteView } from 'views/site/single';
import { MemoGenericRoleAuthorizer } from '../GenericRoleAuthorizer';

export const AuthorizableSiteView = React.memo((): React.ReactElement => {
  const currentUser: UserInDto = useSelector(currentProjectUserSelector);

  const isCurrentUserAdmin = useMemo(() => {
    return isAdmin(currentUser.role);
  }, [currentUser]);

  return (
    <MemoGenericRoleAuthorizer
      authorized={isCurrentUserAdmin}
      unauthorized={!isCurrentUserAdmin}
      authorizedComponent={MemoSiteView}
      unauthorizedComponent={InsufficientPermissionsPage}
    />
  );
});
