import { Box, Tooltip } from '@mui/material';
import { PropsWithChildren, ReactElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { DelayIcon, OnTime } from './icons';
import { useStyles } from './styles';

export function StaticField({
  isExpanded,
  label,
  hasValue,
  children,
}: PropsWithChildren<{
  label: string | undefined;
  isExpanded: boolean;
  hasValue: boolean;
}>): ReactElement {
  if (!label) return <></>;
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginTop: hasValue || isExpanded ? '0.75rem' : '0px',
        maxHeight: !hasValue && !isExpanded ? '0' : '100px',
        height: 'max-content',
        transition: 'max-height .2s, margin .2s',
        width: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      <ExpandableLabel label={label} isExpanded={isExpanded} />
      {children}
    </Box>
  );
}

export function ExpandableField({
  isExpanded,
  label,
  children,
}: PropsWithChildren<{
  label: string | undefined;
  isExpanded: boolean;
}>): ReactElement {
  if (!label) return <></>;
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        alignItems: 'flex-start',
        // https://stackoverflow.com/questions/3508605/how-can-i-transition-height-0-to-height-auto-using-css
        height: 'max-content',
        maxHeight: isExpanded ? '100px' : '0px',
        transition: 'max-height .2s, margin .2s',
        overflow: 'hidden',
        marginTop: isExpanded ? '0.75rem' : '0px',
      }}
    >
      <ExpandableLabel label={label} isExpanded={isExpanded} />
      {children}
    </div>
  );
}

function ExpandableLabel({
  label,
  isExpanded,
}: {
  label: string;
  isExpanded: boolean;
}): ReactElement {
  return (
    <div
      style={{
        boxSizing: 'border-box',
        fontWeight: 700,
        fontSize: '0.75rem',
        color: '#757575',
        // https://stackoverflow.com/questions/3508605/how-can-i-transition-height-0-to-height-auto-using-css
        height: 'max-content',
        maxHeight: isExpanded ? '100px' : '0px',
        marginBottom: '4px',
        overflow: 'hidden',
        transition: 'max-height .2s',
      }}
    >
      {label}
    </div>
  );
}

function DelayInfo({
  delay,
  show,
  isExpanded,
  tooltipLabel,
}: {
  delay: number;
  isExpanded: boolean;
  show: boolean;
  tooltipLabel: string;
}): ReactElement {
  const intl = useIntl();
  return delay > 0 ? (
    <Tooltip title={tooltipLabel}>
      <span
        style={{
          display: 'flex',
          alignItems: 'center',
          marginLeft: '8px',
          color: '#E53935',
          justifyContent: 'flex-start',
          gap: '4px',
          opacity: show ? '1' : '0',
          transition: 'opacity 0.2s',
        }}
      >
        <DelayIcon
          htmlColor='#E53935'
          style={{
            fontSize: '0.75rem',
          }}
        />
        <span>
          {intl.formatMessage(
            { id: 'issue_delay_value' },
            { days: delay }
          )}
        </span>
      </span>
    </Tooltip>
  ) : (
    <Tooltip title={tooltipLabel}>
      <span
        style={{
          display: 'flex',
          alignItems: 'center',
          color: '#00904C',
          justifyContent: 'flex-start',
          gap: '4px',
          opacity: show ? '1' : '0',
          transition: 'opacity 0.2s',
        }}
      >
        <OnTime
          htmlColor='#00904C'
          style={{
            fontSize: '0.75rem',
          }}
        />
        {isExpanded && show && (
          <span>
            {intl.formatMessage({
              id:
                delay < 0
                  ? 'issue_delay_before_time'
                  : 'issue_delay_on_time',
            })}
          </span>
        )}
      </span>
    </Tooltip>
  );
}

export function TextValueField({
  text,
  isExpanded,
  tooltipLabel,
}): ReactElement {
  if (!text && isExpanded) {
    return <NoDataValue />;
  }

  return (
    <Box
      style={{
        fontSize: '0.75rem',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '100%',
      }}
    >
      <Tooltip title={tooltipLabel}>
        <span>{text}</span>
      </Tooltip>
    </Box>
  );
}

export function DateFieldValueGrid({
  dates,
  Arrow,
  delays,
  isExpanded,
  startDateFieldLabel,
  endDateFieldLabel,
  startDatesDelayIconLabel,
  endDatesDelayIconLabel,
}: {
  dates: (string | undefined | null)[];
  Arrow: () => ReactElement;
  delays: (number | null)[];
  isExpanded: boolean;
  startDateFieldLabel: string;
  endDateFieldLabel: string;
  startDatesDelayIconLabel: string;
  endDatesDelayIconLabel: string;
}): ReactElement {
  const cs = useStyles();

  const [startDate, endDate] = dates;

  if (dates.length < 2) {
    return (
      <span className={cs.cardDateValueContainer}>
        <DateWithDelayIcon
          date={startDate}
          data-qa='date-with-delay-icon-start-single'
          dateFieldNameLabel={startDateFieldLabel}
          delayIconLabel={startDatesDelayIconLabel}
          delay={delays[0]}
          isExpanded={isExpanded}
        />
      </span>
    );
  }

  return (
    <div className={cs.cardDateFieldGrid}>
      <span className={cs.cardDateValueContainer}>
        <DateWithDelayIcon
          date={startDate}
          data-qa='date-with-delay-icon-start'
          dateFieldNameLabel={startDateFieldLabel}
          delayIconLabel={startDatesDelayIconLabel}
          delay={delays[0]}
          isExpanded={isExpanded}
        />
      </span>
      <Arrow />
      <span className={cs.cardDateValueContainer}>
        <DateWithDelayIcon
          date={endDate}
          data-qa='date-with-delay-icon-end'
          dateFieldNameLabel={endDateFieldLabel}
          delayIconLabel={endDatesDelayIconLabel}
          delay={delays[1]}
          isExpanded={isExpanded}
        />
      </span>
    </div>
  );
}

function DateWithDelayIcon({
  date,
  delay,
  isExpanded,
  dateFieldNameLabel,
  delayIconLabel,
}: {
  isExpanded: boolean;
  date?: string | null;
  delay?: number | null;
  dateFieldNameLabel: string;
  delayIconLabel: string;
}): ReactElement | null {
  if (!date) {
    return <NoDataValue />;
  }

  return (
    <>
      <Tooltip title={dateFieldNameLabel}>
        <span>{date}</span>
      </Tooltip>
      {typeof delay === 'number' && !isExpanded && (
        <DelayInfo
          delay={delay}
          show={!isExpanded}
          isExpanded={isExpanded}
          tooltipLabel={delayIconLabel}
        />
      )}
    </>
  );
}

export function DelayValuesGrid({
  isExpanded,
  delays,
}: {
  isExpanded: boolean;
  delays: (number | null)[];
}): ReactElement {
  const cs = useStyles();
  const intl = useIntl();
  const [startDelay, completionDelay] = delays;

  if (
    delays.length < 2 &&
    (typeof startDelay !== 'number' || isNaN(startDelay)) &&
    isExpanded
  ) {
    return <NoDataValue />;
  }

  if (
    delays.length < 2 &&
    typeof startDelay === 'number' &&
    !isNaN(startDelay)
  ) {
    return (
      <div>
        <DelayInfo
          delay={startDelay}
          show={isExpanded}
          isExpanded={isExpanded}
          data-qa='delay-info-start-single'
          tooltipLabel={intl.formatMessage({
            id: 'issue_start_date_delay_label',
          })}
        />
      </div>
    );
  }

  return (
    <div
      className={cs.cardDateFieldGrid}
      style={{
        // https://stackoverflow.com/questions/3508605/how-can-i-transition-height-0-to-height-auto-using-css
        height: 'max-content',
        maxHeight: isExpanded ? '100px' : '0px',
        transition: 'max-height .2s',
        overflow: 'hidden',
      }}
    >
      <div>
        {(typeof startDelay === 'number' && !isNaN(startDelay) && (
          <DelayInfo
            delay={startDelay}
            show={isExpanded}
            isExpanded={isExpanded}
            data-qa='delay-info-start'
            tooltipLabel={intl.formatMessage({
              id: 'issue_start_date_delay_label',
            })}
          />
        )) || <NoDataValue />}
      </div>
      <div></div>
      <div>
        {(typeof completionDelay === 'number' &&
          !isNaN(completionDelay) && (
            <DelayInfo
              delay={completionDelay}
              show={isExpanded}
              isExpanded={isExpanded}
              data-qa='delay-info-completion'
              tooltipLabel={intl.formatMessage({
                id: 'issue_delay_label',
              })}
            />
          )) || <NoDataValue />}
      </div>
    </div>
  );
}

function NoDataValue(): ReactElement {
  return (
    <span
      style={{
        color: '#979797',
        fontSize: '0.75rem',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '100%',
      }}
    >
      <FormattedMessage id='issue_card_no_data' />
    </span>
  );
}
