import { BroadcastChannel } from 'broadcast-channel';
import { ChannelNames } from 'shared/domain/channelNames';
import {
  DomainMessagesTypes,
  Message,
} from 'shared/domain/messages/message';
import { filterOutSuperAdmin } from 'shared/domain/user/filterSuperAdmin';
import { userModelToUserOnView } from 'shared/domain/user/mapping/toView';
import { UserModel } from 'shared/domain/user/types/model';
import { useGetUser } from 'hooks/useGetUser';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { projectDataSelector } from 'redux/selectors/project';
import { UserOnView } from 'shared/domain/user/types/view';
import { useSites } from '../withSites';

type UserContextType = {
  user: UserOnView | undefined;
  loading: boolean;
};

export const UserContext = React.createContext<UserContextType>({
  user: undefined,
  loading: true,
});

const WithUser: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const { id } = useParams<{ id: string }>();
  const { processes, organizationId } = useSelector(projectDataSelector);
  const {
    sites: { items: sites },
    loading: loadingSites,
  } = useSites();
  const intl = useIntl();

  const [userOnView, setUserOnView] = useState<UserOnView | undefined>();
  const [userModel, setUserModel] = useState<UserModel | undefined>();

  const [loadingUser, setLoadingUser] = useState<boolean>(Boolean(id));

  useEffect(() => {
    const broadcast = new BroadcastChannel(ChannelNames.userChannel);
    let mounted = true;

    broadcast.onmessage = (event: Message): void => {
      if (!mounted) {
        return;
      }

      if (
        event.data &&
        event.type === DomainMessagesTypes.user &&
        event.data._id === id &&
        filterOutSuperAdmin(event.data)
      ) {
        broadcast.close();
        setUserModel(event.data);
        setLoadingUser(false);
      }
    };

    broadcast.postMessage({
      type: DomainMessagesTypes.getState,
    });

    return (): void => {
      broadcast.close();
      mounted = false;
    };
  }, [id]);

  useEffect(() => {
    if (!userModel || loadingSites) {
      return;
    }

    setUserOnView(
      userModelToUserOnView({
        userModel,
        sites,
        processes,
        intl,
        organizationId,
      })
    );
  }, [userModel, processes, sites, loadingSites, intl]);
  useGetUser(id, true);
  const ctx = {
    user: userOnView,
    loading: loadingUser || loadingSites,
  };

  return (
    <UserContext.Provider value={ctx}>{children}</UserContext.Provider>
  );
};

export const withUser =
  (Component: React.ComponentType<any>) =>
  ({ ...props }): React.ReactElement => (
    <WithUser>
      <Component {...props} />
    </WithUser>
  );

export function useUser(): UserContextType {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserContextProvider');
  }
  return context;
}
