import {
  Box,
  Button,
  Divider,
  Drawer,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import { ButtonContained } from '../../../general';

import { useSelector } from 'react-redux';

import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { LanguageSwitch } from '../../../../intl/LanguageSwitch';

import useIsMobile from 'hooks/useIsMobile';
import GDPRClauseLink from '../../GDPRClause/GDPRClauseLink';
import { styles } from './styles';

import BackgroundLetterAvatar from 'components/core/Avatar/Avatar';
import { useLogout } from 'components/core/WithLogout';
import { ServiceWorkerCheckButton } from 'components/serviceWorkerCheck';
import { currentLabelledUserSelector } from 'redux/selectors/users';
import { useIsOffline } from '../../../common/withIsOffline';
import ForbiddenTooltip from '../../../general/ForbiddenTooltip/ForbiddenTooltip';
import CurrentProjectButton from '../CurrentProjectButton/CurrentProjectButton';
import { MENU_BAR_HEADER_HEIGHT_PX } from '../styles';

const HeaderInfo = (): React.ReactElement | null => {
  const isMobile = useIsMobile();
  const classes = styles({ isMobile });

  const [drawerOpen, setDrawerOpen] = useState(false);
  const user = useSelector(currentLabelledUserSelector);

  const { loading, logout } = useLogout();

  const toggleDrawerOpen = (): void => {
    setDrawerOpen(true);
  };

  const toggleDrawerClose = (): void => {
    setDrawerOpen(false);
  };

  const onLogoutClick = (e: React.MouseEvent): void => {
    e.preventDefault();
    logout();
  };

  if (loading) {
    return null;
  }
  const userName = user.label;

  return (
    <Box
      sx={{
        display: 'flex',
        overflowX: 'auto',
        flexGrow: 1,
        justifyContent: 'flex-end',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          height: MENU_BAR_HEADER_HEIGHT_PX,
          alignItems: 'center',
          flex: '1 1 200px',
          justifyContent: 'flex-end',
          overflow: 'hidden',
        }}
      >
        {isMobile && (
          <Divider
            orientation='vertical'
            sx={{ backgroundColor: 'rgba(37, 37, 37, 0.1)' }}
          />
        )}
        <CurrentProjectButton />
        <Divider
          orientation='vertical'
          sx={{ backgroundColor: 'rgba(37, 37, 37, 0.1)' }}
        />
        <Tooltip title={userName}>
          <Button
            onClick={toggleDrawerOpen}
            data-qa='app-header-user-button'
            sx={{
              mx: { xs: 0, md: 1 },
              maxWidth: { lg: '20%' },
            }}
          >
            {userName && <BackgroundLetterAvatar userName={userName} />}
          </Button>
        </Tooltip>
        <Drawer
          anchor='right'
          data-qa='drawer'
          open={drawerOpen}
          classes={{ paper: classes.drawer }}
          style={{ zIndex: 20000 }}
          onClose={toggleDrawerClose}
        >
          <div
            role='presentation'
            className={classes.sideList}
            onClick={toggleDrawerClose}
            onKeyDown={toggleDrawerClose}
          >
            <Typography variant='h5'>{user.label}</Typography>
            <Typography className={classes.email} variant='h4'>
              {user.email}
            </Typography>
            <LanguageSwitch />
            <ButtonContained
              onClick={onLogoutClick}
              className={classes.logOutButton}
              data-qa='user-sidebar-log-out-button'
            >
              <FormattedMessage id='general_log_out' />
            </ButtonContained>
            <br />
            <EditProfileButton />
            <br />
            <br />
            <ServiceWorkerCheckButton />
            <GDPRClauseLink />
          </div>
        </Drawer>
      </Box>
    </Box>
  );
};

function EditProfileButton(): React.ReactElement {
  const isMobile = useIsMobile();
  const classes = styles({ isMobile });
  const isOffline = useIsOffline();
  const history = useHistory();

  const handleButtonClick = useCallback(() => {
    history.push('/me');
  }, [history]);

  return (
    <ForbiddenTooltip
      visibleWhen={isOffline}
      reason='general_offline_operation_unavailable'
      inlined
    >
      <ButtonContained
        className={classes.logOutButton}
        data-qa='user-sidebar-edit-button'
        onClick={handleButtonClick}
        disabled={isOffline}
      >
        <FormattedMessage id='general_edit' />
      </ButtonContained>
    </ForbiddenTooltip>
  );
}

export default React.memo(HeaderInfo);
