import { UserWithPermissionsInDto } from 'shared/domain/user/types/inDto';
import { LogLevel } from 'shared/types/logger';
import { logWithRethrow } from '../../db/helpers';
import {
  defaultConfig,
  generateHeaders,
  parseResponse,
} from '../../helpers/httpRequests';
import { HeadersData } from '../factories/types';
import { pull } from '../pull';

type ParseResponse = () => Promise<UserWithPermissionsInDto>;

export const fetchPermisions = (
  data: HeadersData,
  controller: AbortController
): Promise<{ userData: UserWithPermissionsInDto }> => {
  const { signal } = controller;
  if (shouldSkip(data, signal)) {
    const msg = 'Abort request';
    logWithRethrow({
      logLevel: LogLevel.INFO,
      errorObj: new Error(msg),
      msg,
      additionalInfo: { headersData: data },
    });
  }

  return pull(`${data.api}/v2/user/me`, {
    ...defaultConfig,
    method: 'GET',
    headers: generateHeaders(data),
    signal,
  })
    .then(<ParseResponse>parseResponse)
    .then((responseJson) => ({
      userData: responseJson,
    }));
};

function shouldSkip(data: HeadersData, signal: AbortSignal): boolean {
  return !data.api || signal.aborted;
}
