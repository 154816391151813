import { LabelledUser } from 'shared/domain/user/mapping/toView';
import { LevelOnView } from 'shared/domain/level/types/view';
import { DateTime } from 'luxon';
export const FORM_NAME = 'level';
export function createEmptyLevel(
  now: DateTime<true>,
  author: LabelledUser
): LevelOnView {
  return {
    _id: '',
    site: '',
    label: '',
    deleted: false,
    createdAt: now,
    modifiedAt: now,
    modifiedBy: author,
    createdBy: author,
  };
}
