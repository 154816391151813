import InsufficientPermissionsPage from 'components/core/InsufficientPermissionsPage/InsufficientPermissionsPage';
import { isAdmin } from 'shared/domain/role/isAdmin';
import { UserInDto } from 'shared/domain/user/types/inDto';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { currentProjectUserSelector } from 'redux/selectors/users';
import { ProjectSettingsView } from 'views/projects/settings';
import { MemoGenericRoleAuthorizer } from '../GenericRoleAuthorizer';
import { MemoEditProjectWizardContainer } from 'views/projects/wizard/edit/container';
export const AuthorizableProjectSettingsView = React.memo(
  (): React.ReactElement => {
    const currentUser: UserInDto = useSelector(currentProjectUserSelector);

    const isCurrentUserAdmin = useMemo(() => {
      return isAdmin(currentUser.role);
    }, [currentUser]);

    return (
      <MemoGenericRoleAuthorizer
        authorized={isCurrentUserAdmin}
        unauthorized={!isCurrentUserAdmin}
        authorizedComponent={ProjectSettingsView}
        unauthorizedComponent={InsufficientPermissionsPage}
      />
    );
  }
);

export const AuthorizableEditProjectWizard = React.memo(
  (): React.ReactElement => {
    const currentUser: UserInDto = useSelector(currentProjectUserSelector);
    const isCurrentUserAdmin = useMemo(() => {
      return isAdmin(currentUser.role);
    }, [currentUser]);
    return (
      <MemoGenericRoleAuthorizer
        authorized={isCurrentUserAdmin}
        unauthorized={!isCurrentUserAdmin}
        authorizedComponent={MemoEditProjectWizardContainer}
        unauthorizedComponent={InsufficientPermissionsPage}
      />
    );
  }
);
