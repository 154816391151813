import React, { useCallback } from 'react';
import NavigationController from '../../../setup/navigation/NavigationController';
import { MenuBar, Toaster } from '../index';
import { Backdrop, Box } from '@mui/material';
import { withStyles } from '@mui/styles';
import DialogContext from '../Dialog/common/DialogContext';
import { MENU_BAR_HEADER_HEIGHT_PX } from '../MenuBar/styles';
import {
  NAVIGATION_DRAWER_ZINDEX,
  NavigationDrawer,
} from '../Navigation/NavigationDrawer/presentational';
import { UploadProgress } from '../UploadProgress';
import { UploadProgressController } from '../UploadProgress/UploadProgressContext';
import {
  leftMainContainerPosition,
  moduleContainerWidth,
  styles,
} from './layoutStyles';

const Layout = ({ children }): React.ReactElement => {
  const [isNavigationExpanded, setNavigationExpanded] =
    React.useState(false);
  const toggleNavigationExpanded = useCallback(
    () => setNavigationExpanded(!isNavigationExpanded),
    [setNavigationExpanded, isNavigationExpanded]
  );

  return (
    <DialogContext>
      <UploadProgressController>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box
            sx={{
              height: MENU_BAR_HEADER_HEIGHT_PX,
            }}
            id='menuBarContainer'
          >
            <MenuBar />
          </Box>
          <Box
            id='mainContainer'
            sx={{
              height: `calc(100vh - ${MENU_BAR_HEADER_HEIGHT_PX})`,
              display: 'flex',
            }}
          >
            <NavigationDrawer
              isNavigationExpanded={isNavigationExpanded}
              setNavigationExpanded={setNavigationExpanded}
              // toggleNavigationExpanded={toggleNavigationExpanded}
            />
            <Box
              id='moduleContainer'
              sx={{
                flexGrow: 1,
                position: 'absolute',
                height: `calc(100vh - ${MENU_BAR_HEADER_HEIGHT_PX})`,
                display: 'flex',
                width: moduleContainerWidth,
                left: leftMainContainerPosition,
                flexDirection: 'column',
                overflow: 'auto',
              }}
            >
              {children}
            </Box>
          </Box>
        </Box>
        <Backdrop
          sx={{
            color: '#fff',
            zIndex: NAVIGATION_DRAWER_ZINDEX - 1,
          }}
          open={isNavigationExpanded}
        ></Backdrop>
        <Toaster />
        <NavigationController />
        <UploadProgress />
      </UploadProgressController>
    </DialogContext>
  );
};

export default withStyles(styles)(Layout);
