import { InspectionTemplateModel } from 'shared/domain/template/inspectionTemplateModel';
import { UserEntity } from 'shared/domain/user/types/entity';
import { InspectionTemplateEntity } from './entity';
import { entityToModelBase } from 'shared/domain/toBaseModel';

export function inspectionTemplateEntityToModel(
  entity: InspectionTemplateEntity,
  users: UserEntity[]
): InspectionTemplateModel {
  const modelBase = entityToModelBase({ entity, users });
  const { checklist, generalNorm, process, status, summary } = entity;
  return {
    ...modelBase,
    checklist,
    generalNorm,
    process,
    status,
    summary,
  };
}
