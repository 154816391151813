enum UserLocation {
  issues = 'issues',
  browseInspections = 'browseInspections',
  browseInspectionTemplates = 'browseInspectionTemplates',
  analytics = 'analytics',
  companies = 'companies',
  users = 'users',
  me = 'me',
  contracts = 'contracts',
  unknown = 'unknown',
  projectSettings = 'projectSettings',
  fieldsSettings = 'fieldsSettings',
  documentations = 'documentations',
}

export enum NavigationDestination {
  issues = 'issues',
  inspections = 'inspections',
  browseInspections = 'browseInspections',
  browseInspectionTemplates = 'browseInspectionTemplates',
  analytics = 'analytics',
  companies = 'companies',
  contracts = 'contracts',
  me = 'me',
  users = 'users',
  projectSettings = 'projectSettings',
  leaveFeedback = 'leaveFeedback',
  fieldsSettings = 'fieldsSettings',
  documentations = 'documentations',
}

function getCurrentLocation(path: string): UserLocation {
  if (path.includes('issue')) {
    return UserLocation.issues;
  } else if (path.includes('analytics')) {
    return UserLocation.analytics;
  } else if (
    path.includes('inspectionTemplate') ||
    path.includes('create-template')
  ) {
    return UserLocation.browseInspectionTemplates;
  } else if (path.includes('inspection')) {
    return UserLocation.browseInspections;
  } else if (path.includes('company')) {
    return UserLocation.companies;
  } else if (path.includes('contract')) {
    return UserLocation.contracts;
  } else if (path.includes('user')) {
    return UserLocation.users;
  } else if (path.includes('/me')) {
    return UserLocation.me;
  } else if (path.includes('projectSettings')) {
    return UserLocation.projectSettings;
  } else if (path.includes('fieldsSettings')) {
    return UserLocation.fieldsSettings;
  } else if (path.includes('documentation')) {
    return UserLocation.documentations;
  } else {
    return UserLocation.unknown;
  }
}

export function isGivenLocation(
  location: NavigationDestination,
  path: string
): boolean {
  return getCurrentLocation(path).toString() === location;
}
