import { IssueOnSingleView } from 'components/dataProviders/withIssue/IssueOnSingleView';
import { DocumentOnView } from 'presentation/document/documentOnView';
import { createContext, SetStateAction } from 'react';
import { LabelledUser } from 'shared/domain/user/mapping/toView';
import { HashMap } from 'shared/types/commonView';
import { FieldSkeleton } from 'shared/types/form';
import {
  ISSUE_REQUEST_STATUS,
  IssueStatusType,
} from '../issueView/helpers';

const issueState = {
  issue: {} as IssueOnSingleView,
  issueAccess: false,
  issueDocuments: [] as DocumentOnView[],
  eventDocuments: {} as HashMap<DocumentOnView[]>,
  issueStatus: { status: ISSUE_REQUEST_STATUS.INITIAL } as IssueStatusType,
  issueManagers: [] as LabelledUser[],
  primaryFields: [] as FieldSkeleton[],
  extendedFields: [] as FieldSkeleton[],
  setIssue: (data: Partial<IssueOnSingleView>): void => {},
  addToIssue: (
    callback: SetStateAction<IssueOnSingleView | undefined>
  ): void => {},
};

// why you need default value done this way -
// https://medium.com/@thehappybug/using-react-context-in-a-typescript-app-c4ef7504c858
const IssueContext = createContext(issueState);

export { IssueContext };
