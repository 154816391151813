import { InspectionFilterItem } from 'components/dataProviders/withInspection/types';
import {
  FilterDateType,
  IssueFilters,
} from 'components/dataProviders/withIssueFilters/types';
import { UserRole } from 'shared/types/userRole';
import { CorrectiveActionTypeModel } from 'shared/domain/correctiveActionType/correctiveActiontypeModel';
import { EnvironmentalAspectModel } from 'shared/domain/environmentalAspect/environmentalAspectModel';
import { HazardCategoryModel } from 'shared/domain/hazardCategory/hazardCategoryModel';
import { FieldTypes } from 'shared/domain/issueForm/types/fieldTypes';
import { LevelOnView } from 'shared/domain/level/types/view';
import { SiteModel } from 'shared/domain/site/types/model';
import { UserModel } from 'shared/domain/user/types/model';
import {
  StaticField,
  getAvailableFields,
} from 'shared/domain/visibleField/availableFields';
import { createAvailableFieldsSet } from 'shared/domain/visibleField/createAvailableFieldsSet';
import { VisibleFieldModel } from 'shared/domain/visibleField/visibleFieldModel';
import { WorktypeModel } from 'shared/domain/worktype/worktypeModel';
import { insertAt } from 'shared/utils/array';
import {
  FieldSkeleton,
  FormFieldType,
  InputType,
  IssueForm,
  ProcessType,
} from 'shared/types/form';
import { IssueFieldNames } from 'shared/domain/issueForm/types/fieldNames';
import {
  HashMap,
  Identificable,
  LabelledEntity,
} from 'shared/types/commonView';
import { matchOptionsWithFilterValues, separateFilters } from './helpers';
import { userModelToLabelledWithEmail } from 'shared/domain/user/mapping/toView';

type FieldsType = {
  primary: FieldSkeleton[];
  extended: FieldSkeleton[];
};

export type GeneralFilter = {
  label: string | { id: string };
  filterName: string;
  options: LabelledEntity[];
  filterValues: LabelledEntity[];
};

type DateFilter = {
  label: string | { id: string };
  filterName: string;
  filterValues: FilterDateType;
};

export type FiltersType = {
  generalFields: GeneralFilter[];
  dateFields: DateFilter[];
};

const makeFields = (
  form: IssueForm,
  visibleFields: Set<string>
): FieldsType => {
  const primary: FieldSkeleton[] = [];
  const extended: FieldSkeleton[] = [];

  form.forEach((field) => {
    if (!visibleFields.has(field.name)) return;
    if (field.fieldType === FormFieldType.primary) {
      primary.push(field);
    } else {
      extended.push(field);
    }
  });

  return {
    primary,
    extended,
  };
};

const excludeMapAndDocuments = (field: FieldSkeleton): boolean => {
  return (
    field.inputType !== InputType.documents &&
    field.inputType !== InputType.map
  );
};

function swapFieldValues(
  fields: {
    workTypes: WorktypeModel[];
    hazardCategory: HazardCategoryModel[];
    environmentalAspect: EnvironmentalAspectModel[];
    [key: string]: LabelledEntity[];
  },
  fieldName: string
): {} | { items: LabelledEntity[] } {
  return fields[fieldName] ? { items: fields[fieldName] } : {};
}

export const getFilterableFields = (
  visibleFields: HashMap<VisibleFieldModel[]>,
  projectProcesses: ProcessType[],
  filters: IssueFilters,
  stages: LabelledEntity[],
  createdBy: UserModel[],
  form: IssueForm,
  currentUserRole: UserRole,
  inspections: InspectionFilterItem[],
  fields: {
    workTypes: WorktypeModel[];
    hazardCategory: HazardCategoryModel[];
    environmentalAspect: EnvironmentalAspectModel[];
    proposedCorrectiveAction: CorrectiveActionTypeModel[];
    level: LevelOnView[];
    site: SiteModel[];
  },
  organizationId: string
): FiltersType => {
  const visibleFieldNameSet = createAvailableFieldsSet(
    visibleFields,
    getAvailableFields().filter(
      (field) => !field.canBeDisabled
    ) as StaticField[],
    projectProcesses || []
  );
  const { primary, extended } = makeFields(form, visibleFieldNameSet);
  const primaryFieldsFiltered = primary.filter(excludeMapAndDocuments);

  const allFields = [
    ...primaryFieldsFiltered.map((field) => ({
      ...field,
      ...swapFieldValues(fields, field.name),
      dataType: FieldTypes.primaryData,
    })),
    ...extended.map((field) => {
      return {
        ...field,
        // while we still use issue form we swap IssueForm values with the ones from endpoint.
        ...swapFieldValues(fields, field.name),
        dataType: FieldTypes.extendedData,
      };
    }),
  ].filter(
    (f) =>
      f.inputType !== InputType.textArea &&
      f.inputType !== InputType.textField &&
      f.inputType !== InputType.numberField &&
      f.inputType !== InputType.estimatedCost &&
      f.inputType !== InputType.finalCost
  );

  const separatedFilters = separateFilters(filters);

  const filterableFieldsDate: DateFilter[] = allFields
    .filter(
      (field) =>
        field.inputType === InputType.datePicker &&
        visibleFieldNameSet.has(field.name)
    )
    .map((f) => ({
      label: f.label,
      filterName: f.name,
      filterValues: separatedFilters.date[f.name] ?? {},
    }));

  const filterableFieldsGeneral: GeneralFilter[] = allFields
    .filter(
      (field) =>
        field.inputType !== InputType.datePicker &&
        visibleFieldNameSet.has(field.name)
    )
    .map((f) => ({
      label: f.label,
      filterName: f.name,
      options: f.items ?? [],
      filterValues: matchOptionsWithFilterValues(
        f.items ?? [],
        separatedFilters.general[f.name]
      ),
    }));

  const creationDate = {
    options: [],
    filterName: IssueFieldNames.creationDate,
    filterValues: separatedFilters.date[IssueFieldNames.creationDate],
    label: { id: 'issue_preview_label_creation_date' },
  };

  const stagesFilter = {
    filterName: 'stage',
    label: { id: 'filters_filter_type_stage' },
    options: stages,
    filterValues: matchOptionsWithFilterValues(
      stages,
      separatedFilters.general['stage']
    ),
  };

  const processesOptions = projectProcesses.map((e) => ({
    _id: e._id,
    label: e.label,
  }));

  const processesFilter = {
    filterName: 'issueTypes',
    label: { id: 'filters_filter_type_process' },
    options: processesOptions,
    filterValues: matchOptionsWithFilterValues(
      processesOptions,
      separatedFilters.general['issueTypes']
    ),
  };

  const createdByOptions = createdBy.map((user) =>
    userModelToLabelledWithEmail(user, organizationId)
  );

  const createdByFilter = {
    filterName: IssueFieldNames.createdBy,
    filterValues: matchOptionsWithFilterValues(
      createdByOptions,
      separatedFilters.general[IssueFieldNames.createdBy]
    ),
    label: { id: 'filters_filter_type_created_by' },
    options: createdByOptions,
  };

  const preDefinedFilters = [stagesFilter, processesFilter];
  if (visibleFieldNameSet.has(IssueFieldNames.createdBy)) {
    preDefinedFilters.push(createdByFilter);
  }

  const dateFields = visibleFieldNameSet.has(IssueFieldNames.creationDate)
    ? [creationDate, ...filterableFieldsDate]
    : filterableFieldsDate;
  const result = {
    generalFields: [...preDefinedFilters, ...filterableFieldsGeneral],
    dateFields: dateFields,
  };

  if (currentUserRole && currentUserRole !== UserRole.standard) {
    insertInspectionFilter(
      inspections,
      separatedFilters.general['inspection'],
      result.generalFields
    );
  }

  return result;
};

function insertInspectionFilter(
  inspections: InspectionFilterItem[],
  inspectionFilterValue: Identificable[],
  filtersList: GeneralFilter[]
): void {
  const inspectionFilter = createInspectionFilter(
    inspections,
    inspectionFilterValue
  );

  const siteFilterIndex = filtersList.findIndex(
    (filter) => filter.filterName === IssueFieldNames.subcontractors
  );

  insertAt(filtersList, siteFilterIndex, inspectionFilter);
}

function createInspectionFilter(
  inspections: InspectionFilterItem[],
  inspectionFilterValue: Identificable[]
): GeneralFilter {
  const inspectionOptions = inspections.map(({ _id, code }) => ({
    _id,
    label: code,
  }));

  return {
    filterName: 'inspection',
    filterValues: matchOptionsWithFilterValues(
      inspectionOptions,
      inspectionFilterValue
    ),
    label: { id: 'inspection_preview_toolbar_title' },
    options: inspectionOptions,
  };
}
