import { LabelledUser } from 'shared/domain/user/mapping/toView';
import { DateTime } from 'luxon';
import { UserRole } from 'shared/types/userRole';
import { SiteOnView } from '../../shared/domain/site/types/view';

export function createEmptySite(
  now: DateTime<true>,
  author: LabelledUser
): SiteOnView {
  return {
    _id: '',
    code: '',
    label: '',
    levels: [],
    deleted: false,
    createdAt: now,
    modifiedAt: now,
    modifiedBy: author,
    createdBy: author,
  };
}
