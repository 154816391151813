import { CompanyEntity } from 'shared/domain/company/types/entity';
import { CompanyModel } from 'shared/domain/company/types/model';
import { CompanyInDto } from 'shared/dtos/in/company';
import { UserEntity } from 'shared/domain/user/types/entity';
import { toOptionalUserWithPersonalData } from 'shared/domain/user/mapping/toView';
import { entityToModelBase } from 'shared/domain/toBaseModel';

export function companyEntityToModel(
  entity: CompanyEntity,
  users: UserEntity[]
): CompanyModel {
  const {
    longLabel,
    projectInvolvementType,
    shortLabel,
    sites,
    correspondenceAddress,
    registeredOfficeAddress,
    taxNumber,
  } = entity;

  const modelBase = entityToModelBase({ entity, users });
  return {
    ...modelBase,
    longLabel,
    projectInvolvementType,
    shortLabel,
    sites,
    correspondenceAddress,
    registeredOfficeAddress,
    taxNumber,
  };
}

export function companyInDtoToEntity(inDto: CompanyInDto): CompanyEntity {
  const {
    _id,
    contracts,
    createdAt,
    deleted,
    longLabel,
    modifiedAt,
    projectInvolvementType,
    shortLabel,
    sites,
    correspondenceAddress,
    createdBy,
    modifiedBy,
    registeredOfficeAddress,
    taxNumber,
  } = inDto;

  return {
    _id,
    contracts,
    createdAt,
    deleted: deleted ? 1 : 0,
    longLabel,
    modifiedAt,
    projectInvolvementType,
    shortLabel,
    sites,
    correspondenceAddress,
    createdBy,
    modifiedBy,
    registeredOfficeAddress,
    taxNumber,
  };
}
