import { BroadcastChannel } from 'broadcast-channel';
import { ChannelNames } from 'shared/domain/channelNames';
import {
  ApiChannelMessage,
  ServiceMethods,
  Services,
} from 'shared/domain/messages/message';
import { UserDeleteCustomEventDetail } from 'shared/domain/messages/user/eventMessages';
import { debugLog } from 'shared/logger/debugLog';
import { UserDeleteModel } from './types/model';

export function startUserDelete(
  userDeleteModel: UserDeleteModel,
  uniqueId: string
): void {
  debugLog('startUserDelete', userDeleteModel, uniqueId);
  const broadcast = new BroadcastChannel<
    ApiChannelMessage<UserDeleteCustomEventDetail>
  >(ChannelNames.apiChannel);
  broadcast.postMessage({
    service: Services.USERS,
    method: ServiceMethods.REMOVE,
    data: {
      userDeleteModel,
      uniqueId,
    },
  });
  broadcast.close();
}
