import {
  CreatableModel,
  DeletableModel,
  ModifiableModel,
} from 'shared/domain/commonModel';
import { LabelledUser } from '../user/mapping/toView';
import {
  Deletable,
  Identificable,
  LabelledEntity,
} from 'shared/types/commonView';
import { EventDescriptionType } from 'shared/types/eventDescription';
import { MapCoordinates } from 'shared/types/mapCoordinates';
import { StageInternalNames } from 'shared/types/stages';
import { UserAccess } from 'shared/types/userAccess';
import { UserWithPersonalData } from '../user/types/model';

export interface EventDescriptionPartModel {
  type: EventDescriptionType;
}

export interface TextDescriptionModel extends EventDescriptionPartModel {
  type: EventDescriptionType.text;
  text: string;
}

export interface MentionModel extends EventDescriptionPartModel {
  type: EventDescriptionType.mention;
  user: string;
}

export interface MentionConstructorModel
  extends EventDescriptionPartModel {
  type: EventDescriptionType.mention;
  user: LabelledEntity & { email: string };
}

export type IssueEventModel = {
  title: string;
  type: string;
  description: EventDescriptionPartModel[];
  deleted: boolean;
} & Identificable &
  CreatableModel &
  ModifiableModel;

type EstimatedCost = {
  cost: string;
  coveredBy: string | undefined;
};

type FinalCost = {
  outstanding: string;
  settled: string;
  coveredBy: string | undefined;
};

export enum IssueLocationType {
  pin = 'pin',
  area = 'area',
}

type Point = { x: number; y: number };
type Area = Point[];

export type IssueModel = {
  _id: string;
  primaryData: {
    site: string;
    title: string;
    assignee: UserWithPersonalData | undefined;
    description: string;
    detectionDate: string | undefined;
    subcontractors: string[];
    contractNumbers: string[];
    level: string;
    positionOnMap: MapCoordinates;
    executor: UserWithPersonalData | undefined;
    selectedLocationType: IssueLocationType;
    targetAreas: Area[];
    finalAreas: Area[];
  };
  extendedData: {
    workTypes: string[] | undefined;
    rootCauses: string[] | undefined;
    estimatedCost: EstimatedCost[] | undefined;
    finalCost: FinalCost[] | undefined;
    targetCompletionDate: string | undefined;
    finalCompletionDate: string | undefined;
    targetStartDate: string | undefined;
    finalStartDate: string | undefined;
    solutionProposal: string | undefined;
    impact: string | null | undefined;
    environmentalAspect: string | null | undefined;
    effect: string | null | undefined;
    hazardCategory: string[] | null | undefined;
    expectedFine: number | undefined;
    imposedFine: number | undefined;
    solutionMethod: string | undefined;
    subcontractorRepresentative: string | undefined;
    decisionToImposeFine: string | undefined;
    circumstances: string | undefined;
    proposedCorrectiveAction: string | undefined;
    costCode: string | undefined;
    daysOfInabilityToWork: number | undefined;
    personUnableToWork: string | undefined;
    spilledSubstance: string | undefined;
    waterLeakageScale: number | undefined;
    soilLeakageScale: number | undefined;
    contaminatedSoilScale: number | undefined;
    executedByCompanies: string[] | undefined;
    targetAmount: number | undefined;
    completedAmount: number | undefined;
    amountPercentage?: number;
    completionDateDelay?: string;
    startDateDelay?: string;
    numberOfEmployees: number | undefined;
  };
  inspection: string | undefined;
  protocolItem: string | undefined;
  process: string;
  stage: StageInternalNames;
  userAccesses: UserAccess[];
  hashtag: string;
  events: IssueEventModel[];
  mainImage: string | undefined;
} & CreatableModel &
  ModifiableModel &
  DeletableModel;

export type CreateIssueModel = {
  process: string;
  projectId: string;
  primaryData: Pick<IssueModel['primaryData'], 'site'> &
    Partial<Omit<IssueModel['primaryData'], 'site'>>;
} & Omit<EditIssueModel, '_id' | 'process' | 'primaryData'>;

export type CopyIssueModel = {
  process: string;
  projectId: string;
  primaryData: Pick<IssueModel['primaryData'], 'site'> &
    Omit<IssueModel['primaryData'], 'site'>;
} & Omit<EditIssueModel, '_id' | 'process' | 'primaryData'>;

export type EditIssueModel = {
  _id: string;
  stage?: StageInternalNames;
} & Partial<Omit<IssueModel, '_id' | 'primaryData' | 'mainImage'>> & {
    primaryData?: Partial<IssueModel['primaryData']>;
  } & Partial<CreatableModel> &
  Partial<ModifiableModel> &
  Partial<Deletable> &
  Partial<{ mainImage: string | null }>;

export enum StageColor {
  draft = '#FF9800',
  consultation = '#FFC107',
  execution = '#C0CA33',
  resolved = '#7CB342',
}
