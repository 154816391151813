import {
  ComplianceCheckModel,
  InspectionModel,
  ProtocolItemModel,
} from 'shared/domain/inspection/inspectionModel';
import {
  ComplianceCheckEntity,
  InspectionEntity,
  ProtocolItemEntity,
} from './entity';
import { UserEntity } from 'shared/domain/user/types/entity';
import {
  entityToModelBase,
  entityToNonCreatableModelBase,
} from 'shared/domain/toBaseModel';

export function inspectionEntityToModel(
  inspectionEntity: InspectionEntity,
  users: UserEntity[]
): InspectionModel {
  const modelBase = entityToModelBase({ entity: inspectionEntity, users });
  return {
    ...modelBase,
    contracts: inspectionEntity.contracts,
    controlledParty: inspectionEntity.controlledParty,
    inspectionDate: inspectionEntity.inspectionDate,
    isCompleted: inspectionEntity.isCompleted,
    levels: inspectionEntity.levels,
    protocol: inspectionEntity.protocol.map((protocolItem) =>
      protocolEntityToModel(protocolItem, users)
    ),
    site: inspectionEntity.site,
    template: inspectionEntity.template,
    workTypes: inspectionEntity.workTypes,
    comment: inspectionEntity.comment,
  };
}

function protocolEntityToModel(
  protocolEntity: ProtocolItemEntity,
  users: UserEntity[]
): ProtocolItemModel {
  const modelBase = entityToNonCreatableModelBase({
    entity: protocolEntity,
    users,
  });
  return {
    ...modelBase,
    complianceCheck: complianceCheckEntityToModel(
      protocolEntity.complianceCheck
    ),
    templateChecklistItem: protocolEntity.templateChecklistItem,
  };
}

function complianceCheckEntityToModel(
  complianceCheckEntity: ComplianceCheckEntity
): ComplianceCheckModel {
  return {
    issues: complianceCheckEntity.issues,
    result: complianceCheckEntity.result,
    comment: complianceCheckEntity.comment,
  };
}
