import { Box, Chip, FormControl } from '@mui/material';
import { MemoDisabledSimpleDatepicker } from 'components/common/simpleInputFields/datepicker';
import { DateTime } from 'luxon';
import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useStyles } from './styles';
import {
  PreviewFieldProps,
  PreviewPhoneFieldProps,
  ValueRendererProps,
} from './types';
import { MemoDisabledPhoneField } from 'components/common/simpleInputFields/phoneInput';

function EmptyPlaceholder(): ReactElement {
  const classes = useStyles();
  return (
    <span className={classes.emptyPlaceholder}>
      <FormattedMessage id='issue_field_placeholder_empty' />
    </span>
  );
}

function ValueRenderer(props: ValueRendererProps): ReactElement {
  const classes = useStyles();
  const { value } = props;

  const isFilled = Boolean(value.length);

  return Array.isArray(value) ? (
    <div className={classes.chipsWrapper}>
      {isFilled ? (
        value.map((val, index) => (
          <Chip
            key={`${val}${index}`}
            className={classes.chip}
            size='small'
            label={val}
          />
        ))
      ) : (
        <EmptyPlaceholder />
      )}
    </div>
  ) : (
    <div className={classes.value}>
      {isFilled ? value : <EmptyPlaceholder />}
    </div>
  );
}

export const MemoValueRenderer = React.memo(ValueRenderer);

function PreviewPhoneField(props: PreviewPhoneFieldProps): ReactElement {
  const classes = useStyles();
  const { label, value, dense } = props;
  // TODO use MUI labels instead of creating div with label manually
  return (
    <FormControl
      data-qa={'form-control-generalPreview'}
      margin={dense ? 'dense' : 'none'}
      variant='outlined'
      // sx={isHalf ? sx : {}}
    >
      <div className={classes.phoneLabel}>{label}</div>
      <MemoDisabledPhoneField
        value={value || ''}
        dense={false}
        labelId={''}
        fieldName={''}
        reserveSpaceForHelperText={false}
      />
    </FormControl>
  );
}

export const MemoPreviewPhoneField = React.memo(PreviewPhoneField);

function PreviewField(props: PreviewFieldProps): ReactElement {
  const classes = useStyles();
  const { isHalf, label, value, component, dense } = props;
  // if (typeof value === 'undefined' && !component) {
  //   const errorMessage = `Preview Field ${label} requires one of properties - "value" or "component"`;
  //   logError(errorMessage);

  //   throw new Error(errorMessage);
  // }
  if (DateTime.isDateTime(value)) {
    return (
      <Box sx={{ ml: -2, mt: 1 }}>
        <MemoDisabledSimpleDatepicker
          value={value}
          label={label}
          dense={dense}
        />
      </Box>
    );
  }

  // TODO use MUI labels instead of creating div with label manually
  return (
    <FormControl
      data-qa={'form-control-generalPreview'}
      margin={dense ? 'dense' : 'none'}
      variant='outlined'
      // sx={isHalf ? sx : {}}
    >
      <div className={classes.label}>{label}</div>
      {component ? component : <MemoValueRenderer value={value || ''} />}
    </FormControl>
  );
}

export const MemoPreviewField = React.memo(PreviewField);
